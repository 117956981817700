import styled from "styled-components";

export const HierarchyVariableContent = styled.div`
margin-top: 20px;
`;

export const ShowGraph = styled.span<{inactive:number}>`
  text-decoration: ${props => props.inactive===0 ? `none` : `underline`};
  cursor: ${props => props.inactive===0 ? `inherit` : `pointer`};
  opacity: ${props => props.inactive===0 ? `0.3` : `1`};
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${({theme}) => theme.colors.WHITE!=='#FFFFFF'?'rgb(209, 203, 199)':theme.colors.GREY[500]};
  font-family: Roboto Condensed;
`;

export const HierarchyRow = styled.div`
  display: flex;
  margin-bottom: 13px;
`

export const HierarchyName = styled.div`
  flex-basis: 71px;
  color: ${({theme}) => theme.colors.PRIMARY[400]};
  font-weight: 500;
  font-size: 16px;
  font-family: Roboto Condensed;
  line-height: 19px;
`

export const SwitchContainer = styled.div`
  display: flex;
  margin-top: 14px;
  margin-bottom: 34px;
`

export const SwitchButton = styled.div<{ isActive: boolean }>`
  font-weight: 500;
  font-size: 14px;
  font-family: Roboto Condensed;
  line-height: 20px;
  padding: 12px 44px;
  cursor: pointer;
  background-color: ${props => props.isActive ? '#364073' : 'rgba(54, 64, 115, 0.1)'};
  color: ${props => props.isActive ? '#FFFFFF' : '#333333'};
`
