import styled from "styled-components";

export const errorIcon = `${process.env.PUBLIC_URL}/assets/error.svg`

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Modal = styled.div`
  width: 478px;
  background-color: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 36px 42px;
`

export const ErrorIcon = styled.img`
  width: 44px;
  height: 38px;
`

export const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin-top: 18px;
  margin-bottom: 8px;
`

export const ModalBody = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 26px;
`

export const ModalActions = styled.div`
  display: flex;
  justify-content: end;
`

export const OkButton = styled.div`
  background:  #2E1A61;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
`