import { FunctionComponent, PropsWithChildren, useEffect } from "react";
import { ModalController } from "../../hooks/useModal";
import { Container, Modal } from "../../styles/Modal.styled";
import { toggleScroll } from "utils";

const ModalComponent: FunctionComponent<
  PropsWithChildren<{ controller: ModalController, width?:string, padding?:string, medium?:boolean }>
> = ({ children, controller, width, padding, medium }) => {

  useEffect(() => {
    toggleScroll(controller.isOpen)
  }, [controller.isOpen])

  if (!controller.isOpen) return null;
  return (
    <Container>
      <Modal data-testid='modal' width={width!} padding={padding!} medium={medium}>{children}</Modal>
    </Container>
  );
};

export default ModalComponent;
