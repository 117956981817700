import styled from "styled-components";

export const Modal = styled.div`
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; 
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
`

export const ModalContent = styled.div`
    height: calc(80vh - 160px);
    overflow-y: scroll !important;
    ::-webkit-scrollbar {
        width: 16px;
        padding: 20px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: rgba(46, 26, 97, 0.1);
        border-radius: 2px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #2E1A61;
        border-radius: 2px;
    }
    padding-right: 20px;
`

export const ScrollableArea = styled.div`
    margin-top: 10vh;
    padding: 64px 20px 20px 32px;
    background-color: #ffffff;
    border-radius: 20px;
    width: 80%;
    max-width: 932px;
    margin-bottom: 10vh;
`

export const SubmitButton = styled.div<{disabled:boolean}>`
    background: #2E1A61;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: white;
    padding: 16px;
    width: 130px;
    position: relative;
    bottom: 0;
    right: 0;
    cursor:  ${props => props.disabled ? `not-allowed` : `pointer`};
    opacity: ${props => props.disabled ? 0.5 : 1};
`

export const ButtonRow = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;