import {OcrDocResponseFields, OcrDocResponseItem} from "../types";
import {createDocumentsObject} from "../utils/airTableForTesting";

export const createControllerRealPayload = (accessToken: string, metadata:Object, selectedDocuments:OcrDocResponseItem[]):Object => {

    let passports:OcrDocResponseFields[] = []
    let utilityBills:OcrDocResponseFields[] = []
    let drivingLicences:OcrDocResponseFields[] = []

    // this can be done better, but for now it's ok
    selectedDocuments.forEach( (selectedDocument:OcrDocResponseItem) => {
        const data = selectedDocument.doc
        if(selectedDocument.type === 'passport'){
            passports.push(data)
        }else if (selectedDocument.type === 'utility_bill'){
            utilityBills.push(data)
        }else{
            drivingLicences.push(data)
        }
    })

    return {
        accessToken: accessToken,
        metadata: metadata,
        documents: {
            ...(passports.length !==0 && { "passport": createDocumentsObject(passports)}),
            ...(utilityBills.length !==0 && {"utility bill": createDocumentsObject(utilityBills)}),
            ...(drivingLicences.length !==0 && {"driving licence": createDocumentsObject(drivingLicences)} )
        }
    }

}