import { DEFAULT_ORIGIN, ecpOriginInParams } from "config/ecp";

export const loadSdk = (
    ): Promise<void> => {
        return new Promise<void>((resolve) => {
            const sdkScriptNode = document.createElement('script');
            sdkScriptNode.src = `https://${ecpOriginInParams || DEFAULT_ORIGIN}/embed/sdk.js`;
            sdkScriptNode.id = 'symphony-ecm-sdk';
            sdkScriptNode.setAttribute('render', 'explicit');
            sdkScriptNode.setAttribute('data-partner-id', 't360_t360degrees_BYC-742');
            (window as any).renderRoom = () => {
                (window as any).symphony
                    .render('symphony-ecm', {
                        // showTitle: true,
                        // ecpLoginPopup: false,
                        // showChatSearch: false,
                        canAddPeople: false, // allow add members to rooms if owner. default false
                        condensed: false, // condensed mode. default true
                        condensedMessageBelowName: true, // if in condensed mode, display message below or next to name. default true (below)
                        ecpLoginPopup: false, // perform login within a popup, for SSO systems that refuse iframe integration. default false
                        mode: 'light', // built-in colour palettes. default light
                        showAttach: true, // enable attachments. default true
                        showEmoji: true, // enable emojis in the editor. default true
                        showSuppressMessage: false, // allow user to suppress messages. default true
                        showSystemMessages: false, // show system/maestro messages (e.g. a user joined room). default false
                        sound: false, // enable sound notifications
                        // Focus mode only
                        showChatSearch: false, // enable search feature. default true
                        showBookmarkMessage: false, // allow user to bookmark message. default true
                        showCompose: true, // enable compose mode editor. default true
                        showDisableInput: false, // enable user to disable editor. default true
                        showEditor: true, // enable editor. default true
                        showTitle: false, // show room title in the header. default true
                        showInfo: false, // show room information in the header. default true
                        showMembers: false, // show list of members. default true
                        showProfilePopover: false, // show popover profile cards. default true
                        symphonyLogo: false, // Display the 'Powered by SYMPHONY logo' below ECP chats. default true
                        // Collaboration mode only
                        allowChatCreation: false
                    }).then(() => {resolve();});
            };
            sdkScriptNode.setAttribute('data-onload', 'renderRoom');
            document.body.appendChild(sdkScriptNode);
        });
    };
    

    export const sdkLoaded = loadSdk()