import { AdminMenuItem } from "../types";

const crownIcon = process.env.PUBLIC_URL + "/assets/crown.svg";
const plusIcon = process.env.PUBLIC_URL + "/assets/plus-icon.svg";
const settingIcon = process.env.PUBLIC_URL + "/assets/settings-dido-icon.svg";
const searchIcon = process.env.PUBLIC_URL + "/assets/search.svg";
const dashboardIcon = process.env.PUBLIC_URL + "/assets/dashboard-icon.svg";

export const ADMIN_MENU_ITEMS: AdminMenuItem[] = [
  {
    text: "ALICE Requests",
    icon: crownIcon,
    link: "alice-requests",
  },
  {
    text: "New Entity LINK",
    icon: plusIcon,
    link: "entity-link",
  },
  {
    text: "GET Search",
    icon: searchIcon,
    link: "get-search",
  },
  {
    text: "Dashboard",
    icon: dashboardIcon,
    link: "dashboard",
  },
  {
    text: "Settings",
    icon: settingIcon,
    link: "settings",
  },
];

