import { SEARCH_CLASS_TYPE } from 'constants/filter';
import type { FunctionComponent } from 'react';
import {Search} from './Search'
import {
    FilterContainer,
 } from 'styles/filter/FilterTable.styled';

type Props = {
    setData?: (arg:string) => void
}

export const FilterTable:FunctionComponent<Props> = ({setData}) => {
    return (
        <FilterContainer>
           <Search setData={setData} classType={SEARCH_CLASS_TYPE.PRIMARY} />
        </FilterContainer>
    )
}