import styled from 'styled-components';


export const SearchResultItemContainer = styled.div<{show: boolean}>`
    color: ${({theme}) => theme.colors.DARK[500]};
    padding: 40px 0 50px 0;
    border-bottom: 1px solid ${({theme}) => theme.colors.PRIMARY[400]};
    background-color:${({theme}) => theme.colors.WHITE};
    cursor: ${props => `${props.show ? `auto` : `pointer`}`};
    position:relative;
`;

export const SearchResultItemArea = styled.div`
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
`;

export const SearchResultItemLeftArea = styled.div`
    flex: 0 0 280px;
    padding: 0 15px 0 0;
    @media (max-width: 768px) {
      flex: auto;
      padding: 0 0 25px 0;
    }
`

export const SearchResultItemRightArea = styled.div`
    width: 100%;
`

export const SearchResultsItemName = styled.div`
    font-size: 23px;
    font-weight: 700;
    line-height: 28px;
    display: block;
    text-transform: uppercase;
    margin-bottom: 8px;
`;

export const SearchResultsItemCountry = styled.div`
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    & div{
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }
`;

export const AccordionActionButton = styled.div`
    margin-left: 10px;
    display: inline-block;
    cursor: pointer;
    &:first-child{
        margin-left: 0;
    }
`;

export const OpenPartyIcon = styled.span`
    display: block;
    width: 18px;
    height: 18px;
    filter: ${({theme}) => theme.colors.WHITE!=='#FFFFFF'?'saturate(0.8) brightness(0.86) hue-rotate(180deg) invert(1)':'none'};
    padding-top:6px;
    background-image: url(${process.env.PUBLIC_URL+"/assets/openPartyProfileIcon.svg"});
    background-repeat:no-repeat;
    &:hover{
        opacity: 0.5;
    }
`;

export const CloseAccordionIcon = styled.span`
    display: block;
    width: 20px;
    height: 20px;
    filter: ${({theme}) => theme.colors.WHITE!=='#FFFFFF'?'saturate(0.8) brightness(0.86) hue-rotate(180deg) invert(1)':'none'};
    background-image: url(${process.env.PUBLIC_URL+"/assets/closeAccordionIcon.svg"});
    background-repeat:no-repeat;
    margin-left:12px;
    &:hover{
        opacity: 0.5;
    } 
`;
export const OpenAccordionIcon = styled.span`
    display: block;
    width: 20px;
    height: 20px;
    filter: ${({theme}) => theme.colors.WHITE!=='#FFFFFF'?'saturate(0.8) brightness(0.86) hue-rotate(180deg) invert(1)':'none'};
    background-image: url(${process.env.PUBLIC_URL+"/assets/openAccordionIcon.svg"});
    background-repeat:no-repeat;
    margin-left:12px;
    &:hover{
        opacity: 0.5;
    } 
`;
export const GuoIconContainer = styled.div`
    display: inline-block;
    width: 100%;
`;

export const GuoImage = styled.img`
    display: inline-block;
    width: 20px;
    height: 20px; 
    margin:0px 2px 10px 2px;
`
export const RightIcons = styled.div`
position: absolute;
right:0;
top: 06px;
`