import styled from "styled-components";

export const rabbitIcon =  `${process.env.PUBLIC_URL}/assets/crown-alert-rabbit-icon.svg`;

export const Container = styled.div`
    padding: 35px 40px 40px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const MainIcon = styled.div<{iconURL:string}>`
    width: 146px;
    height: 160px;
    background-image: ${props => `url(${props.iconURL})`};
    background-size: 100px;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 17px;
`

export const Title = styled.div`
  font-size: 24px;
  color: ${({theme}) => theme.colors.DARK[400]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`

export const Text = styled.div`
  font-size: 16px;
  color: ${({theme}) => theme.colors.DARK[400]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 29px;
`

export const CloseButton = styled.button`
    cursor: pointer;
    width: 250px;
    height: 56px;
    align-items: center;
    background-color: #4A82A6;
    border: solid 1px #4A82A6;
    border-radius: 6px;
    color: #fff;
    margin-bottom: 3px;
    font-size: 16px;
`