import {ArrayData, FieldData, ObjectType, ValetParking, ValetParkingRules} from "../types";

export let valetParker: ValetParking = (rules: ValetParkingRules, debug?: boolean) => (p: ObjectType) => {
    let result: any = {}
    if (rules)
        Object.entries(rules).forEach(([valetName, rule]) => {
            if (debug) console.log('valetParker', valetName, JSON.stringify(rule))
            let valet: any = {}
            if(rule.fields) fieldValetParker(valet, rule.fields, p, debug)
            if(rule.arrays) arrayValetParker(valet, rule.arrays, p, debug)
            result[valetName] = valet
        })
    return result
}

export let fieldValetParker = (valet: any, rule: FieldData, p: ObjectType, debug?: boolean) => {
    if (rule) Object.entries(rule).forEach(([aspect, fieldData]) => {
        if (debug) console.log('  fieldValetParker', 'aspectis', aspect, 'fielddata is', fieldData)
        let aspectData:any = p[aspect]
        if (aspectData) Object.entries(fieldData).forEach(([parking, aspectFieldName]) => valet[parking] = aspectData[aspectFieldName])
    })
}

export let arrayValetParker = (valet: any, rule: ArrayData, p: ObjectType, debug?: boolean) => {
    if (rule)
        Object.entries(rule).forEach(([parkingArrayName, parkingFromAspectArray]) => {
            if (debug) console.log('  arrayValetParker', 'parkingArrayNameis', parkingArrayName, 'parkingFromAspectArrayis', parkingFromAspectArray)
            let aspectDataArray = p[parkingFromAspectArray.array]
            if (aspectDataArray) {
                let arr: object[]
                if (!Array.isArray(aspectDataArray)) {
                    arr= [aspectDataArray]
                }
                else arr = aspectDataArray
                if (debug) console.log('  arrayValetParker', 'aspectDataArrayLengt', aspectDataArray.length)
                valet[parkingArrayName] = arr.map((aspectDetails: any) => {
                    let resultData: any = {}
                    Object.entries(parkingFromAspectArray.fields).forEach(([parkingFieldName, aspectFieldName]) => resultData[parkingFieldName] = aspectDetails[aspectFieldName])
                    return resultData
                })
            }
        })
}