import styled from "styled-components";

 export const LandingPageMenuContainer = styled.div`
 min-height: 80vh;
 padding: 0 0.5rem;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
`

export const LandingPageMenuMain = styled.div`
 padding: 2rem 1rem;
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 flex-wrap: wrap;
`
