//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the Documents section in Party Manager.
 */

 import { DocumentsContainer, DocumentRow, DocumentIcon, Title } from 'styles/party/Documents.styled';
 import { useEffect, useState } from 'react';
 import { readRemoteFile } from 'react-papaparse';
 import {NoDataToDisplay} from '../noData/ComingSoon'
 

 interface CSVData {
     data: Array<string>[]
 }
 
 export function Documents({ accordionName, partyId }: { accordionName: string, partyId: string }) {
 
 
     const dataInitialState:CSVData = { data: []}
     let [datatoDisplay, setDataToDisplay] = useState<CSVData>(dataInitialState)
     let [isElaborationFinished, setIsElaborationFinished] = useState(false)
 
     const retrieveData = () => {
         readRemoteFile(process.env.PUBLIC_URL+"/csv/listOfLinksForDocumentsSection.csv",{
             complete: (results:CSVData) => {
 
                 let dataToConsider:Array<string>[] = []
 
                 results.data.forEach( item => {
                     if (item[2]===partyId){
                         dataToConsider.push(item)
                     }
                 })
 
                 setDataToDisplay({data: dataToConsider})
                 setIsElaborationFinished(true)
             },
             download: true
         })
     }
 
     useEffect(() => {
         retrieveData()
     }, [])
 
     return (
         <div>
         {isElaborationFinished && (datatoDisplay.data.length !==0
             ?   <DocumentsContainer>
                     {datatoDisplay.data.map((item:string[], index:number) => (
                         <DocumentRow key={index}>
                             <a href={item[5]} target="_blank" rel='noreferrer'><DocumentIcon/></a>
                             <a href={item[5]} target="_blank" rel='noreferrer'>{item[item.length-1]}</a>
                         </DocumentRow>
                     ))}
                 </DocumentsContainer>
             :   <NoDataToDisplay /> )}
         </div>
     )
 
 }
 