// Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

import {Link} from "react-router-dom";
import { LandingPageCardDetails } from "../../types";
import { CustomCardContainer, CustomCard } from "styles/landingPageCard/LandingPageCard.styled";
import {useAppData} from "../../hooks/useAppData";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { MouseEventHandler } from "react";

const LandingPageCard = ({landingPageCardDetails}:{landingPageCardDetails:LandingPageCardDetails}) => {
     const {status} = useAppData()
     const {track} = useTrackActivity()
     
    const trackClickActivity = (buttonName: string): MouseEventHandler<HTMLDivElement> => {
        return (event) => {
            track(ActivityType.BUTTON_CLICK, `${buttonName} page`, {button_clicked: buttonName})
        }
    }
     
     return (
             <CustomCardContainer onClick={trackClickActivity(landingPageCardDetails.text)}>
                 <Link to={landingPageCardDetails.link} style={{ textDecoration: 'none' }}>
                     <CustomCard
                         iconURL={landingPageCardDetails.iconURL}
                         iconURLHover={landingPageCardDetails.iconURLHover}
                         status={status}>
                         <div><span>&nbsp;</span></div>
                         <div><p>{landingPageCardDetails.text}</p></div>
                     </CustomCard>
                 </Link>
             </CustomCardContainer>
     )
 }
 
 export default LandingPageCard