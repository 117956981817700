/**
 * Each data import produces a configuration file that we call Import Variables.
 * In order to load data for a Party Profile, it's necessary to download first these Import Variables.
 * Then, the Import Variables will be used as a starting point for downloading a Finger Prints file and the Entity List files.
 */
export const IMPORT_VARIABLES_FILE_NAME = '.t360import';

/**
 * Each data import produces a Finger Prints file that helps to map the data.
 * The Finger Prints file is used for keeping track of the headers and other metadata during the import process.
 * This file is necessary for extracting meaningful data from the Entity List files.
 */
export const FINGERPRINTS_FILE_NAME = 'allFingerPrints.fpt'

export const INDEX_PATH = "index.json"