import styled from 'styled-components';

export const closerIcon = `${process.env.PUBLIC_URL}/assets/header-modal-closer-icon.svg`;
export const settingsIcon = `${process.env.PUBLIC_URL}/assets/header-modal-settings-icon.svg`;


export const CloserTab = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 400px;
    height: 64px;
`

export const BackImage = styled.div`
    width: 100%;
    height: 120px;
    background-image: url("/assets/header-modal-background-image.png");
`

export const ContentContainer = styled.div`
    position: relative;
    height: 184px;
    margin-top: -48px;
    margin-bottom: 48px;
`

export const AvatarImageContainer = styled.div`
    height: 96px;
    width: 96px;
    border-radius: 50%;
    border: solid white 4px;
    margin-left: 24px;
    background-image: url("/assets/no-pic-user-avatar-icon.png");
  
    //Specific options to adapt temporary dummy avatar image
    background-size: 80px;
    background-position-x: 8.5px;
    background-color: lightgrey;
`

export const TextContainer = styled.div`
    color: black;
    height: 60px;
    margin: 24px 0 16px 24px;
    display: flex;
    align-items: start;
    flex-direction: column;
`

export const NameContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 4px;
    font-size: 24px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.DARK[500]};
`

export const EmailContainer = styled.div`
    font-size: 18px;
    color: ${({ theme }) => theme.colors.GRAY[600]};
`

export const PermissionsContainer = styled.div`
      display: flex;
      flex-direction: row;
      margin-left: 24px;
      height: 22px;
`

export const SettingsContainer = styled.div`
  position: relative;
`

export const SettingsTab = styled.div`
    cursor: pointer;
    height: 40px;
    width:max-content;
    margin-bottom: 10px;
    padding: 10px 26px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.GRAY[700]};
`

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 122px;
`

export const SignOutButton = styled.div`
    width: 207px;
    height: 44px;
    border: solid 1px #D0D5DD;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.GRAY[700]};
`

export const MenuItemIcon = styled.div<{iconURL:string}>`
    width: 20px;
    height: 20px;
    background-image: ${props => `url(${props.iconURL})`};
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
`

export const GeneralMenuContainer = styled.div`
    overflow-Y: auto;
    height: 500px;
`
export const SubMenuContainer = styled.div`
margin-left: 1.5rem;
`