import { useLocation, Link } from "react-router-dom";
import useModal from 'hooks/useModal';
import SignOut from "../alert/SignOut";
import { PATH } from "constants/index";
import * as S from "../../styles/admin/Sidebar.styled";
import { signoutIcon } from "../../styles/admin/Sidebar.styled";
import { DIDO_DEMO_MENU_ITEMS } from "../../constants/dido";
import {useAppData} from "../../hooks/useAppData";

const isActive = (path: string, link: string) => {
  return path.includes(link);
};

const SideBar = () => {
  const {status} = useAppData()

  const t360LogoPurple = process.env.PUBLIC_URL + status==='dark'?"/assets/t360-header-logo.svg":"/assets/t360-header-logo-purple.svg";

  const { pathname } = useLocation();

  const signOutModal = useModal();

  const openSignOutModal = () => {
      signOutModal.open();
  }

  return (
    <S.Container>
      <SignOut controller={signOutModal} />
      <S.Content>
        <S.Header>
          <Link to={PATH.INDEX}>
            <S.Logo iconURL={t360LogoPurple} />
            <S.HeaderTitle>T360</S.HeaderTitle>
          </Link>
        </S.Header>
        <S.MenuContainer>
          {DIDO_DEMO_MENU_ITEMS.map((menu) => (
            <S.MenuLink to={menu.link} key={menu.text}>
              <S.MenuItem isActive={isActive(pathname, menu.link)}>
                <S.MenuItemIcon
                  iconURL={menu.icon}
                  isActive={isActive(pathname, menu.link)}
                />
                <S.MenuItemText>{menu.text}</S.MenuItemText>
              </S.MenuItem>
            </S.MenuLink>
          ))}
        </S.MenuContainer>

        {/* signout */}
        <S.MenuItem onClick={openSignOutModal}>
          <S.MenuItemIcon iconURL={signoutIcon} path={PATH.SIGNOUT} />
          <S.MenuItemText>Sign Out</S.MenuItemText>
        </S.MenuItem>
      </S.Content>
    </S.Container>
  );
};

export default SideBar;
