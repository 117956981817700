import {useEffect, useState} from "react";
import LoadingSpinner from "../../LoadingSpinner";
import {
    AirTableJSON, AirTableRecord,
    initialSelectBoxDetails,
    SelectBoxDetails,
    SelectBoxOption,
    SelectedDocumentsProps
} from "../../../types";
import {fetchAirTable} from "../../../services/airTable";
import {DataSelector} from "./DataSelector";

/**
 * Component for downloading dummy data from a specific AirTable base.
 * @param typeOfDocument: the exact name of the AirTable's table
 * @param selectedDocumentsProps: state and setter for selectedDocuments
 */
export const AirTableSelection = (
    {typeOfDocument, selectedDocumentsProps}:{typeOfDocument:string, selectedDocumentsProps:SelectedDocumentsProps}) => {

    // 2 states: 1 for ON/OFF showing; 1 for the data to pass to the selectbox
    const [showAirTableSelection, setShowAirTableSelection] = useState<boolean>(false)
    const [selectBoxDetails, setSelectBoxDetails] = useState<SelectBoxDetails>(initialSelectBoxDetails)

    useEffect(()=>{
        setShowAirTableSelection(false)
        // retrieve the necessary data from AirTable
        fetchAirTable(typeOfDocument)
            .then((airTableJSON:AirTableJSON) => {
                const selectBoxOptions:SelectBoxOption[] = airTableJSON.records.map((airTableRecord:AirTableRecord) => {
                    return {
                        optionValue: JSON.stringify(airTableRecord.fields),
                        optionContent: JSON.stringify(airTableRecord.fields)
                    }
                })

                setSelectBoxDetails({
                    selectBoxName: typeOfDocument,
                    selectListOfOptions: selectBoxOptions
                })
                // finally show the selection
                setShowAirTableSelection(true)
            })
    },[typeOfDocument])

    if(!showAirTableSelection) return <LoadingSpinner/>

    return(<DataSelector selectBoxDetails={selectBoxDetails} selectedDocumentsProps={selectedDocumentsProps} />)
}