import styled from "styled-components";

export const DocumentsContainer = styled.div`
columns: 2 auto;

@media (max-width: 1200px) {
    column-count: 1;
}
`;

export const DocumentRow = styled.div`
font-size: 16px;
color: #333;
margin-bottom: 20px;

a {
    margin-right: 20px;
    color: #333;
    &:hover{
        color: #4A82A6;
    }
}
`;

export const DocumentIcon = styled.span`
display: inline-block;
vertical-align: middle;
min-width: 20px;
min-height: 20px;
background-image: url(${process.env.PUBLIC_URL+"/assets/downloadDocumentIcon.svg"});
`;

export const Title = styled.div<{light?:boolean}>`
    color: ${({theme}) => theme.colors.DARK[400]}
`
