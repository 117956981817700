import styled from 'styled-components';

export const DivIcon = `${process.env.PUBLIC_URL}/assets/DivIcon.png`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.colors.DARK[500]};
`;

export const GetSearchSection = styled.div`
  h1 {
    margin-top: 0;
  }
`;

export const GetSearchContent = styled.div`
  display:flex;
  grid-template-columns:repeat(4, 1fr);
  grid-gap: 0.5rem;
  position:relative;
  @media (max-width: 768px) {
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }
`;

export const Hive = styled.div`
    background: ${({theme}) => theme.colors.PRIMARY[500]};
    border-radius: 4px;
    width: 30%;
    height: 870px;
    position: relative ;
    margin:0;
    @media (max-width: 768px) {
      width: 100%;
    }
`;

export const HiveHeading = styled.div`
    display:flex;
    justify-content:center;
    align-item:center;
    font-weight: 700;
    font-size: 16px;
    color:  ${({theme}) => theme.colors.PRIMARY[400]};
    &>h2{
        margin:14px 10px 10px 10px;
    }
`;
export const HiveContent = styled.div`
    padding:20px;
    &>div>h2{
      font-size:24px;
      font-weight: 600;
      color: ${({theme}) => theme.colors.DARK[400]};
      margin:0;
    }
    &>div>p{
      margin:8px 0 0 0;
      color: ${({theme}) => theme.colors.DARK[400]};
      font-weight: 500;
    }
    & .contentdiv{
      color: ${({ theme }) => theme.colors.DARK[500]};
      display:grid;
      grid-template-columns:repeat(2, 1fr);
      font-size: 14px;
      & p:nth-child(2) {
        color: ${({theme}) => theme.colors.PRIMARY[400]};
        font-weight: 700;
      }
    }
`;
export const IconDivA = styled.div`
    margin:0;
    padding:0;
    width:0;
    position:absolute;
    right:-5px;
    &>img{
      position:absolute;
      left:-28px;
      top:13px;
      width:50px;
      @media (max-width: 768px) {
        position:absolute;
        top:-15px;
        transform:rotate(90deg);
      }
    }
    @media (max-width: 768px) {
      display: none;
    }
`;

export const SCRAPING = styled.div`
    background: ${({theme}) => theme.colors.PRIMARY[500]};
    border-radius: 4px;
    width: 30%;
    position: relative ;
    height: 100%;
    margin:0;
    @media (max-width: 768px) {
      width: 100%;
    }
`;

export const SCRAPINGHeading = styled.div`
    display:flex;
    justify-content:center;
    align-item:center;
    font-weight: 700;
    font-size: 16px;
    color: ${({theme}) => theme.colors.PRIMARY[400]};
    &>h2{
      margin:14px 10px 10px 10px;
    }
    @media (max-width: 768px) {
      padding-top: 20px;
    }
`;

export const SCRAPINGContent = styled.div<{status:string}>`
    padding:20px;
    &>div>h2{
      font-size:24px;
      font-weight: 600;
      color: ${({theme}) => theme.colors.DARK[400]};
      margin:0;
    }
    & .contentdiv{
      display:grid;
      grid-template-columns:repeat(1, 1fr);
      font-size: 12px;
      padding:05px;
      word-wrap: break-word;
    }
    & .contentdiv p{
      color: ${({theme, status}) => status==='dark'?'#fff':theme.colors.DARK[400]};
      width:170px;
      word-wrap: break-word;
    }
`;

export const IconDiv = styled.div`
    margin:0;
    padding:0;
    width:0;
    position:absolute;
    right:-5px;
    &>img{
      position:absolute;
      left:-28px;
      top:13px;
      width:50px;
      @media (max-width: 768px) {
        top: 0;
        }
    }
    @media (max-width: 768px) {
      position:absolute;
      top:0;
      left:55%;
      right: 0;
      transform: translate(-50%, -50%) rotate(90deg);
    }
`;

export const IconDivB = styled.div`
    margin:0;
    padding:0;
    width:0;
    position:absolute;
    right:-5px;
    &>img{
      position:absolute;
      left:-28px;
      top:13px;
      width:50px;
      @media (max-width: 768px) {
        top: 0;
        position:absolute;
        width: 50px;
      }
    }
    @media (max-width: 768px) {
      position:absolute;
      top:0;
      left:55%;
      right: 0;
      width: 0;
      margin: 0;
      padding:0;
      transform: translate(-50%, -50%) rotate(90deg);
    }
    @media (min-width: 768px) {
      display: none;
    }
`;

export const AliceImported = styled.div`
    background: ${({theme}) => theme.colors.PRIMARY[500]};
    border-radius: 4px;
    width: 40%;
    height: 870px;
    margin:0;
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
      padding-top: 20px;
    }
`;

export const AliceImportedHeading = styled.div`
    display:flex;
    justify-content:space-between;
    align-item:center;
    font-weight: 700;
    font-size: 16px;
    color: ${({theme}) => theme.colors.PRIMARY[400]};
    padding-left:20px;
    &>h2{
      margin:14px 10px 10px 10px;
    }
    &>div{
      font-size:12px;
      margin:0px 23px 0px 10px;
      display:flex;
      justify-content:center;
      align-items:center;
      cursor:pointer;
    }
    &>div>p{
      background: rgba(46, 26, 97, 0.05);
      border-radius: 8px;
      padding:08px 10px;
    }
`;

export const AliceImportedContent = styled.div`
    padding:20px;
    &>div>div>h2{
      font-size:24px;
      font-weight: 600;
      color: ${({theme}) => theme.colors.DARK[400]};
      margin:0;
    }
    &>div>div>p{
      margin:8px 0 0 0;
      color: ${({theme}) => theme.colors.DARK[400]};
      font-weight: 500;
    }
    & .contentdiv{
      color: ${({ theme }) => theme.colors.DARK[500]};
      display:grid;
      grid-template-columns:repeat(2, 1fr);
      font-size: 14px;
      & p:nth-child(2) {
        color: ${({theme}) => theme.colors.PRIMARY[400]};
        font-weight: 700;
      }
    }
`;

export const DropDownContainer = styled("div")`
    position:relative;
    z-index:3;
    color: ${({ theme }) => theme.colors.DARK[500]};
`;

export const DropDownHeader = styled("div")`
    width:50px;  
    font-size:14px;
    padding:13px 20px 5px 20px;  
    background: ${({theme}) => theme.colors.GREYED[800]};
    border: 1px solid ${({theme}) => theme.colors.GRAY[300]};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    display:flex;
    justify-content: center;
    cursor: pointer;
    position:relative;
    &>div{
      margin-top:-4px;
    }
`;

export const DropDownListContainer = styled("div")``;

export const DropDownList = styled("ul")`
    width:50px;  
    font-size:12px;
    padding:10px 20px;  
    background: ${({theme}) => theme.colors.GREYED[800]};
    border: 1px solid ${({theme}) => theme.colors.GRAY[300]};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    @media (max-width: 768px) {  
      width: 40%;
    }
`;

export const ListItem = styled("li")`
    background: ${({ theme }) => theme.colors.GREYED[800]};
    color: ${({ theme }) => theme.colors.DARK[500]};
    list-style:none;
    margin:1rem auto;
    cursor:pointer;
    &:hover{
      color: ${({theme}) => theme.colors.PRIMARY[400]};
      font-weight: 600;
    }
`;
