import {useContext} from 'react'
import { AppContext } from "contexts/App";
import {StatusProps} from "constants/index";

export function useToast() {
    const { toastInfo, setToastInfo } = useContext(AppContext);

    function toast(message: string, status: StatusProps) {
        // setToastInfo({isOpen: true, message, status})
        console.log(message, 'message')
    }

    return {toast, toastInfo, setToastInfo}
}