/**
 * This is the dedicated component for the Accordion that is showed for every Search Result in Party Manager.
 */

import { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { useOktaAuth } from "@okta/okta-react";
import { getRelatedEntities, ultimateOwnerCall } from "../../../services/neo4jCalls";
import { initialUltimateOwnerState, RelatedEntity } from "../../../types";
import { FieldValueFilter } from "../../FieldValueFilter";
import {
    SearchResultsAccordionContainer,
    SearchResultsAccordionSingleField,
    SearchResultsAccordionSingleFieldTitle,
    SearchResultsAccordionSingleFieldValue,
    ShowMore,
    NameCover,
    Name,
    SearchResultsAccordionHierarchyInfo,
} from '../../../styles/searchResult/SearchResultAccordion.styled';
import { PATH } from 'constants/index';


export function SearchResultsAccordion({ idForNeo4j, mainActivity, category, description, identifiers }: {
    idForNeo4j: string, mainActivity?: string,
    category?: string, description?: string, identifiers?: Array<Array<string>>
}) {

    const { authState, oktaAuth } = useOktaAuth();

    // GUO
    let [guo, setGuo] = useState<RelatedEntity>(initialUltimateOwnerState);
    let [guoDisplay, setGuoDisplay] = useState(false)
    const displayGuo = () => {
        setGuoDisplay(!guoDisplay)
    }

    // DUO
    let [duo, setDuo] = useState<RelatedEntity>(initialUltimateOwnerState)
    let [duoDisplay, setDuoDisplay] = useState(false)
    const displayDuo = () => {
        setDuoDisplay(!duoDisplay)
    }

    // configurable parameters for SHO and SUB Neo4j calls
    const directFOPercentage = '5'
    const totalFOPercentage = '25'
    const limit = '100'


    // SHO
    let [sho, setSho] = useState<RelatedEntity[]>([])
    let shareholdersNumber = 0
    let [shareholdersDisplay, setShareholdersDisplay] = useState(false)
    const displayShareholders = () => {
        shareholdersDisplay ? setShareholdersDisplay(false) : setShareholdersDisplay(true)
    }

    // SUB
    let [sub, setSub] = useState<RelatedEntity[]>([])
    let subsidiariesNumber = 0
    let [subsidiariesDisplay, setSubsidiariesDisplay] = useState(false)
    const displaySubsidiaries = () => {
        subsidiariesDisplay ? setSubsidiariesDisplay(false) : setSubsidiariesDisplay(true)
    }

    useEffect(() => {

        if (authState && authState.isAuthenticated) {

            const accessToken = oktaAuth.getAccessToken()

            if (accessToken) {
                ultimateOwnerCall("GUO", idForNeo4j, accessToken).then((response: RelatedEntity) => setGuo(response))
                ultimateOwnerCall("DUO", idForNeo4j, accessToken).then((response: RelatedEntity) => setDuo(response))
                getRelatedEntities("SHO-STAT", idForNeo4j, directFOPercentage, totalFOPercentage, limit, accessToken).then((response: RelatedEntity[]) => setSho(response))
                getRelatedEntities("SUB-STAT", idForNeo4j, directFOPercentage, totalFOPercentage, limit, accessToken).then((response: RelatedEntity[]) => setSub(response))
            }
        }


    }, []);

    return (
        <SearchResultsAccordionContainer>
            {mainActivity && <SearchResultsAccordionSingleField>{mainActivity}</SearchResultsAccordionSingleField>}
            {category && <SearchResultsAccordionSingleField>{category}</SearchResultsAccordionSingleField>}
            {identifiers?.map((value: string[], index: number, array: string[][]) => (
                <SearchResultsAccordionSingleField key={index}>
                    <SearchResultsAccordionSingleFieldTitle>{value[0]}</SearchResultsAccordionSingleFieldTitle>
                    <SearchResultsAccordionSingleFieldValue>{value[1]}</SearchResultsAccordionSingleFieldValue>
                </SearchResultsAccordionSingleField>
            ))}
            {description && <FieldValueFilter fieldToFilter={description} />}

            {/* The following are the Hierarchy Stats */}
            <SearchResultsAccordionHierarchyInfo>


                {/* Hierarchy Stats: GUOs and DUOs */}
                <>
                    {guo.id !== "" && duo.id !== "" && guo.id === duo.id
                        ?
                        <>
                            <div><b>GUO/DUO: </b>1 <ShowMore onClick={() => displayGuo()}>{guoDisplay ? "Hide " : "Show"}</ShowMore></div>
                            {guoDisplay
                                && <NameCover>
                                    <Link to={`${PATH.PARTY}/${guo.id}`}><Name>{guo.name}</Name></Link>
                                </NameCover>
                            }
                        </>

                        : <div>
                            <>
                                <div><b>GUO: </b>1 <ShowMore onClick={() => displayGuo()}>{guoDisplay ? "Hide " : "Show"}</ShowMore></div>
                                {guoDisplay
                                    && <NameCover>
                                        <Link to={`${PATH.PARTY}/${guo.id}`}><Name>{guo.name}</Name></Link>
                                    </NameCover>
                                }
                            </>
                            <>
                                <div><b>DUO: </b>1 <ShowMore onClick={() => displayDuo()}>{duoDisplay ? "Hide " : "Show"}</ShowMore></div>
                                {duoDisplay
                                    && <NameCover>
                                        <Link to={`${PATH.PARTY}/${duo.id}`}><Name>{duo.name}</Name></Link>
                                    </NameCover>
                                }
                            </>
                        </div>
                    }
                </>

                <>

                </>

                {/* Hierarchy Stats: shareholders */}
                <>
                    {sho.length > 0
                        && sho.map((item, index) => {
                            if (item.id !== guo.id && item.id !== duo.id) {
                                shareholdersNumber++
                            }
                        })
                    }
                </>

                <>
                    {shareholdersNumber > 0 ? <div><b>SHO: </b>{shareholdersNumber} <ShowMore onClick={() => displayShareholders()}>{shareholdersDisplay ? "Hide " : "Show"}</ShowMore></div> : null}
                </>
                <>
                    {shareholdersDisplay
                        && <NameCover>
                            {sho.map((item, index) => {
                                if (item.id !== guo.id && item.id !== duo.id) {
                                    return (<Link to={`${PATH.PARTY}/${item.id}`} key={index}><Name>{item.name}</Name></Link>)
                                }
                            }
                            )}
                        </NameCover>
                    }
                </>

                {/* Hierarchy Stats: subsidiaires */}
                <>
                    {sub.length > 0 && sub.map((item, index) => { subsidiariesNumber++ })}
                </>
                <>
                    {subsidiariesNumber > 0 && <div><b>SUB: </b>{subsidiariesNumber} <ShowMore onClick={() => displaySubsidiaries()}>{subsidiariesDisplay ? "Hide " : "Show"}</ShowMore></div>}
                </>
                <>
                    {subsidiariesDisplay
                        && <NameCover>
                            {sub.map((item, index) => {
                                return (<Link to={`${PATH.PARTY}/${item.id}`} key={index}><Name>{item.name}</Name></Link>)
                            })}
                        </NameCover>
                    }
                </>
            </SearchResultsAccordionHierarchyInfo>
        </SearchResultsAccordionContainer>
    )
}