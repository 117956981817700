//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the main component for Party Profile
 */

import { Fragment, FunctionComponent } from "react";
import { Accordion } from "components/party/accordion/Accordion";
import TopHoldings from "./TopHoldings";
import List from "./List";
import SubFunds from "./subfunds/SubFunds";
import { PerformanceType } from "types";
import { Flex, ListCover, InfoList } from "styles/party/funds/index.styled";
import FundsDocument from "./FundsDocument";
import { FundDocumentsType } from "types/funds";
import { PortfolioBreakdown } from "../portfolioBreakdown/PortfolioBreakdown";
import FundManager from "./fundManager/FundManager";
import FundManagerDetails from "./fundManager/FundManagerDetails";
import NoData from "components/noData/NoData";

const groupD = [
    "Group Name",
    "Entity Group Name",
    "Entity Name"
]

const FundsInfoD = [
    "Currency",
    "ISIN",
    "Citicode",
    "SEDOL",
    "Fund Type",
    "Asset Class Title",
]

const PerformanceD = [
    "Price",
    "Yield",
    "Ratio Alpha",
    "Ratio Beta",
    "Ratio Volatility",
    "Ratio Sharpe",
    "Risk Score",
    "Morning Star Analyst Rating",
    "Discrete Performance 0 to 12 Months",
    "Discrete Performance 12 to 24 Months",
    "Discrete Performance 24 to 36 Months",
    "Annual Performance 12 Months",
    "Annual Performance 12 to 24 Months",
    "Annual Performance 24 to 36 Months",
    "Annual Performance 36 to 48 Months",
    "Annual Performance 48 to 60 Months",
    "Annual Performance YTD"
];

const FundsD = [
    "Fund Name",
    "Currency",
    "ISIN",
    "Citicode",
    "Legal Entity Identifier",
    "SEDOL",
    "Fund Type",
    "Asset Class Title",
    "Portfolio Sector",
    "Portfolio Asset Class",
    "Fund ID",
    "Child Fund ID"
]

const performanceData = (obj: PerformanceType) => {
    let res: { [key: string]: string } = {};

    for (const [key, value] of Object.entries(obj)) {
        if (PerformanceD.includes(key)) {
            res[key] = value;
        }
    }
    return res;
};

const groupData = (obj: PerformanceType) => {
    let res: { [key: string]: string } = {};

    for (const [key, value] of Object.entries(obj)) {
        if (groupD.includes(key)) {
            res[key] = value;
        }
    }
    return res;
};

const FundsInfoData = (obj: PerformanceType) => {
    let res: { [key: string]: string } = {};

    for (const [key, value] of Object.entries(obj)) {
        if (FundsInfoD.includes(key)) {
            res[key] = value;
        }
    }
    return res;
};

const fundsData = (obj: PerformanceType) => {
    let res: { [key: string]: string } = {};

    for (const [key, value] of Object.entries(obj)) {
        if (FundsD.includes(key)) {
            res[key] = value;
        }
    }
    return res
};

const GroupedDetails: FunctionComponent<{
    data: any;
    partyId: string;
    fundDocuments: FundDocumentsType;
    hideSubFund?: boolean
}> = ({ data, partyId, fundDocuments, hideSubFund = false }) => {
    if (!data) return null;
    return (
        <div>
            <Fragment>
                <Accordion title="Overview" light noTopBorder opened={true}>
                    <Fragment>
                        <Flex mb>
                            <ListCover>
                                <List data={performanceData(data)} title="Performance" />
                            </ListCover>
                            <InfoList>
                                <ListCover>
                                    <List small data={FundsInfoData(data)} title="Fund Info" />
                                </ListCover>
                                <ListCover>
                                    <List small data={groupData(data)} title="Group Info" />
                                </ListCover>
                            </InfoList>
                            <ListCover>
                                <TopHoldings
                                    data={
                                        data?.["Top Holdings"] ? JSON.parse(data?.["Top Holdings"]) : []
                                    }
                                    title="Top 10 Holdings"
                                />
                            </ListCover>
                        </Flex>
                    </Fragment>
                </Accordion>
                <Accordion title="Portfolio Breakdown" light noTopBorder opened={true}>
                    <PortfolioBreakdown data={
                        data ? data : []
                    } />
                </Accordion>
                <Accordion title="Fund Documents" light noTopBorder opened={true}>
                    <FundsDocument data={fundDocuments} />
                </Accordion>
                <Accordion title="Fund Manager" light noTopBorder opened={true}>
                    {
                        data?.["Fund Manager Details"] ? <FundManagerDetails data={data?.["Fund Manager Details"] ? JSON.parse(data?.["Fund Manager Details"]) : []} />
                            :
                            data?.["Fund Manager"] ? <FundManager data={data?.["Fund Manager"] ? JSON.parse(data?.["Fund Manager"]) : []} />
                                : <NoData />
                    }
                </Accordion>
                {!hideSubFund && <Accordion title="Sub-Funds" light noTopBorder opened={true}>
                    {!data?.['Child Fund ID'] ? <NoData /> : <SubFunds
                        filter=""
                        childFundIds={data['Child Fund ID']}
                        data={fundsData(data)} />}
                </Accordion>}
            </Fragment>
        </div>
    );
};

export default GroupedDetails;
