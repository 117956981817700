import NoDataContent from "components/noData/NoDataContent";
import { FC } from "react";
import {
    TileContainer,
    TilePersonIcon,
    TilePersonTitle,
    TilePersonIconContainer,
    TilePersonName,
    TilePersonDetailsContainer,
} from 'styles/party/keyPeople/TilePerson.styled' ;

const ListFundManager: FC<{ data: {[key:string]:string}, small?:boolean; title?:string }> = ({ data, small, title }) => {
    return (
        <TileContainer>
            <TilePersonIconContainer>
                <TilePersonIcon/>
            </TilePersonIconContainer>
            <TilePersonDetailsContainer>
            {data ? Object.entries(data).map(([key, value]) => (
                    key === 'Name' ?
                        <TilePersonName key={key}>{value}</TilePersonName>
                        :
                        <TilePersonTitle key={key}>{key}: {value}</TilePersonTitle>
            )) : <NoDataContent /> }
            </TilePersonDetailsContainer>
        </TileContainer>
    )
}

export default ListFundManager;