import {initialUltimateOwnerState, JsonData, Neo4jResultJson, RelatedEntity} from "../types";
import {ULTIMATE_OWNER_GCF} from "../config/googleCloudFunctionsConfig";

export const ultimateOwnerCall = async (ultimateOwnerType:string, idForNeo4j:string, accessToken?:string) => {

    // GUO_FOR_SEARCH_SUGGESTION
    let suggestion:RelatedEntity = initialUltimateOwnerState
    // GUO
    let guo:RelatedEntity = initialUltimateOwnerState
    // DUO
    let duo:RelatedEntity = initialUltimateOwnerState

    if (accessToken) {

        const paramsObj = {
            accessToken:accessToken,
            queryId: idForNeo4j,
            ultimateOwnerType:ultimateOwnerType
        }

        const searchParams = new URLSearchParams(paramsObj)

        const googleFunctionApiUrlForNeo4j:string = ULTIMATE_OWNER_GCF + "?" + searchParams.toString().replace(/%2f/gi,'/')

        await fetch(googleFunctionApiUrlForNeo4j)
            .then(response => {
                if (!response.ok) {
                    throw new Error("Server reply: " + response.ok)
                }
                return response
            })
            .then(response => response.json())
            .then((responseJson: Neo4jResultJson) => {
                if(responseJson.results[0].data.length > 0){
                    if (ultimateOwnerType === "GUO_FOR_SEARCH_SUGGESTION") {
                        let isGuoCheck = responseJson.results[0].data[0].row[0].id === responseJson.results[0].data[0].row[1].id;
                        if (isGuoCheck) suggestion = responseJson.results[0].data[0].row[0]
                        else suggestion = responseJson.results[0].data[0].row[1]
                        suggestion.isGuo = isGuoCheck
                        suggestion.targetName = responseJson.results[0].data[0].row[1].name
                    }
                    else if(ultimateOwnerType === "GUO") {
                        guo = responseJson.results[0].data[0].row[0]
                        guo.notInNeo = false
                    }
                    else {
                        duo = responseJson.results[0].data[0].row[0]
                        duo.notInNeo = false
                    }
                }
            }).catch(err => {
                // TODO: re-add ErrorDisplay here
                // ErrorDisplay(err, "API call to Neo4j server for retrieving " + ultimateOwnerType, "ultimateOwner")
            })

    }


    if (ultimateOwnerType === "GUO_FOR_SEARCH_SUGGESTION") return suggestion
    else if(ultimateOwnerType === "GUO") return guo
    else return duo
}

/**
 * This is the dedicated function for getting related entities (shareholders and subsidiaries) given the following parameters:
 * - relatedEntityType
 * - idForNeo4j
 * - directFOPercentage
 * - totalFOPercentage
 * - limit
 */

// function for getting related entities (shareholders and subsidiaries) info from Neo4j
export const getRelatedEntities = async (relatedEntityType: string, idForNeo4j:string, directFOPercentage:string, totalFOPercentage:string, limit:string, accessToken:string) => {

    let shareholdersContainer:RelatedEntity[] = []
    let subsidiariesContainer:RelatedEntity[] = []

    const paramsObj = {
        accessToken:accessToken,
        queryId: idForNeo4j,
        ultimateOwnerType:relatedEntityType,
        limit: limit,
        directFOPercentage:directFOPercentage,
        totalFOPercentage:totalFOPercentage
    }

    const searchParams = new URLSearchParams(paramsObj)

    const googleFunctionApiUrlForNeo4j:string = ULTIMATE_OWNER_GCF + "?" + searchParams.toString().replace(/%2f/gi,'/')

    await fetch(googleFunctionApiUrlForNeo4j)
        .then(response => {
            if (!response.ok) {
                throw new Error("Server reply: " + response.ok)
            }
            return response
        })
        .then(response => response.json())
        .then((responseJson: Neo4jResultJson) => {
            if (responseJson.results[0].data.length > 0) {
                responseJson.results[0].data.map((item: JsonData) => {

                    relatedEntityType === "SHO"
                        ?   shareholdersContainer.push(item.row[0])
                        :   subsidiariesContainer.push(item.row[0])

                })
            }

        })
        .catch(err => {
            // TODO: re-add the ErrorDisplay
            // ErrorDisplay(err, "API call to Neo4j server for retrieving " + relatedEntityType, "relatedEntities")
        })

    if(relatedEntityType === "SHO")
        return shareholdersContainer
    else
        return subsidiariesContainer
}

// function for retrieving the Neo4j results specifically for hierarchy
export const getRelatedEntities4Hierarchy = async (relatedEntityType: string, idForNeo4j: string, directFOPercentage: string, totalFOPercentage: string, limit: string, accessToken:string) => {

    const paramsObj = {
        accessToken:accessToken,
        queryId: idForNeo4j,
        ultimateOwnerType:relatedEntityType,
        limit:limit,
        directFOPercentage:directFOPercentage,
        totalFOPercentage:totalFOPercentage
    }

    const searchParams = new URLSearchParams(paramsObj)

    const googleFunctionApiUrlForNeo4j:string = ULTIMATE_OWNER_GCF + "?" + searchParams.toString().replace(/%2f/gi,'/')

    return fetch(googleFunctionApiUrlForNeo4j)
        .then(response => {
            if (!response.ok) {
                throw new Error("Server reply: " + response.ok)
            }
            return response
        })
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(err => {
            // TODO: re-add ErrorDisplay
            //ErrorDisplay(err, "API call to Neo4j for " + relatedEntityType, "relatedEntities4Hierarchy")
        })
}



