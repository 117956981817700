import styled from "styled-components";

export const CustomIcon = styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const waitingScan = `${process.env.PUBLIC_URL}/assets/waiting-scan.gif`;
