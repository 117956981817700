import styled from 'styled-components';

export const SearchResultsContainer = styled.div`
    a {
        text-decoration: none;
    }
    p {
      color: ${({theme}) => theme.colors.DARK[500]};
    }
`;
