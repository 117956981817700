import {
  Container,
  Modal,
  ErrorIcon,
  ModalTitle,
  ModalBody,
  ModalActions,
  OkButton,
  errorIcon
} from "styles/alert/docsUploadAndSavings/ErrorModal.styled";

interface DocumentsUploadProps {
    onClose: () => void;
    title: string;
    message: string;
    show: boolean
}

const ErrorModal = ({ onClose, title, message, show }: DocumentsUploadProps) => {

    return (
        show ?
            <Container>
                <Modal>
                    <ErrorIcon src={errorIcon}/>
                    <ModalTitle>{title}</ModalTitle>
                    <ModalBody>{message}</ModalBody>
                    <ModalActions>
                        <OkButton onClick={onClose}>Ok</OkButton>
                    </ModalActions>
                </Modal>
            </Container>
            :
            <></>
    )
}

export default ErrorModal;