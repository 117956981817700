//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the accordion Title
 */

import {
  Container,
  Text,
  ArrowIcon,
} from "styles/party/accordion/Title.styled";
import { TitleProps } from "types";

export function Title({ text, isOpen,hideIcon, light, toggle, small }: TitleProps) {
  return (
    <Container data-testid={text} onClick={toggle}>
      <Text light={light} small={small}>{text}</Text>
      {!hideIcon && <ArrowIcon rotate={isOpen ? 1 : 0} title="Open" />}
    </Container>
  );
}
