import styled from "styled-components";

const ModalHouse = styled.div`
  width: 1040px;
  height: 443px;
  padding: 1px 1px 1px 20px;
  background: ${({theme}) => theme.colors.WHITE };
  border-radius: 20px;
`;

const ModalTitle = styled.div`
  width: auto;
  height: 24px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.DARK[400] };
  margin-top: 64px;
  margin-left: 40px;
`;

const DocumentTypeContainer = styled.div`
  position: relative;
  width: 933px;
  height: 259px;
  margin-left: 40px;
  margin-top: 20px;
  padding-top: 10px;
  background-color: ${({theme}) => theme.colors.GREYED[775] };
  border-radius: 12px;
`;

const DocumentTitle = styled.div`
  width: auto;
  height: 27px;
  margin: 40px auto 24px 30px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.TITLE[500] };
`;

const DocumentDetailsContainer = styled.div`
  width: 746px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 12px auto 12px 20px;
`;

const EditDetailLabel = styled.div`
  width: 164px;
  height: 19px;
  margin: 1px 10px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${({theme}) => theme.colors.DARK[400] };
`;

const EditInput = styled.input`
  width: 425px;
  height: 48px;
  margin: 1px 10px;
  padding-left: 16px;
  background-color: ${({theme}) => theme.colors.WHITE };
  border: 0;
  border-radius: 4px;
  
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    padding-top: 16px;
    color: ${({theme}) => theme.colors.GREYED[500] };
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    padding-top: 16px;
    color: ${({theme}) => theme.colors.GREYED[500] };
  }
  &::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    padding-top: 16px;
    color: ${({theme}) => theme.colors.GREYED[500] };
  }
  &::-webkit-input-placeholder { /* Edge */
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    padding-top: 16px;
    color: ${({theme}) => theme.colors.GREYED[500] };
  }
`;

const SaveButton = styled.div`
  position: absolute;
  bottom: 40px;
  left: 215px;
  width: 111px;
  height: 48px;
  padding-top: 12px;
  background: ${({theme}) => `${theme.colors.PRIMARY[400]}` };
  border-radius: 8px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.WHITE };
  text-align: center;
  cursor: pointer;
`;


export const EcsuStyle = {
    ModalHouse,
    ModalTitle,
    DocumentTypeContainer,
    DocumentTitle,
    DocumentDetailsContainer,
    EditDetailLabel,
    EditInput,
    SaveButton
};
