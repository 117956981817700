//Copyright Marco Rapaccini, Marcello Di Fronzo Gravallese and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
* This is the dedicated component for the Search Suggestions' single items.
*/

import {useEffect, useState} from 'react';
import {RelatedEntity, SetSearchSuggestionsHasFinished} from '../../types';
import {Link} from 'react-router-dom';
import {
    SuggestionItem,
    SuggestionItemIcon,
    SuggestionItemName
 } from 'styles/searchSuggestion/SearchSuggestionItem.styled';
import { PARTY, PATH } from 'constants/index';
import { useAppData } from 'hooks/useAppData';

export const SearchSuggestionItem = (
{ entityToDisplay, setSearchSuggestionsHasFinished, isLast, isIdentifierSearch }:
{ entityToDisplay: RelatedEntity, setSearchSuggestionsHasFinished: SetSearchSuggestionsHasFinished, isLast:boolean, isIdentifierSearch:boolean}
) => {

let [entityName, setEntityName] = useState<string>('')
const { isUserPartOfOrginaization } = useAppData();


useEffect(() => {
     // if we already have a name, just use it
     if (entityToDisplay.name && entityToDisplay.name.trim()!== "") {
         setEntityName(entityToDisplay.name)
         // if we are at the last Search Suggestion Item, do not show anymore the loader bar in Search Suggestions
         isLast && setSearchSuggestionsHasFinished(true)
     }
 }, [entityToDisplay.name, isLast, setSearchSuggestionsHasFinished])


return (
  <>
    {entityName !== "" && (
      //  switch this pathname to funds search results page if we click from the funds search page
        <Link to={{ pathname: (isIdentifierSearch ? `${PATH.PARTY}/` : `${isUserPartOfOrginaization([PARTY.HUBWISE]) ? PATH.FUND_SEARCH_RESULT : PATH.SEARCH_RESULT}/`) + entityToDisplay.id }} style={{ color: '#333' }}>
            <SuggestionItem>
          {entityToDisplay.isGuo ? (
            <SuggestionItemIcon typeOfIcon="GUO" />
          ) : (
            (entityToDisplay.notInNeo && (
              <SuggestionItemIcon typeOfIcon="NO_NEO" />
            )) ||
            (!entityToDisplay.notInNeo && (
              <SuggestionItemIcon typeOfIcon="NEO" />
            ))
          )}
          <SuggestionItemName>{entityName}</SuggestionItemName>
        </SuggestionItem>
      </Link>
    )}
  </>
);
}
