import { ImageCover, Title, Right, ListCover, List } from "styles/party/SSI.styled";
import DATA from "data/ssiCountries.json";
import { Chart } from "react-google-charts";
import { useAppData } from "hooks/useAppData";
import { CHART_TYPE } from "constants/index";

const activeCountries = (initials:string[]):string[] => {
    const res:string[] = [];

    initials.forEach((c) => {
        if(c === 'GB') {
            res.push('UNITED KINGDOM')
            res.push('LONDON')
        }else if(c === 'DE') {
            res.push('GERMANY')
        } else if(c === 'US') {
            res.push('UNITED STATE')
        }
    })

    return res;

};
const SSIImage = ({Cinitials}:{Cinitials:string[]}) => {
    const { colors, status } = useAppData();
    
    return (
        <ImageCover>
            <div style={{width:'40%'}}>
                <Chart
                    chartType={CHART_TYPE.GEO}
                    width="100%"
                    height="200px"
                    data={[['Region Code', 'Continent'],
                    ['150', 'Europe'],
                ]}
                    options={{
                        legend: false,
                        backgroundColor: status === 'dark' ? '#222425' : '#fff',
                        resolution: 'continents',
                        defaultColor: colors.PRIMARY[400]
                    }}
                />
            </div>
            <Right>
                <Title>
                    <p>
                        Europe
                    </p>
                </Title>
                <ListCover>
                    {
                        DATA.map((c, i) => (
                            <List active={activeCountries(Cinitials)?.includes(c)} key={i}>{c}</List>
                        ))
                    }
                </ListCover>
            </Right>
        </ImageCover>
    )
}

export default SSIImage;