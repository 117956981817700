import styled from "styled-components";

export const TileAndModal = styled.div`
flex-wrap: wrap;
position: relative ;
color: ${({theme}) => theme.colors.PRIMARY[400]};
`;

export const TileContainer = styled.div`
max-width: 350px;
display: flex;
flex-direction: row;
flex: 1;
align-items: center;
padding: 31px 61px 30px 20px;
margin-bottom: 20px;
background: rgba(54, 64, 115, 0.05);
border-radius: 25px;
height: 100px;
cursor: pointer;
transition: 0.5s;
`;

export const TilePersonIconContainer = styled.div`
display: flex;
flex-direction: column;
`;

export const TilePersonDetailsContainer = styled.div`
display: flex;
flex-direction: column;
margin-left: 20px;

& div{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;

    &:last-child{
        margin-bottom: 0px;
    }
}
`;

export const TilePersonIcon = styled.span`
display: block;
width: 40px;
height: 40px;
background-image: url(${process.env.PUBLIC_URL+"/assets/avatar.svg"});
background-repeat: no-repeat;
`;

export const TilePersonTitle = styled.div`
color: ${({theme}) => theme.colors.GREY[500]};
font-size: 16px;
font-family: Roboto Condensed;
`;

export const TilePersonName = styled.div``;

export const NameDiv = styled.div`
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-weight: 700;
  font-size: 18px;
  font-family: Roboto Condensed;
`;