//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.
import { Title } from "styles/ComingSoon.styled"

/**
 * This is the dedicated component to show when there's no data to display.
 */


export function NoDataToDisplay() {
    return (<Title><em>Content coming soon</em></Title>)
}