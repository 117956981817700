import Bar from "components/admin/Bar";
import ModalComponent from "components/modal";
import { useAppData } from "hooks/useAppData";
import useModal from "hooks/useModal";
import {
    Container,
    SingleRow,
    Section,
    SectionTitle,
    BarsContainer,
    LabelContainer,
    SmallTitle,
    Cover,
} from "styles/party/portfolioBreakdown/PortfolioBreakdown.styled";
import {ElementPortfolioBreakdown, ItemsPortfolioBreakdown, PortfolioBreakdownData} from "../../../types";

const DATA = [
    { id: "Asset Class BreakDown", key: "Asset", title: "Asset Location" },
    { id: "Region Breakdown", key: "Region", title: "Regional Exposure" },
    { id: "Sector Breakdown", key: "Sector", title: "Sector Allocation" }
]

const parseData = (data: PortfolioBreakdownData, s: string) => {
    let str = s as keyof ItemsPortfolioBreakdown
    if (!data?.data?.[str]) return [];
    return JSON.parse(data.data[str]);
};
export function PortfolioBreakdown(data: PortfolioBreakdownData) {
    const modal = useModal<{ key: string; id: string; title: string }>();

    const { colors } = useAppData();

    const listOfColors = [
        colors.LINEAR_GRADIENT[400],
        colors.LINEAR_GRADIENT[500],
        colors.LINEAR_GRADIENT[200],
        colors.LINEAR_GRADIENT[300],
        colors.LINEAR_GRADIENT[150],
        colors.LINEAR_GRADIENT[400],
        colors.LINEAR_GRADIENT[120],
        colors.LINEAR_GRADIENT[100],
        colors.LINEAR_GRADIENT[75],
        colors.LINEAR_GRADIENT[70],
        colors.LINEAR_GRADIENT[60],
        colors.LINEAR_GRADIENT[50],
    ];

    if (!data?.data) return null;
    return (
        <Container>
            <LabelContainer>
                {
                    ["Portfolio Sector", "Portfolio Asset Class"].map((el) => (
                        <SectionTitle key={el}>
                            <SmallTitle>Portfolio Sector:</SmallTitle>
                            {data.data["Portfolio Sector"]}
                        </SectionTitle>
                    ))
                }

            </LabelContainer>
            <SingleRow>
                {DATA.map(({ id, key, title }) => (
                    parseData(data, id).length ? (
                        <Section key={id} onClick={() => modal.open({ id, key, title })}>
                            <SectionTitle>{title}</SectionTitle>
                            <BarsContainer>
                                {parseData(data, id).map(
                                    (el: ElementPortfolioBreakdown, i: number) => {
                                        let k = key as keyof ElementPortfolioBreakdown
                                        return (
                                            <Bar
                                                percent={parseInt(el["Percentage"])}
                                                title={el[k]}
                                                key={i}
                                                color={listOfColors[i]}
                                                isGrey
                                            />
                                        );
                                    }
                                )}
                            </BarsContainer>
                        </Section>
                    ) : null
                ))}
            </SingleRow>
            <ModalComponent controller={modal} medium>
                <Cover onClick={modal.close}>
                    <div className="button-cover">
                        <button>Close</button>
                    </div>
                    {parseData(data, modal.modalData?.id).length ? (
                        <Section full>
                            <SectionTitle>{modal.modalData?.title}</SectionTitle>
                            <BarsContainer>
                                {parseData(data, modal.modalData.id).map((el: ElementPortfolioBreakdown, i: number) => {
                                    let k = modal.modalData?.key as keyof ElementPortfolioBreakdown
                                    return (
                                        <Bar
                                            percent={parseInt(el["Percentage"])}
                                            title={el[k]}
                                            key={i}
                                            color={listOfColors[i]}
                                            isGrey
                                        />
                                    );
                                })}
                            </BarsContainer>
                        </Section>
                    ) : null}
                </Cover>
            </ModalComponent>
        </Container>
    );
}
