import {
  Container,
  Header,
  HeaderInfo,
  Title,
  Content,
  CardContainer,
  ReportTracker,
  LeftSection,
  RightSection,
  CardInfoContainer,
  Analytics,
  ClosingRate,
  AnalyticsHeader,
  RateHeaderText,
  RateSmallText,
  TimeCardContainer,
  BottomTimeCards,
  TopTimeCards,
  Status,
  StatusBox,
  StatusItem,
  StatusImage,
  StatusInfo,
  StatusWrapper,
  StatusItemTitle,
  DocsSection,
  DocItem,
  DocTitle,
  DocImage,
  DocInfo,
  DocNumber,
  DownloadBtn,
  DownloadIcon,
} from "styles/client/Report.styled";
import Cards from "./cards/Cards";
import data from "data/report.json";
import InfoCard from "./cards/InfoCard";
import TimeCards from "./cards/TimeCards";
import { Chart } from "react-google-charts";
import {
  alertStatusCardsData,
  bottomTimeCardsData,
  cardsData,
  infoCardData,
  topTimeCardsData,
  transactionStatusCardsData,
} from "constants/reports";
import { useAppData } from "../../hooks/useAppData";
import ReportData from "../../data/report.json";

export const DATA = [
    ["Incorrect", "Missing", "Issues", "Additional"],
    [2 / 3, -1, 0, 0],
    [2 / 3, -1, 0, null],
    [2 / 3, -1, 0, null],
    [-1 / 3, 0, 1, null],
    [-1 / 3, 0, -1, null],
    [-1 / 3, 0, 1, null],
    [-1 / 3, 0, 1, null],
  ]

export const DOUGHNUT_DATA = [
  ["Task", "Hours per Day"],
  ["", 11],
  ["", 2],
];

const scannedDocsIcon = process.env.PUBLIC_URL + "/assets/ScannedDocIcon.svg";
const transactionIcon = process.env.PUBLIC_URL + "/assets/TransactionIcon.svg";
const downloadIcon = process.env.PUBLIC_URL + "/assets/downloadIcon.svg";

function Reports() {
  const { colors } = useAppData();

  const doughnutOptions = {
    pieHole: 0.4,
    is3D: false,
    colors: [colors.PRIMARY[700], colors.PRIMARY[10]],
  };

  const options = {
    title: "",
    legend: { position: "top", maxLines: 2 },
    colors: [
      colors.PRIMARY[400],
      colors.PRIMARY[600],
      colors.SECONDARY[100],
      colors.YELLOW[400],
    ],
    interpolateNulls: false,
  };

  const exportData = () => {
    const jsonString = `data:text/xlsx;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(ReportData)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "report.xlsx";
    link.click();
  };

  return (
    <Container>
      <Header>
        <Title>Reports</Title>
        <HeaderInfo>Keep track of requests and their activities</HeaderInfo>
      </Header>
      <Content>
        <CardContainer>
          {cardsData.map((data, index) => (
            <Cards key={index} num={data.num} data={data.data} />
          ))}
        </CardContainer>
        <ReportTracker>
          {/* left section */}
          <LeftSection>
            <Analytics>
              <AnalyticsHeader>Analytics Report</AnalyticsHeader>
              <Chart
                chartType="Histogram"
                width="100%"
                data={DATA}
                options={options}
              />
            </Analytics>
            <CardInfoContainer>
              {infoCardData.map((data, index) => (
                <InfoCard
                  key={index}
                  icon={data.icon}
                  num={data.num}
                  name={data.name}
                />
              ))}
            </CardInfoContainer>
          </LeftSection>
          {/* right section */}
          <RightSection>
            <ClosingRate>
              <RateHeaderText>Closing Rate</RateHeaderText>
              <RateSmallText>Check your score</RateSmallText>
              <Chart
                chartType="PieChart"
                data={DOUGHNUT_DATA}
                options={doughnutOptions}
                width={"100%"}
              />
            </ClosingRate>
            <TimeCardContainer>
              <TopTimeCards>
                {topTimeCardsData.map((item, index) => (
                  <TimeCards
                    key={index}
                    title={item.title}
                    icon={item.icon}
                    time={item.time}
                  />
                ))}
              </TopTimeCards>
              <BottomTimeCards>
                {bottomTimeCardsData.map((item, index) => (
                  <TimeCards
                    key={index}
                    title={item.title}
                    icon={item.icon}
                    time={item.time}
                  />
                ))}
              </BottomTimeCards>
            </TimeCardContainer>
          </RightSection>
        </ReportTracker>
        <Status>
          <StatusWrapper>
            <StatusItemTitle>Transaction Status</StatusItemTitle>
            <StatusBox>
              {transactionStatusCardsData.map((item, index) => (
                <StatusItem key={index}>
                  <StatusImage src={item.img} />
                  <StatusInfo color={item.color}>{item.data}</StatusInfo>
                </StatusItem>
              ))}
            </StatusBox>
          </StatusWrapper>
          <StatusWrapper>
            <StatusItemTitle>Alert Status</StatusItemTitle>
            <StatusBox>
              {alertStatusCardsData.map((item, index) => (
                <StatusItem key={index}>
                  <StatusImage src={item.img} />
                  <StatusInfo color={item.color}>{item.data}</StatusInfo>
                </StatusItem>
              ))}
            </StatusBox>
          </StatusWrapper>
        </Status>
        <DocsSection>
          <DocItem>
            <DocTitle>
              Scanned
              <br /> Documents
            </DocTitle>
            <DocInfo>
              <DocImage src={scannedDocsIcon} />
              <DocNumber>{data.scannedDocuments}</DocNumber>
            </DocInfo>
          </DocItem>
          <DocItem>
            <DocTitle>
              Scanning <br /> Transactions
            </DocTitle>
            <DocInfo>
              <DocImage src={transactionIcon} />
              <DocNumber>{data.scanningTransactions}</DocNumber>
            </DocInfo>
          </DocItem>
        </DocsSection>
        <DownloadBtn onClick={exportData}>
          <DownloadIcon src={downloadIcon} />
          Download
        </DownloadBtn>
      </Content>
    </Container>
  );
}

export default Reports;
