/**
 * Function that creates and returns an object required for each specific document type.
 * @param docs an array of DocumentData
 */ import {generateRandomString} from "./randomStringGenerator";
import {AirTableDocumentData, AirTableSelectedDocument} from "../types";

export const createDocumentsObject = (docs:AirTableDocumentData[]) => {
    let documentsObject = {}
    /*
        Each selected document will be identified by a randomly-generated name, e.g.:
            "name-saved-abxn52VoS0": {
				"Surname": "Example Surname",
				"Given Name": "Example Name ",
                ...,
				"Code of issuing State": "GBR"
			},
     */
    docs.forEach((doc:AirTableDocumentData) => {
        documentsObject = {
            ...documentsObject,
            ["name-saved-" + generateRandomString(10)]:doc
        }
    })
    return documentsObject
}

/**
 * Function that creates and returns the right Object payload that is necessary for an API call.
 * @param accessToken a string that represents the access token
 * @param metadata an Object with the metadata that the Google Cloud Function
 * @param selectedDocuments an array of SelectedDocument(s) that the user has selected
 */
export const createControllerPayload = (accessToken: string, metadata:Object, selectedDocuments:AirTableSelectedDocument[]):Object => {

    let passports:AirTableDocumentData[] = []
    let utilityBills:AirTableDocumentData[] = []
    let drivingLicences:AirTableDocumentData[] = []

    // this can be done better, but for now it's ok
    selectedDocuments.forEach( (selectedDocument:AirTableSelectedDocument) => {
        const data = selectedDocument.documentData
        if(selectedDocument.typeOfDocument === 'passport'){
            passports.push(data)
        }else if (selectedDocument.typeOfDocument === 'utility_bill'){
            utilityBills.push(data)
        }else{
            drivingLicences.push(data)
        }
    })

    return {
        accessToken: accessToken,
        metadata: metadata,
        documents: {
            ...(passports.length !==0 && { "passport": createDocumentsObject(passports)}),
            ...(utilityBills.length !==0 && {"utility bill": createDocumentsObject(utilityBills)}),
            ...(drivingLicences.length !==0 && {"driving licence": createDocumentsObject(drivingLicences)} )
        }
    }

}