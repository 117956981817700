export const docTypes = [
    {
        index: 0,
        DocTypeName: 'Driving Licence',
        DocDetails: [
            {label: 'Full Name'},
            {label: 'Licence Number'},
            {label: 'Date of Issue'},
            {label: 'Address'},
            {label: 'DOB'},
        ]
    },
    {
        index: 1,
        DocTypeName: 'Passport',
        DocDetails: [
            {label: 'Full Name'},
            {label: 'Address'},
            {label: 'Passport Number'},
            {label: 'DOB'},
            {label: 'Date of Expiry'},
        ]
    },
    {
        index: 2,
        DocTypeName: 'Identity Card',
        DocDetails: [
            {label: 'Full Name'},
            {label: 'Address'},
            {label: 'DOB'},
            {label: 'Date of Issue'},
            {label: 'Date of Expiry'},
        ]
    },
    {
        index: 3,
        DocTypeName: 'Utility Bill',
        DocDetails: [
            {label: 'Full Name'},
            {label: 'Address'},
            {label: 'DOB'},
            {label: 'Date of Issue'},
            {label: 'Date of Expiry'},
        ]
    },
]