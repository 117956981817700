import { FC } from "react";
import { Cover } from "styles/party/funds/index.styled";
import { FundManagerType } from "types/funds";
import ListFundManager from "./ListFundManager";

const keys = ['Fund Manager Name']

const fundData = (obj: FundManagerType) => {
    let res: { [key: string]: string } = {};
    for (const [key, value] of Object.entries(obj)) {
        if (keys.includes(key)) {
            if(key === 'Fund Manager Name') {
                res['Name'] = value
            }else {
                res[key] = value;
            }
        }
    }
    return res;
};


const FundManager: FC<{ data: FundManagerType[]; title?: string }> = ({ data, title }) => {
    if (!data?.length) return null;
    return (
        <Cover>
            {data.map((datum) => (
                <div className="each" key={datum["Fund Manager Name"]}>
                    <ListFundManager data={fundData(datum)} small/>
                </div>
            ))}
        </Cover>
    )
}

export default FundManager;