import { FunctionComponent } from "react";
import { ModalController } from "../../hooks/useModal";
import ModalComponent from "../modal";
import { EcsuStyle } from "../../styles/EditConfirmSubmitUpload.styled";

const EditConfirmSubmitUpload:FunctionComponent<{controller:ModalController,  currentParameter: string, saveButtonAction:() => void}> = ({controller,  currentParameter, saveButtonAction}) => {
    return (
        <ModalComponent controller={controller} >
            <EcsuStyle.ModalHouse>
                <EcsuStyle.ModalTitle>{"Please check the text fields for each document before submitting"}</EcsuStyle.ModalTitle>
                <EcsuStyle.DocumentTypeContainer>
                    <EcsuStyle.DocumentTitle>Edit {currentParameter}</EcsuStyle.DocumentTitle>
                    <EcsuStyle.DocumentDetailsContainer>
                        <EcsuStyle.EditDetailLabel>{currentParameter}</EcsuStyle.EditDetailLabel>
                        <EcsuStyle.EditInput placeholder={"pre-filled information from doc"} />
                    </EcsuStyle.DocumentDetailsContainer>
                    <EcsuStyle.SaveButton onClick={saveButtonAction}>Save</EcsuStyle.SaveButton>
                </EcsuStyle.DocumentTypeContainer>
            </EcsuStyle.ModalHouse>
        </ModalComponent>
    )
}

export default EditConfirmSubmitUpload;