import { EmptyImportVariablesObject, ImportVariables } from "types";
import { parseKeyValue } from "./parseKeyValue";

/**
 * Read the raw Import Variables, extracting a more structured object with key-value pairs.
 * @param {ArrayBuffer} arrayBuffer an ArrayBuffer representing the raw file downloaded with the Import Variables
 */
 export const readImportVariables = async (arrayBuffer: ArrayBuffer): Promise<ImportVariables> => {
    let rawImportVariables = new TextDecoder().decode(arrayBuffer);
    let importVariables: ImportVariables = EmptyImportVariablesObject;
    // take each line that has a content and parse the line in order to get a key-value pair
    rawImportVariables.split('\n').filter(line => line.length > 0).forEach(line => {
        const keyValuePairArray = parseKeyValue(line);
        // assign the key-value pair to the ImportVariables object
        importVariables[keyValuePairArray[0]] = keyValuePairArray[1];
    })
    return importVariables;
}