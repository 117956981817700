import {WORKLOAD_AUDIENCE, WORKLOAD_READ_SERVICE_ACCOUNT} from "../../config/googleCloudConfig";

/**
 * Function that takes an oktaAccessToken as input and exchanges it for a Federated Google Token;
 * then maps the STS Token with the Google Service Account.
 */
export const getFederatedToken = async (oktaAccessToken: string) => {

    const fetchBody = JSON.stringify(
        {
            "subjectToken": oktaAccessToken,
            "subjectTokenType": "urn:ietf:params:oauth:token-type:jwt",
            "grantType": "urn:ietf:params:oauth:grant-type:token-exchange",
            "requestedTokenType": "urn:ietf:params:oauth:token-type:access_token",
            "audience": WORKLOAD_AUDIENCE,
            "scope": "https://www.googleapis.com/auth/cloud-platform",
            "service_account_impersonation_url": `https://iamcredentials.googleapis.com/v1/projects/-/serviceAccounts/${WORKLOAD_READ_SERVICE_ACCOUNT}:generateAccessToken`,
        }
    )

    // exchange the OKTA token for a Federated Google Token
    try {
        const response = await fetch("https://sts.googleapis.com/v1beta/token", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: fetchBody
        })
        const result_1 = await response.json()
        if (result_1) {
            const stsAccessToken = result_1.access_token

            // Mapping the Federated token to the Google Service Account
            return fetch(`https://iamcredentials.googleapis.com/v1/projects/-/serviceAccounts/${WORKLOAD_READ_SERVICE_ACCOUNT}:generateAccessToken`, {
                body: "{'scope':['https://www.googleapis.com/auth/cloud-platform']}",
                headers: {
                    Accept: "application/json",
                    'Authorization': "Bearer " + stsAccessToken,
                    "Content-Type": "application/json"
                },
                method: "POST"
            }).then(response_1 => response_1.json())
                .then(
                    impersonatingToken => {
                        if (impersonatingToken) {
                            const returnValue: string = impersonatingToken.accessToken
                            return returnValue
                        }
                    }
                )
                .catch((err: Error) => {})
        }
    } catch (err_1) {
        return
    }
}
