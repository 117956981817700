import styled from "styled-components";
import { HEADER_STYLE, HEADER_TYPE } from "../../types";
import MenuIcon from '@material-ui/icons//Menu';

export const t360Logo = `${process.env.PUBLIC_URL}/assets/t360-header-logo.svg`;
export const t360LogoPurple = `${process.env.PUBLIC_URL}/assets/t360-header-logo-purple.svg`;

export const searchIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-search-white.svg`;
export const askAliceIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-ask-alice-white.svg`;
export const alertIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-alert-white.svg`;
export const adminRoomsIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-admin-rooms-white.svg`;
export const searchIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-search-blue.svg`;
export const askAliceIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-ask-alice-blue.svg`
export const alertIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-alert-blue.svg`;
export const notificationIconWhite = `${process.env.PUBLIC_URL}/assets/header-icon-notifications-white.svg`;
export const notificationIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-notifications-blue.svg`;
export const adminRoomsIconBlue = `${process.env.PUBLIC_URL}/assets/header-icon-admin-rooms-blue.svg`;


export const StyledMenuIcon = styled(MenuIcon)`
 margin-right: 10px ;
 @media(min-width:769px) {
    display: none !important;
 }
`

export const StyledHeader = styled.header<{ headerStyle: HEADER_STYLE }>`
    position: sticky;
    top: 0;
    z-index: 9;
    color: ${props => [HEADER_TYPE.DEFAULT, HEADER_TYPE.FUNDS].includes(props.headerStyle) ? props.theme.colors.WHITE : props.theme.colors.BLACK[400]};
    background: ${props => [HEADER_TYPE.DEFAULT, HEADER_TYPE.FUNDS].includes(props.headerStyle)? `linear-gradient(180deg, #363868 0%, #47527C 116.67%)` : props.theme.colors.WHITE};
    display: ${props => props.headerStyle === HEADER_TYPE.NONE ? `none` : `flex`};
    justify-content: space-between;
    @media (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    
`
export const StyledHeaderLeft = styled.div`
    display: flex;
    align-items: center;
`
export const StyledHeaderRight = styled.div`
    display: flex;
    align-items: center;
`
export const StyledLogo = styled.div<{ iconURL: string }>`
    width: 28px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    background-image: ${props => `url(${props.iconURL})`};
    margin: 0 14px 0 0;
`
export const StyledHeaderTitle = styled.div<{ headerStyle: HEADER_STYLE }>`
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: ${props => [HEADER_TYPE.DEFAULT, HEADER_TYPE.FUNDS].includes(props.headerStyle) ? '#fff' : props.theme.colors.BLACK[400]};

    @media(max-width: 600px) {
        font-size: 18px;
    }
`


export const IconsContainer = styled.div<{ iconUrl: string }>`
    width: 24px;
    height: 24px;
    background-image: ${props => `url(${props.iconUrl})`};
    cursor: pointer;
`

export const IconsSeparator = styled.div`
    margin-right: 24px;
    
    @media (max-width: 768px) {
        display: none;
    }
`
export const ProfileIconWrapper = styled.div``;