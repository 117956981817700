//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the main component for Party Profile
 */

import { FunctionComponent, useEffect, useState } from "react";
import DATA from 'data/SSI.json';
import SEPA_DATA from 'data/SEPA.json';
import { Accordion } from "components/party/accordion/Accordion";
import { History } from "components/party/History";
import { Identifiers } from "components/party/Identifiers";
import { Hierarchy } from "components/party/Hierarchy";
import { KeyPeople } from "components/party/keyPeople/KeyPeople";
import { Documents } from "components/party/Documents";
import { FieldsGroup } from "./FieldsGroup";
import { PartyDetails } from "types";
import SEPA from "./SEPA";
import SSI from "./ssi/SSI";
import { getSSI } from "services/ssi";
import { getSEPA } from "services/sepa";
import { useParams } from "react-router-dom";
import useOktaAccessToken from "hooks/useOktaHooks";
import { useToast } from "hooks/useToast";
import { StatusProps } from "constants/index";

const GroupedDetails: FunctionComponent<{ data: PartyDetails; partyId: string; light?: boolean }> = ({
  data,
  partyId,
  light
}) => {
  const [ssiData, setSSIData] = useState<typeof DATA | null>(null);
  const [sepaData, setSepaData] = useState<typeof SEPA_DATA | null>(null);

  const { id: pid } = useParams<{ id: string }>();
  const accessToken = useOktaAccessToken();
  const { toast } = useToast();

  useEffect(() => {
    if (accessToken && pid) {
      getSEPA(accessToken, pid).then((data) => {
        setSepaData(data)
      })
        .catch((err: Error) => toast(err.toString(), StatusProps.ERROR))


      getSSI(accessToken, pid).then((data) => {
        setSSIData(data)
      })
        .catch((err: Error) => toast(err.toString(), StatusProps.ERROR))
    }

  }, [accessToken, pid]);


  if (!data) return null;
  return (
    <div>
      {Object.entries(data!)?.map(([key, dataToDisplay]: [string, any], i) => {
        // this part is hardcoded, so has to be changed in the future!
        switch (key) {
          case "Identifiers":
            return (
              <Accordion key={i} title={key} noTopBorder small>
                <Identifiers data={dataToDisplay} accordionName={key} key={i} />
              </Accordion>
            );
          case "History":
            return (
              <Accordion key={i} title={key} small>
                <History data={dataToDisplay} accordionName={key} key={i} />
              </Accordion>
            );
          case "Hierarchy / Group Info":
            return (
              <Accordion key={i} title={key} small>
                <div>
                  <FieldsGroup
                    data={dataToDisplay}
                    accordionName={key}
                    key={i}
                  />
                  <Hierarchy
                    accordionName={key}
                    partyId={partyId}
                    key={i * 50}
                  />
                </div>
              </Accordion>
            );
          case "Key People":
            return (
              <Accordion key={i} title={key} small>
                <KeyPeople data={dataToDisplay} accordionName={key} key={i} />
              </Accordion>
            );
          case "Documents / KYC":
            return (
              <Accordion key={i} title={key} small>
                <Documents accordionName={key} partyId={partyId} key={i} />
              </Accordion>
            );
          case "SEPA":
            return (
              <Accordion key={i} title={key} small>
                <SEPA accordionName={key} key={i} data={sepaData!} />
              </Accordion>
            );
          case "Standing Settlement Instructions (SSI)":
            return (
              <Accordion key={i} title={key} small>
                <SSI accordionName={key} key={i} data={ssiData} />
              </Accordion>
            );
          case "Funds":
            return null;
          case "Contact Information":
            return null;
          default:
            // if it's not a specific case, just use the general layout
            return (
              <Accordion key={i} title={key === 'Mergers & Aquisitions' ? 'Mergers & Acquisitions' : key} small>
                <FieldsGroup
                  data={dataToDisplay}
                  accordionName={key}
                  key={i}
                />
              </Accordion>
            );
        }
      })}
    </div>
  );
};

export default GroupedDetails;
