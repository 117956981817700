import AliceModalTooltip from "components/AliceModalTooltip";
import { dataFilter } from "components/dataFilter/DataFilter";
import { fieldValueFilterFromBVD } from "components/FieldValueFilter";
import { NoDataToDisplay } from "components/noData/ComingSoon";
import { TableContent } from "components/tableContent/TableContent";
import { useState } from "react";
import { FieldName, SingleRow, Span } from "styles/party/FieldsGroup.styled";
import { getTableType } from "utils/party";


// general layout for Accordion's data (not for specific sections)
export function FieldsGroup({
    data,
    accordionName,
  }: {
    data: any;
    accordionName: string;
  }) {
    // dataFilter is a function that chooses if we should display BanksOnly (bo) or NON-BanksOnly (no_bo)
    data = dataFilter({ data });
  
    return (
      <>
        {data.length === 0 ? (
          <NoDataToDisplay />
        ) : (
          data.map(([name, field]: [string, string | any], j: number) =>
            // check if data is an array
            Array.isArray(field) ? (
              // if it's an array, than treat it as a table
              <TableContent
                data={field}
                name={name}
                key={j}
                tableType={getTableType(accordionName)}
              />
            ) : (
              // else, if data is not an array, check if the field has some value ad display field name and value
              field &&
              field.trim() !== "" && (
                <SingleRow key={j}>
                  <FieldName>{name}</FieldName>
                  <AliceModalTooltip selectedField={fieldValueFilterFromBVD(field)} selectedKey={name}><Span>{fieldValueFilterFromBVD(field)}</Span></AliceModalTooltip>
                </SingleRow>
              )
            )
          )
        )}
      </>
    );
  }
  