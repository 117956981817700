import NoDataContent from "components/noData/NoDataContent";
import { FC } from "react";
import { Table, Text, TableCover, TH, TD } from "styles/party/funds/index.styled";
import { TopHoldingType } from "types";

const TopHoldings: FC<{ data: TopHoldingType[]; title?: string }> = ({ data, title }) => {
    return (
        <TableCover>
            <div className="title-cover">

            {title ? <Text light>{title}</Text> : null}
            </div>
            {data.length === 0 ? <NoDataContent />
            :<Table>
                <thead>
                    <tr>
                        <TH position="center">Rank</TH>
                        <TH position="center">Largest Holdings</TH>
                        <TH position="center">%</TH>
                    </tr>
                </thead>
                <tbody>
                    {data.map((datum) => (
                        <tr key={datum?.Rank}>
                            <TD position="center">{datum?.Rank}</TD>
                            <TD>{datum?.['Largest Holding']}</TD>
                            <TD position="right">{datum?.Percentage}</TD>
                        </tr>
                    ))}
                </tbody>
            </Table>}
        </TableCover>
    )
}

export default TopHoldings;