import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const cancelIcon = `${process.env.PUBLIC_URL}/assets/close_gray_no_bg.svg`;
export const alertIcon = `${process.env.PUBLIC_URL}/assets/greentick.svg`;

const ModalHouse = styled.div`
  width: 400px;
  height: 268px;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  background: ${({theme}) => theme.colors.WHITE };

  box-shadow: 0px 20px 24px -4px ${({theme}) => `${theme.colors.SHADOW[100]}8`}, 0px 8px 8px -4px ${({theme}) => `${theme.colors.SHADOW[100]}21`};
  border-radius: 12px;
`;

const GroupAlertIconAndCancelButton = styled.div`
  position: relative;
  display: block;
`;

const CancelButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin: 10px 10px 0 0;
  background-color: ${({theme}) => theme.colors.WHITE };
  background-image: url(${cancelIcon});
  background-repeat: no-repeat;
  background-size: 15px 15px;
  cursor: pointer;
`;

const AlertIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  
  & > span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${({theme}) => theme.colors.SUCCESS[100] };
    border: 8px solid ${({theme}) => theme.colors.SUCCESS[50] };
    border-radius: 28px;
  }
`;

const AlertIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const ModalTopActions = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  margin: 0;
  padding: 0;
`;

const ModalTitle = styled.div`
  color: ${({theme}) => theme.colors.DARK[400]};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-top: 18px;
  margin-bottom: 8px;
  text-align: center;
`;

const ModalBody = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 26px;
  text-align: center;
  color: #475467;
`;

const ModalBottomActions = styled.div`
  display: flex;
  justify-content: center;
`;

const ContinueButton = styled.div`
  background:  ${({theme}) => `${theme.colors.PRIMARY[400]}`};
  border-radius: 8px;
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: ${({theme}) => theme.colors.WHITE };
  display: flex;
  justify-content: center;
  width: 352px;
  height: 44px;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
`;

export const StyledCircularProgress = styled(CircularProgress)`
svg {
  color: ${({theme}) => theme.colors.WHITE};
}
`

export const STYLE = {
    ModalHouse,
    GroupAlertIconAndCancelButton,
    ModalTopActions,
    CancelButton,
    AlertIconContainer,
    AlertIcon,
    ModalTitle,
    ModalBody,
    ModalBottomActions,
    ContinueButton,
    StyledCircularProgress
};
