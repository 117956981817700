//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the Hierarchy section in Party Manager, where we show a table and a graph.
 */

 import { CytoscapeExample } from "./cytoscape/Cytoscape"

 export function Hierarchy({ accordionName, partyId }: { accordionName: string, partyId: string }) {
 
 
     return (<CytoscapeExample idForHierarchy={partyId}/>);
 
 }
 
 