//Copyright Marcello Di Fronzo Gravallese and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

import IframeCard from '../ecp/EcpIframeCard';
import { IframeCardDetails } from "../../types/ecp";

const IframeDivBasic = () => {

    const iframeCardDetails:IframeCardDetails[] = [
        {
            // domain: "60207.p.symphony.com",
            streamId: "WbpHBL0o1GNNVBZIUIMRoH///nnXpQu6dA==",
            chatName: "first"
        },
        {
            // domain: "60207.p.symphony.com",
            streamId: "zhJ1rZJVJVVAcsoWyzwEy3///nnXpgJbdA==",
            chatName: "second"
        }
    ]

    return (
        <>
            <h1>ECP Test Page</h1>
                    {iframeCardDetails.map((item, i) =>
                        <IframeCard key={i}
                            streamId={item.streamId}
                            chatName={item.chatName}
                        />
                    )}
        </>
    )
}

export default IframeDivBasic;
