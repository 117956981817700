import { FunctionComponent, useState } from "react";
import { ModalController } from "../../hooks/useModal";
import ModalComponent from "../modal";
import { STYLE, alertIcon } from "../../styles/SuccessfulUpload.styled";
import { AlertFeedbackType, OcrDocResponseItem } from "types";
import EXAMPLE_DATA from "data/checkmateControllerResponseExample";

const SuccessfulUpload: FunctionComponent<{
    controller: ModalController;
    alertController: ModalController<AlertFeedbackType>;
    confirmedDocuments: OcrDocResponseItem[]
}> = ({ controller, alertController }) => {
    
    const [loading] = useState(false);

    const openAlertModal = () => {
        alertController.open(EXAMPLE_DATA)
        controller.close();
    }

    return (
        <ModalComponent controller={controller}>
            <STYLE.ModalHouse>
                <STYLE.GroupAlertIconAndCancelButton>
                    <STYLE.ModalTopActions>
                        <STYLE.CancelButton onClick={controller.close} />
                    </STYLE.ModalTopActions>
                    <STYLE.AlertIconContainer>
                        <span>
                            <STYLE.AlertIcon src={alertIcon} />
                        </span>
                    </STYLE.AlertIconContainer>
                </STYLE.GroupAlertIconAndCancelButton>
                <STYLE.ModalTitle>{"Uploaded Successfully"}</STYLE.ModalTitle>
                <STYLE.ModalBody>
                    {
                        "These documents have been published. Continue with your due diligence process."
                    }
                </STYLE.ModalBody>
                <STYLE.ModalBottomActions>
                    <STYLE.ContinueButton onClick={openAlertModal}>
                       {loading ? <STYLE.StyledCircularProgress size={20} /> :  'Continue'}
                    </STYLE.ContinueButton>
                </STYLE.ModalBottomActions>
            </STYLE.ModalHouse>
        </ModalComponent>
    );
};

export default SuccessfulUpload;
