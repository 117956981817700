/* Google Cloud buckets names */
export const ASPECT_FILES_PATH = "old";
export const ASPECT_FILES_SOURCE_BUCKET_NAME = "data-repository-clientscope";
export const FINANCIAL_GROUP_CACHE_DESTINATION_BUCKET_NAME = "preview-cache-clientscope";
export const MAIN_CACHE_BUCKET_NAME = "main-cache-clientscope";
export const VALET_PARKING_RULES_BUCKET_NAME = "valet-rules-clientscope";
export const FUNDS_DATA_REPOSITORY_BUCKET_NAME = "transactional-data-repository-clientscope";

/* Google Cloud Workload */
export const WORKLOAD_READ_SERVICE_ACCOUNT = 'workload-read-only@old-not-used-361914.iam.gserviceaccount.com';
export const WORKLOAD_AUDIENCE = '//iam.googleapis.com/projects/829055970416/locations/global/workloadIdentityPools/okta-clientscope-pool/providers/okta-clientscope-provider';

/* Google Cloud file path names */
export const VALET_PARKING_RULES_PATH = 'rules.json'
export const FUNDS_PATH = "funds/v1/funds"
export const SUB_FUNDS_PATH = "funds/v1/sub-funds"
export const FUNDS_DOCUMENTS_PATH = "funds/v1/funds-documents"
