
import styled from 'styled-components';


export const SearchResultsAccordionContainer = styled.div`
margin-top: 3px;
color: ${({theme}) => theme.colors.DARK[400]};
`;

export const SearchResultsAccordionSingleField = styled.div`
margin-bottom: 15px;
font-weight:700;
display: grid;
grid-template-columns: 1fr 1fr;
width:50%;
@media (max-width: 968px) {
    width: 100%;
  }
&:last-of-type {
    margin-bottom: 0;     
}
`;

export const SearchResultsAccordionSingleFieldTitle = styled.div`
color: ${({theme}) => theme.colors.DARK[500]};
font-weight:600;
`;

export const SearchResultsAccordionSingleFieldValue = styled.div`
color: ${({theme}) => theme.colors.DARK[500]};
`;

export const ShowMore = styled.span`
cursor: pointer;
`;

export const SearchResultsAccordionHierarchyInfo = styled.div`
margin-top: 15px;

& div {
    margin-top: 10px;
}
`;

export const NameCover = styled.div`
    display: flex ;
    min-width: 150px ;
    flex-wrap: wrap
`

export const Name = styled.p`
background-color: ${({theme}) => theme.colors.PRIMARY[150]};
color: ${({theme}) => theme.colors.PRIMARY[400]};
border: none;
padding: 5px 15px;
border-radius: 20px ;
margin: 5px 10px 5px 0;
font-weight: 500 ;
`
