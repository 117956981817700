import { AdminMenuItem } from "../types";

const crownIcon = process.env.PUBLIC_URL + "/assets/crown.svg";
const plusIcon = process.env.PUBLIC_URL + "/assets/plus-icon.svg";
const reportIcon = process.env.PUBLIC_URL + "/assets/settings-dido-icon.svg";
const searchIcon = process.env.PUBLIC_URL + "/assets/report.svg";

export const CLIENT_MENU_ITEMS: AdminMenuItem[] = [
  {
    text: "ALICE Requests",
    icon: crownIcon,
    link: "alice-requests",
  },
  {
    text: "T360 Connect",
    icon: plusIcon,
    link: "connect",
  },
  {
    text: "Report",
    icon: searchIcon,
    link: "report",
  },
  {
    text: "Settings",
    icon: reportIcon,
    link: "settings",
  },
];