import styled from 'styled-components';
import { CustomFieldsGroupContainer, FieldValue } from 'styles/SectionSharedItems.styled';

export const ContactInfoContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.WHITE} ;
`;

export const ContactInfoContent = styled.div`
  margin-top: 33px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const ContactInfoCustomFGC = styled(CustomFieldsGroupContainer)`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  flex-basis: 100%;
  flex: 1;
  row-gap: 39px;
  @media (max-width: 1200px) {
    flex-basis: 100%;
  }
  &:first-child {
    @media (max-width: 500px) {
    padding-bottom: 30px;
  }
  }
`;

export const MapBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  flex: 1;
  @media (max-width: 1200px) {
    margin-top: 20px;
    flex-basis: 100%;
  }
`;

export const ContactInfoCustomFV = styled(FieldValue) <{
  capitaliseFirstLetter: number;
}>
`
  flex-basis: auto;
  text-transform: ${(props) =>
    props.capitaliseFirstLetter ? `capitalize` : `none`};
  color: ${({theme}) => theme.colors.GREY[500]};
  font-weight: 500;
  font-size: 16px;
  padding: 5px;
  line-height: 18.75px;
  display: flex;
  align-items: center;
  a {
    color: inherit;

    &:hover {
      color: #800080;
    }
  }
  @media (max-width: 500px) {
    padding-top: 10px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: ${({theme}) => theme.colors.PRIMARY[400]};
`;

export const SingleRow = styled.div`
  display: flex;
  @media (max-width: 500px) {
    display: inline;
  }
`;

export const FieldName = styled.div`
  flex-basis: 50%;
  color: ${({theme}) => theme.colors.PRIMARY[400]};
  font-size: 18px;
  line-height: 21.09px;
  padding-bottom: 10px;
`;

export const FieldGroupsCover = styled.div<{isFull: boolean, column:boolean}>`
  display: ${({isFull, column}) => (isFull || column) ? 'flex' : 'block'};
  flex-direction: ${({column}) => column ? 'column' : 'row'};
  justify-content: space-between ;
  width: ${({isFull}) => isFull ? '100%' : '50%'};
  flex-wrap: wrap ;
  min-height: 555px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`