/**
 * This is the dedicated component that has functions for foltering the value of the fields
 */
import { GoToNewLine, ValueContainer } from "styles/FieldValueFilter.styled";


export function fieldValueFilterFromBVD(fieldToFilter:string):string{

    if (fieldToFilter.indexOf("[source: Bureau van Dijk]") >= 0)
        fieldToFilter = fieldToFilter.substring(0, fieldToFilter.indexOf("[source: Bureau van Dijk]"))

    return fieldToFilter
}

// dedicated function for removing the <br> from text and use it as an actual HTML <br> tag
export function FieldValueFilter({fieldToFilter}:{fieldToFilter:any|string}){

    let filteredField:string = fieldToFilter

    let paragraphs:string[] = []

    while(filteredField.includes("<br>")){
        paragraphs.push(filteredField.substring(0, filteredField.indexOf('<br>')))
        filteredField = filteredField.substring(filteredField.indexOf('<br>') + 4, filteredField.length)
    }

    while(filteredField.includes("<BR>")){
        paragraphs.push(filteredField.substring(0, filteredField.indexOf('<BR>')))
        filteredField = filteredField.substring(filteredField.indexOf('<BR>') + 4, filteredField.length)
    }

    paragraphs.push(filteredField)

    return (
        <ValueContainer>
            {paragraphs.map((paragraph:string, index:number) => (
                index!==0
                    ?   <div key={index}><GoToNewLine>&nbsp;</GoToNewLine>{paragraph}</div>
                    :   <div key={index}>{paragraph}</div>
            ))}
        </ValueContainer>
    )
}