import { GeneralDetails } from "components/funds/GeneralDetails";
import Tabs from "components/funds/Tabs";
import { FUNDS_TAB } from "constants/funds";
import useOktaAccessToken from "hooks/useOktaHooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Banner, Container } from "styles/party/index.styled";
import { getDataAndMetadata } from "utils/getDataAndMetaData";
import { useAppData } from "hooks/useAppData";
import LoadingSpinner from "components/LoadingSpinner";
import { GeneralDetailsType } from "types/funds";
import { useToast } from "hooks/useToast";
import { StatusProps } from "constants/index";
import {
  SettingSection,
  SettingSubSectionA,
  SettingSubSectionB,
  SettingImg,
} from "../../styles/admin/Setting.styled";
import { FundsContainer } from "styles/funds/index.styled";
import {ManagedFunds} from "../../components/party/funds/ManagedFunds";
import {PartyData} from "../../types/party";

const Funds = () => {
  const { id } = useParams<{ id: string }>();
  const accessToken = useOktaAccessToken();
  const [tab, setTab] = useState<FUNDS_TAB>(FUNDS_TAB.PROFILE);
  const [profileDetails, setProfileDetails] = useState<PartyData>();
  const { toast } = useToast();

  const { selectedFunds: generalDetails, setSelectedFunds } = useAppData();

  useEffect(() => {
    if (accessToken && id) {
      getDataAndMetadata(accessToken, id)
        .then((data) => {
          if (data) {
            // get general details from data and set
            setSelectedFunds({
              ...(data?.["General Details"] as GeneralDetailsType),
              id,
            });

            // set the rest of the data as profileDetails
            let details = { ...data };

            delete details?.["General Details"];

            setProfileDetails(details);
          }
        })
        .catch((err: Error) => toast(err.toString(), StatusProps.ERROR));
    }
  }, [accessToken, id]);

  if (!profileDetails) return <LoadingSpinner />;
  return (
    <Container>
      <Banner className="container">
        <Tabs selectedTab={tab} setTab={setTab} />
        <GeneralDetails data={generalDetails as GeneralDetailsType} />
      </Banner>
      <FundsContainer>
        <div className='container'>
          {tab === FUNDS_TAB.PROFILE && (
                <ManagedFunds
                    accordionName="Managed Funds"
                    token={accessToken}
                    partyId={id!}
                    data={profileDetails}
                />
          )}
        </div>
        {tab === FUNDS_TAB.ADMIN && (
          <SettingSection>
            <SettingSubSectionA>
              <h2>Coming soon!!!</h2>
              <span>
                All Good Things <br /> Come to Those who <br />
                Wait...
              </span>
            </SettingSubSectionA>
            <SettingSubSectionB>
              <img
                src={SettingImg}
                width="400"
                className="csImg"
                alt="coming soon"
              />
            </SettingSubSectionB>
          </SettingSection>
        )}
      </FundsContainer>
    </Container>
  );
};

export default Funds;
