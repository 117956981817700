import styled from "styled-components";

export const Container = styled.div`
    position: relative ;
    margin-top: 15px ;
`

export const ToolTip = styled.div<{progress:number}>`
    position: absolute ;
    top:-40px;
    right: ${({progress}) => progress > 12 &&`${100-progress}%`};
    color: ${({ theme }) => theme.colors.DARK[500]};
    font-size: 12px;
    background: ${({ theme }) => theme.colors.WHITE};
    font-weight: 700;
    padding: 8px 12px;
    border: ${({ theme }) => `1px solid ${theme.colors.GRAY[200]}`};
    border-radius: 8px;
`;

export const Progress = styled.div`
  width: 100%;
  transition: height 0.3s;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.GRAY[200]};
  border-radius: 20px ;
  height: 8px;
`;

export const ProgressFilled = styled.div<{color?:string}>`
  background: ${({ theme, color }) => color || theme.colors.PRIMARY[400]};
  height: 100%;
  border-radius: 20px ;
`;