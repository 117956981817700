import styled from "styled-components";

export const Container = styled.div``;

export const Right = styled.div`
  margin-left: 20px;

  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;

export const ImageCover = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Image = styled.image``;

export const Title = styled.div`
  p {
    margin-top: 0;
    color: ${({ theme }) => theme.colors?.PRIMARY[400]};
    font-weight: 600;
    font-size: 18px;
  }
`;

export const ListCover = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const List = styled.div<{active?:boolean}>`
  margin-right: 5px;
  margin-bottom: 5px;
  width: fit-content;
  padding: 6px 12px;
  border-radius: 2px;
  background-color: ${({ theme, active }) => active ? theme.colors?.PRIMARY[400] : theme.colors?.PRIMARY[15]};
  color: ${({ theme, active }) => active ? theme.colors.WHITE : theme.colors?.PRIMARY[400]};
`;
