import styled, { keyframes } from 'styled-components';

 // animation for fade in
 export const fadeIn = keyframes`
      from {
          opacity:0;
      }
      to {
          opacity:1;
      }
  `;
 
 export const ModalOverlay = styled.div`
      color: #4A82A6;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1100;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
      animation: ${fadeIn} 0.5s;
      -moz-animation: ${fadeIn} 0.5s; /* Firefox */
      -webkit-animation: ${fadeIn} 0.5s; /* Safari and Chrome */
      -o-animation: ${fadeIn} 0.5s; /* Opera */
  `;
 
 export const ModalBoxContainer = styled.div`
      display: block;
      position: fixed;
      margin: 50px;
      top: 0;
      left: 0;
      z-index: 1200;
      height: 90%;
      max-height: 1920px;
      width: 90%;
      max-width: 1920px;
      border: 2px solid black;
      border-radius: 0 25px 25px 0;
      background-color: #fff;
      box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.9);
      animation: ${fadeIn} 0.5s;
      -moz-animation: ${fadeIn} 0.5s; /* Firefox */
      -webkit-animation: ${fadeIn} 0.5s; /* Safari and Chrome */
      -o-animation: ${fadeIn} 0.5s; /* Opera */
  `;
 
 export const ModalBoxControls = styled.div`
      position: absolute;
      top: 10px;
      right: 20px;
      z-index: 1200;
  `
 export const ModalBoxCloseIcon = styled.span`
      display: inline-block;
      vertical-align: middle;
      width: 25px;
      height: 25px;
      background-color: ${({theme}) => theme.colors.WHITE};
      border-radius: 18px;
      background-image: url(${process.env.PUBLIC_URL + "/assets/closeAccordionIcon2.svg"});
      background-size: 25px;
      cursor: pointer;
  `
 
 export const ModalBoxContent = styled.div`
      color: #333;
  `;