import styled from "styled-components"

export const settingsCheckBoxOn = process.env.PUBLIC_URL + "/assets/header-modal-settings-checkbox-on.svg"
export const settingsCheckBoxOff = process.env.PUBLIC_URL + "/assets/header-modal-settings-checkbox-off.svg"

export const ProgressCover = styled.div`
    margin: 30px 26px;
`

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Title = styled.div`
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.GRAY[700]};
`

export const ColorOptions = styled.div`
    margin: 10px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

export const Option = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const CheckBox = styled.div<{checked:boolean}>`
  margin-right: 12px;
  width: 28px;
  height: 28px;
  background-size: 28px;
  background-image: ${({ checked }) => `url(${checked ? settingsCheckBoxOn : settingsCheckBoxOff})`};
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`

export const SettingsContainer = styled.div<{ display: string }>`
  flex-direction: column;
  width: 100%;
  display: ${({ display }) => display};
`;