//Copyright Marco Rapaccini, Marcello Di Fronzo Gravallese and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the Search Suggestions.
 */

import { useEffect, useState } from 'react'
import { LUCENE_GCF, CALL_NEO4J_EXTERNALLY } from '../../config/googleCloudFunctionsConfig'
import {
    CallNeo4jExternallyItem,
    SearchSuggestionInput,
    SearchSuggestionResult
} from '../../types'
import { SearchSuggestionItem } from './SearchSuggestionItem'
import { useOktaAuth } from '@okta/okta-react';
import { RelatedEntity } from '../../types'
 import {
    TopSearches,
    Loader,
    LoaderBar,
    Suggestions,
    SuggestionNotFound
} from '../../styles/SearchSuggestion.styled';
import {StatusProps} from "constants/index";
import { useToast } from "hooks/useToast";

const searchSuggestionsLimit: number = 100

export const SearchSuggestions = ({ searchString, isIdentifierSearch, showTopSearches = true, noMargin=false }: { searchString: string, isIdentifierSearch: boolean, showTopSearches?: boolean, noMargin?: boolean }) => {
    const {toast} = useToast();
    const { authState, oktaAuth } = useOktaAuth();

    let [resultsToDisplay, setResultsToDisplay] = useState<SearchSuggestionResult[]>([])

    let [luceneReplyLength, setLuceneReplyLength] = useState<number | null>(null)

    let [searchSuggestionsHasFinished, setSearchSuggestionsHasFinished] = useState<boolean>(false)

    // this is the asynchronous function for retrieving data from Lucene
    const fetchResults = async () => {

        if (authState && authState.isAuthenticated) {

            const accessToken = oktaAuth.getAccessToken()

            if (accessToken) {

                /*
                 * Here we prepare all what we need for the Google Function call
                 */
                const paramsObj = {
                    accessToken: accessToken,
                    stringToMatch: searchString,
                    isIdentifierSearch: isIdentifierSearch.toString()
                }

                const searchParams = new URLSearchParams(paramsObj)


                const googleFunctionApiUrlForLucene = LUCENE_GCF + "?" + searchParams.toString().replace(/%2f/gi, '/');

                // then we do the fetch
                await fetch(googleFunctionApiUrlForLucene)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error("SearchSuggestions - Error when calling Google Function --> Lucene")
                        }
                        return response
                    })
                    .then((response: Response) => response.json())
                    .then(luceneResults => {


                        let guosArray: RelatedEntity[] = []
                        let nonGuosArray: RelatedEntity[] = []
                        let luceneNonNeo4jArray: RelatedEntity[] = []

                        const luceneResultsLength = luceneResults.length
                        setLuceneReplyLength(luceneResultsLength)

                        if (luceneResultsLength > 0) {
                            const entityIdList = luceneResults.map((item: SearchSuggestionInput) => {
                                return item.id
                            })
                            const payloadForGCF = {
                                accessToken: accessToken,
                                entity_id_list: entityIdList,
                                query_type: "search_suggestions_list",
                                limit: "100"
                            }
                            const options = {
                                method: 'POST',
                                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                                body: JSON.stringify(payloadForGCF)
                            }

                            fetch(CALL_NEO4J_EXTERNALLY, options)
                                .then((response: Response) => response.json())
                                .then((response: CallNeo4jExternallyItem[]) => {
                                    response.forEach((item: CallNeo4jExternallyItem, index: number) => {

                                        // the response array is in the same order of the luceneResults array
                                        const relatedEntityItem: RelatedEntity = {
                                            name: luceneResults[index].name || luceneResults[index].internationalName || "",
                                            isGuo: item.status === "guo",
                                            id: luceneResults[index].id,
                                            iso: luceneResults[index].isoCountryCode,
                                            type: luceneResults[index].type,
                                            notInNeo: item.status === "null"
                                        }

                                        // this an alternative to a switch or to an if/else/else-if
                                        const assignToRightArray: { [key: string]: RelatedEntity[] } = {
                                            "guo": guosArray,
                                            "sub": nonGuosArray,
                                            "null": luceneNonNeo4jArray
                                        }
                                        assignToRightArray[item.status].push(relatedEntityItem)
                                    })
                                })
                                .finally(() => setResultsToDisplay(guosArray.concat(nonGuosArray).concat(luceneNonNeo4jArray)))
                                .catch((err: Error) => toast(err.toString(), StatusProps.ERROR))
                        }
                    })
                    .catch((err: Error) => toast(err.toString(), StatusProps.ERROR))
            }
        }
    };

    const resetValues = () => {
        setResultsToDisplay([])
        setLuceneReplyLength(null)
        setSearchSuggestionsHasFinished(false)
    }

    useEffect(() => {
        if (searchString.trim() !== "") {
            resetValues();
            fetchResults();
        }
    }, [searchString]);

    const SearchSuggestionItemsList = (): JSX.Element => {
        // sometimes the results are less than the searchSuggestionsLimit, so we need to pick the smallest one between the 2
        const maxSuggestionIndex = (resultsToDisplay.length < searchSuggestionsLimit ? resultsToDisplay.length : searchSuggestionsLimit) - 1;
        return (
            <>
                {
                    resultsToDisplay.length === luceneReplyLength
                    &&
                    resultsToDisplay.map((item: RelatedEntity, index) => (
                        index < searchSuggestionsLimit &&
                        <SearchSuggestionItem
                            key={index}
                            entityToDisplay={item}
                            setSearchSuggestionsHasFinished={setSearchSuggestionsHasFinished}
                            isLast={index === maxSuggestionIndex}
                            isIdentifierSearch={isIdentifierSearch}
                        />
                    ))
                }
            </>

        )
    }

    return (
        <Suggestions noMargin={noMargin}>
            {showTopSearches && <TopSearches>
                <p>Top Searches</p>
            </TopSearches>}
            {(!searchSuggestionsHasFinished && luceneReplyLength !== 0) &&
                <Loader>
                    <LoaderBar />
                </Loader>
            }
            {luceneReplyLength !== 0
                ? <SearchSuggestionItemsList />
                : <SuggestionNotFound>Nothing found, try a different search</SuggestionNotFound>
            }
        </Suggestions>
    )
}

