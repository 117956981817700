import { Routes, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import Home from "../pages/home/Home";
import Protected from "./Protected";
import { RequiredAuth } from "./SecureRoute";
import LoadingSpinner from '../components/LoadingSpinner';
import { SearchResults } from "../pages/searchResults/SearchResults";
import Search from '../pages/search/Search';
import Alert from "../pages/alert/Alert";
import AlertAirtableTesting from "../pages/alertAirtableTesting/AlertAirtableTesting";
import DiDoDemo from "../pages/didoDemo";
import AdminRoom from "../pages/admin";
import ClientRoom from "../pages/client";
import AliceRequests from '../pages/admin/AliceRequests';
import ClientAliceRequests from '../pages/client/AliceRequests';
import Report from '../pages/client/Report';
import Connect from '../pages/client/Connect';
import EntityLink from '../pages/admin/EntityLink';
import GetSearch from '../pages/admin/Search';
import Dashboard from '../pages/admin/Dashboard';
import Settings from '../pages/admin/Settings';
import Party from 'pages/party';
import { useAppData } from 'hooks/useAppData';
import { ClientAdminTab, InteractiveTab } from 'components/didoDemo/RightContent';
import Ecp from "../pages/ecp/Ecp";
import FundsSearch from 'pages/funds/Search';
import Funds from 'pages/funds';
import { PARTY } from 'constants/index';
import FundHeader from 'components/header/FundHeader';
import { HEADER_TYPE } from 'types';
import Header from "components/header/Header";
import { Main } from "styles/App.styled";
import { FundsSearchResults } from 'pages/funds/FundsSearchResults';


const AppRoutes = () => {

    const { isDidoDemo, isUserPartOfOrginaization, isT360Email } = useAppData();

    return (
        <Main>
            {isUserPartOfOrginaization([PARTY.HUBWISE]) ? <FundHeader headerStyle={HEADER_TYPE.FUNDS} /> :
                <Header headerStyle={HEADER_TYPE.DEFAULT} />}
            <Routes>
                <Route path='login/callback' element={<LoginCallback loadingElement={<LoadingSpinner />} />} />
                <Route path='/' element={<RequiredAuth />}>
                    <Route path='' element={isUserPartOfOrginaization([PARTY.HUBWISE]) ? <Search /> : <Home />} />

                    <Route path='' element={<Protected no_access={isUserPartOfOrginaization([PARTY.HUBWISE])} />}>
                        <Route path='/search' element={<Search />} />
                        <Route path='/search-results/:targetEntityId' element={<SearchResults />} />
                        <Route path='/alert' element={<Alert />} />
                        <Route path='/checkmate-testing' element={<AlertAirtableTesting />} />
                        <Route path='/party/:id' element={<Party />} />
                        <Route path='/ECP-test' element={<Ecp />} />
                    </Route>


                    <Route path='' element={<Protected no_access={!isUserPartOfOrginaization([PARTY.HUBWISE])} />}>
                        <Route path="/funds/search" element={<FundsSearch />} />
                        <Route path='/funds/:id' element={<Funds />} />
                        <Route path="/funds/search-results/:targetEntityId" element={<FundsSearchResults />} />
                    </Route>



                    {/* admin room */}
                    <Route path='/' element={<Protected no_access={!isT360Email} />}>
                        <Route path='admin' element={<AdminRoom />}>
                            <Route path='alice-requests' element={<AliceRequests />} />
                            <Route path='entity-link' element={<EntityLink />} />
                            <Route path='get-search' element={<GetSearch />} />
                            <Route path='dashboard' element={<Dashboard />} />
                            <Route path='settings' element={<Settings />} />
                            <Route path="*" element={null} />
                        </Route>
                    </Route>

                    {/* client room */}
                    <Route path='/' element={<Protected no_access={isT360Email!} />}>
                        <Route path='client' element={<ClientRoom />}>
                            <Route path='alice-requests' element={<ClientAliceRequests />} />
                            <Route path='connect' element={<Connect />} />
                            <Route path='report' element={<Report />} />
                            <Route path='settings' element={<Settings />} />
                            <Route path="*" element={null} />
                        </Route>
                    </Route>

                    {/* didodemo room */}
                    <Route path='/' element={<Protected no_access={!isDidoDemo} />}>
                        <Route path='dido-demo' element={<DiDoDemo />}>
                            <Route path='interactive' element={<InteractiveTab />} />
                            <Route path='client-admin' element={<ClientAdminTab />} />
                            <Route path='settings' element={<Settings />} />
                            <Route path="*" element={null} />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </Main>
    );
};

export default AppRoutes;
