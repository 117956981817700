import AliceModalTooltip from "components/AliceModalTooltip";
import NoDataContent from "components/noData/NoDataContent";
import { FC } from "react";
import {
  IdentifierList,
  CContainer,
  Each,
  EachCover
} from "styles/party/funds/index.styled";

const IdentifierLists: FC<{
  data: { [key: string]: string }[][];
  small?: boolean;
}> = ({ data, small }) => {
  return (
    <CContainer>
      {data && data?.length ? (
        <EachCover>
          {data.map((list,index) => (
            <Each key={index}>
              {list.map((item, i) => (
                <IdentifierList key={i} small={small}>
                  <p>{`${item?.["National ID Label"]}`}</p>
                  <p><AliceModalTooltip selectedField={item?.["National ID Number"]} selectedKey={`${item?.["National ID Label"]}:`}>{item?.["National ID Number"]}</AliceModalTooltip></p>
                </IdentifierList>
              ))}
            </Each>
          ))}
        </EachCover>
      ) : (
        <NoDataContent />
      )}
    </CContainer>
  );
};

export default IdentifierLists;
