import styled from 'styled-components';

export const IdentifiersSearchContainer = styled.div`
display: block;
width: 80%;
`;
export const IdentifiersSearchForm = styled.form<{isData?:boolean}>`
width: 100%;

& fieldset {
    color: #333;
    border: ${({isData}) => isData ? 'none' : '1px solid rgba(204, 204, 204, 0.8)'};
    font-weight: bold;
    text-align: center;
    border-radius: 8px;
    position: relative;
    padding: 10px 10px 20px 10px;
}
`;
export  const IdentifierSearchInput = styled.div<{isData?: boolean}>`
float: left;
width: 100%;

& input {
    width: calc(100% - 60px);
    height: 40px;
    color: #333;
    background: ${({isData}) => isData ? 'transparent' : '#F2F2F2'};
    border-radius: 8px;
    border: 0;
    padding: 0 40px 0 20px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    font-size: 16px;

    &:focus {
        outline: none;
    }

    &::-webkit-input-placeholder { /* WebKit browsers */
        font-weight: normal;
        font-style: italic;
        color: #333;
    }
    &:input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        font-weight: normal;
        font-style: italic;
        color: #333;
    }
    &:input::-moz-placeholder { /* Mozilla Firefox 19+ */
        font-weight: normal;
        font-style: italic;
        color: #333;
    }
    &:input:-ms-input-placeholder { /* Internet Explorer 10+ */
        font-weight: normal;
        font-style: italic;
        color: #333;
    }
}
`;
export const SearchIconContainer = styled.div`
position: absolute;
right: 10px;
top: 20px;
padding-right: 8px;
`;

export const SearchIconButton = styled.button`
padding: 0;
cursor: pointer;
background: transparent;
border: 0;

&:hover{
    opacity: 0.8;
}

& span {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(${process.env.PUBLIC_URL + "/assets/searchIcon.svg"});
    background-repeat: no-repeat;
}
`;