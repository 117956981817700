import { useAppData } from "hooks/useAppData";
import { useLocation } from "react-router-dom";
import {useEffect, useState} from "react";
import FirstStep from "./steps/FirstStep";
import SecondStep from "./steps/SecondStep";
import ThirdStep from "./steps/ThirdStep";
import {
    MainContainer,
    DarkLayer,
    ContentContainer,
    Title,
    PagePath,
    CloseIcon,
    closeIcon
} from 'styles/crownAlert/CrownAlert.styled'
import { clipText, toggleScroll } from "utils";
import { CrownAlertSteps, PATH } from "constants/index";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import Tooltip from "components/Tooltip";

const CrownAlert = ({alertIsActive, menuToggle, selectedField, selectedKey, header}:{alertIsActive: boolean, menuToggle: (a?:string, b?:string) => void, selectedField?: string, selectedKey?: string, header?: boolean}) => {
    const [step, setStep] = useState('');
    const {pathname} = useLocation();
    const {selectedParty} = useAppData();
    const {track} = useTrackActivity();

    useEffect(() => {
        setStep('')
        toggleScroll(alertIsActive)
        if (alertIsActive === true) {
            track(
                ActivityType.BUTTON_CLICK,
                'crown alert modal',
                {
                    button_clicked: 'Crown Alert Button',
                    clicked_from: 'Header',
                    activity_info: 'Crown Alert Modal Opened'
                })
        }
    }, [alertIsActive]);
        

    let component;
    switch (step) {
        case '':
            component = <FirstStep setStep={setStep} />
            break;
        case CrownAlertSteps.DATAENQUIRY:
            component = <SecondStep setStep={setStep} value={'DataEnquiry'}/>
            break;
        case CrownAlertSteps.FEEDBACK:
            component = <SecondStep setStep={setStep} value={'Feedback'}/>
            break;
        case CrownAlertSteps.CLOSE:
            component = <ThirdStep menuToggle={menuToggle} />
            break;
        default:
            component = <FirstStep setStep={setStep}  />
    }

    function handleCloseModal() {
        menuToggle();
        track(ActivityType.BUTTON_CLICK, 'crown alert modal', {activity_info: 'Crown Alert Modal Closed'})
      }    

    useEffect(() => {
       step === CrownAlertSteps.FEEDBACK && track(ActivityType.BUTTON_CLICK, 'Alert Modal', {button_clicked: 'Feedback button clicked'})
       step === CrownAlertSteps.DATAENQUIRY && track(ActivityType.BUTTON_CLICK, 'Alert Modal', {button_clicked: 'Data Enquiry button clicked'})
    }, [step])


    return(
        <div style={{display: alertIsActive ? 'flex' : 'none'}}>
            <DarkLayer />
            <MainContainer header={header}>
                <CloseIcon iconURL={closeIcon} onClick={handleCloseModal}/>
                <ContentContainer>
                    <Title style={{display: step==='Close'?'none':'inherit'}}><b>ASK ALICE</b></Title> {/*This title mustn't be visible in the last step*/}
                    {pathname.includes(PATH.SEARCH_RESULT) && step!=='Close' ?
                        <PagePath><b>{pathname.substring(16)}</b></PagePath> //If we are on the search results page we take the PID of the GUO searched from the url
                    : <div/>}
                    {window.location.pathname.includes(PATH.PARTY) && step !== 'Close' ?
                        <PagePath><b>{selectedParty?.id} &gt; {selectedParty?.['Legal Name']}
                            {/* check if length of selected field is more than 27 char and put a tooltip around it if true */}
                                {
                                    selectedField && selectedField.toString().length > 27 ? (
                                        <Tooltip propInModal content={selectedField!}>
                                            {` > ${clipText(selectedField, 27)}`}
                                        </Tooltip>
                                    ) : ` > ${selectedKey}: ${selectedField}`
                                }                                
                        </b> </PagePath> //If we are on a Party Profile page we take the PID of the entity from the url
                        : <div />}
                    {component}
                </ContentContainer>
            </MainContainer>
        </div>
    )
}
export default CrownAlert;