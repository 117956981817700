import React, { useContext, useEffect} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import LoadingSpinner from "../components/LoadingSpinner";
import { AppContext } from '../contexts/App';

/**
 * Ensures that the authentication has successfully ended before returning the `<Protected />` component.
 * @return Returns [1] the `<Outlet />` component that represent the RequiredAuth child route `<Protected />` component, defined in `AppRoutes.tsx`
 * or [2] a Loading component or [3] null if there's no authState.
 * This component is inspired by [this Okta example](https://github.com/okta/okta-react/blob/master/samples/routing/react-router-dom-v6/src/components/SecureRoute.tsx).
 */
export const RequiredAuth: React.FC = () => {
    const { oktaAuth, authState } = useOktaAuth();

    const {user} = useContext(AppContext); 

    useEffect(() => {
        if (!authState) {
            return;
        }
        
        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect();
        }
        

    }, [oktaAuth, !!authState, authState?.isAuthenticated]);

    if (!authState || !authState?.isAuthenticated || !user?.email) {
        return (<LoadingSpinner/>);
    }

    return (<Outlet />);
}