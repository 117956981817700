import styled from 'styled-components';

export const Container = styled.div`
    width: calc(100%/3 - 22px);
    min-width: 180px;
    height: 90px;
    background: rgba(46, 26, 97, 0.05);
    border: 1px solid #2E1A61;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const Title = styled.div`
    width: 100px;
`;
export const Info = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const Icon = styled.img``;
export const Num = styled.div``;