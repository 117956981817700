import { FunctionComponent } from 'react';
import {
  DropDownContainer,
  DropDownCover,
  DropDownItem
 } from 'styles/admin/DropdownContent.styled';
type Props = {
  items: string[];
  onSelect: React.Dispatch<React.SetStateAction<string>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DropDownContent:FunctionComponent<Props> = ({items,onSelect, setOpen}) => {

  const handleCLick = (item: string) => {
    onSelect(item);
    setOpen(false);
  };

  return (
    <DropDownContainer data-testid="dropdown">
      {items.map((item, index) => (
        <DropDownCover onClick={() => handleCLick(item)} key={index} data-testid="list">
          <DropDownItem>
            {item}
            </DropDownItem>
        </DropDownCover>
      ))}
    </DropDownContainer>
  )
}

export default DropDownContent;
