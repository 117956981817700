import {OktaConfig, UserDetailsEmail, UserDetailsOkta} from "../../types";
import {OKTA_AUTH_SERVER_ISSUER_ID, OKTA_CLIENT_ID} from "../../config/oktaConfig";
import {AuthState, OktaAuth, UserClaims} from "@okta/okta-auth-js";
import { removeItem } from "../../utils/localStorage";
import { KEY } from "../../constants/key";

/**
 * Redirects the user to the Okta Authentication page. After authentication, through the callback route, the user will be redirected to the protected route that we defined in `AppRoutes.tsx` (or, by default, to `/`).
 * @param {OktaAuth} oktaAuth the current OKTA's API JS wrapper
 */
export const login = async (oktaAuth:OktaAuth) => {
    await oktaAuth.signInWithRedirect({ originalUri: '/' })
}

/**
 * Sign user out of OKTA and then redirect back to the configured `postLogoutRedirectUri` (that you can find in online OKTA app config).
 * @param {OktaAuth} oktaAuth the current OKTA's API JS wrapper
 */
export const logout = async (oktaAuth:OktaAuth) => {
    await oktaAuth.signOut()
    removeItem(KEY.USER)
}

/**
 * Returns `OktaConfig` types properties to configure a new OktaAuth object in App.tsx.
 */
export const getOktaConfig = ():OktaConfig => {
    const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
    const REDIRECT_URI = `${window.location.origin}/login/callback`
    return {
        clientId: OKTA_CLIENT_ID,
        issuer: OKTA_AUTH_SERVER_ISSUER_ID,
        redirectUri: REDIRECT_URI,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK
    }
}

/**
 * Retrieving the name, given name, family name and initials info regarding a user from okta. 
 * @param oktaAuth the current OKTA's API JS wrapper
 */
export const getUserNameOkta = async (oktaAuth:OktaAuth):Promise<UserDetailsOkta> => {
    const userClaims:UserClaims = await oktaAuth.getUser()
    return {
        name: userClaims.name ? userClaims.name : 'Organisation User',
        givenName: userClaims.given_name,
        familyName: userClaims.family_name,
        initials:  userClaims.given_name && userClaims.family_name ? userClaims.given_name.charAt(0) + userClaims.family_name.charAt(0) : "OU",
        email: userClaims.email
    }
}

export const getUserEmailOkta = async (oktaAuth:OktaAuth):Promise<UserDetailsEmail> => {
    const userClaims:any = await oktaAuth.getUser()
    return {
        email: userClaims.email
    }
}

/**
 * Checking if the user is authenticated.
 * @param {AuthState|null} authState state of authentication handled by OKTA.
 */
export const isUserAuthenticated = (authState:AuthState|null):boolean => {
    return !!(authState && authState.isAuthenticated)
}

/**
 * Using the OKTA custom user claims, check if the authenticated user is part of an OKTA group
 * @param {AuthState|null} authState state of authentication handled by OKTA.
 * @param {string} userClaimName property key of the custom user claim in the OKTA authState.accessToken.claims object
 * @param {string} groupName name of the OKTA users group
 */
export const isUserPartOfGroup = (authState:AuthState|null, userClaimName: string, groupName:string):boolean => {
    const claims = authState && isUserAuthenticated(authState) && authState.accessToken && authState.accessToken.claims;
    const userClaimNameObject = claims && claims[userClaimName] ? Object.entries(claims[userClaimName]) : [];
    return (userClaimNameObject.some(([, value]: [string, unknown]) => value === groupName))
}

export const displayClaims = (authState: AuthState | null, userClaimName: string): Object => {
    const accessClaims = authState && isUserAuthenticated(authState) && authState.accessToken && authState.accessToken.claims;
    const accessClaimNameObject = accessClaims && accessClaims[userClaimName] ? Object.entries(accessClaims[userClaimName]) : [];
    return {
        userClaimName,
        accessClaimNameObject
    }
}

export const displayParsedTokens = (authState: AuthState | null): Object => {
    const accessTokenClaims = authState?.accessToken?.claims;
    const idTokenClaims = authState?.idToken?.claims;
    return {
        accessTokenClaims,
        idTokenClaims
    }
}

export const displayTokens = (authState: AuthState | null): Object => {
    const accessToken = authState?.accessToken;
    const idToken = authState?.idToken;
    return {
        accessToken,
        idToken
    }
}