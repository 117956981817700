import {useContext, useRef, useEffect} from "react";
import {useDetectOutsideClick} from "../../hooks/useDetectOutsideClick";
import {UserProfileProps} from "../../types";
import {
    StyledContainer,
    UserProfileInfo,
    UserProfileInitials,
    MenuContainer
} from 'styles/header/UserProfileHeader.styled'
import HeaderMenu from "./HeaderMenu";
import { AppContext } from "../../contexts/App";
import { useLocation } from "react-router-dom";
import { toggleScroll } from "utils";
import {useAppData} from "../../hooks/useAppData";
import { PATH } from "constants/index";
import { ActivityType, useTrackActivity } from "hooks/useTracker";


const UserProfileHeader = ({headerStyle, tabIsActive, alertIsActive}:UserProfileProps) => {
    const {pathname} = useLocation();
    const IS_ADMIN = pathname.includes(PATH.ADMIN) || pathname.includes(PATH.CLIENT);

    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false)

    const {status} = useAppData()
    const {track} = useTrackActivity();

    const {user} = useContext(AppContext);
    //toggle the menu to open and close
    const menuToggle = () => {
        // toggle only when tab and alert modal are not opened
        if (!tabIsActive && !alertIsActive) {
            setIsActive(!isActive)
        }
        if(!isActive) track(ActivityType.BUTTON_CLICK, 'Header', {button_clicked: 'user profile header opened'})
        if(isActive) track(ActivityType.BUTTON_CLICK, 'Header', {button_clicked: 'user profile header closed'})
    }

    

    useEffect(() => {
      toggleScroll(isActive)
    }, [isActive])
    
    return(
        <StyledContainer ref={dropdownRef}>
            {/*user clickable profile to be toggled */}
            <UserProfileInfo onClick={menuToggle}>
                {/*<UserProfilePic profilePicURL={profilePic}/>*/}
                <UserProfileInitials headerStyle={headerStyle} status={status}>{user?.initials}</UserProfileInitials>
                {/*<UserProfileMenuIcon iconURL={isActive ? collapseMenuIcon : expandMenuIcon}/>*/}
            </UserProfileInfo>
            { /* The following <div> has the React reference that we use in combination with useDetectOutsideClick */ }
            <MenuContainer visibility={isActive ? "visible" : "hidden"} isAdmin={IS_ADMIN}>
                <HeaderMenu menuToggle={menuToggle}/>
            </MenuContainer>
        </StyledContainer>
    )
}
export default UserProfileHeader;