import styled from "styled-components"

export const permissionIcon = `${process.env.PUBLIC_URL}/assets/header-modal-permissions-plus-icon.svg`

export const Tag = styled.div<{color: string|undefined, backgroundColor: string|undefined, iconURL?:string, padding?:string}>`
    padding: ${props => `${props.padding}`};
    color: ${props => `${props.color}`};
    background-color: ${props => `${props.backgroundColor}`};
    background-image: ${props => `url(${props.iconURL})`};
    background-size: 12px;
    background-position: center;
    background-repeat: no-repeat;
    min-width: 20px;
    border-radius: 16px;
    font-weight: 600;
    font-size: 12px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`