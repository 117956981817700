import {ChangeEvent, Dispatch, SetStateAction} from "react";
import {OcrDocResponseItem} from "../../../types";
import {
    CustomForm,
    FormGroup,
    Title,
    Container,
    ConfirmButton,
    ButtonRow
} from "styles/alert/docsUploadAndSavings/DocumentFromOCR.styled";

interface DocumentFromOCRProps {
    ocrDocResponseItem: OcrDocResponseItem,
    setConfirmedDocuments: Dispatch<SetStateAction<OcrDocResponseItem[]>>,
    index: number,
    setDocSavedChecks: Dispatch<SetStateAction<boolean[]>>,
}

const DocumentFromOCR = ({ ocrDocResponseItem, setConfirmedDocuments, index, setDocSavedChecks }: DocumentFromOCRProps) => {

    const docProperty = ocrDocResponseItem.doc

    const updateDocSavedChecks = (value:boolean) => {
        setDocSavedChecks(prevState => {
            let newState = [...prevState]
            newState[index] = value
            return newState
        })
    }

    const handleChange = ( event:ChangeEvent<HTMLInputElement>) =>{
        docProperty[event.target.name] = event.target.value;
    }

    const formatLabel = (text: String) => text.replace(/_/g, ' ');

    const saveDocResponseItem = ()=>{

        const newDocResponseItem:OcrDocResponseItem = {
            doc: docProperty,
            name_saved_sha: ocrDocResponseItem.name_saved_sha,
            type: ocrDocResponseItem.type
        }

        setConfirmedDocuments(prevState => {
            let newState = [...prevState]
            newState[index] = newDocResponseItem
            return newState
        } )

        updateDocSavedChecks(true)
    }

    return (
        <Container>
            <Title>{formatLabel(ocrDocResponseItem.type)}</Title>
            <CustomForm>
                {Object.entries(docProperty).map(([key, value]: [string, string]) => {
                    return (
                        <FormGroup key={key}>
                            <label htmlFor={key}>{formatLabel(key)}</label>
                            <input name={key} id={key} defaultValue={value} onChange={(event) => handleChange(event)} />
                        </FormGroup>
                    )
                })}
                <ButtonRow>
                    <ConfirmButton onClick={saveDocResponseItem} >{"Save & Confirm"}</ConfirmButton>
                </ButtonRow>
            </CustomForm>
        </Container>
    )
}
export default DocumentFromOCR;