import PartyProfile from "components/party/profile";
import PartyAfme from "components/party/afme";
import PartyCash from "components/party/cash";
import PartyPayments from "components/party/t360Payments";
import { GeneralDetails } from "components/party/GeneralDetails";
import Tabs from "components/party/Tabs";
import { PARTY_TAB } from "constants/party";
import useOktaAccessToken from "hooks/useOktaHooks";
import { ContactInfo } from "components/party/ContactInfo";
import {
  ContactCover
} from "styles/party/profile/index.styled";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Banner, Container } from "styles/party/index.styled";
import { getDataAndMetadata } from "utils/getDataAndMetaData";
import { useAppData } from "hooks/useAppData";

import { Accordion } from "components/party/accordion/Accordion";
import { ManagedFunds } from "components/party/funds/ManagedFunds";
import LoadingSpinner from "components/LoadingSpinner";
import { GeneralDetailsType, PartyData } from "types/party";
import { useToast } from "hooks/useToast";
import {StatusProps} from "constants/index";

const Party = () => {
  const { id } = useParams<{ id: string }>();
  const accessToken = useOktaAccessToken();
  const [tab, setTab] = useState<PARTY_TAB>(PARTY_TAB.DEFAULT);
  const [profileDetails, setProfileDetails] = useState<PartyData>();
  const [open, setOpen] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>('');
  const {toast} = useToast();


  const { user, selectedParty: generalDetails, setSelectedParty } = useAppData()
  
  useEffect(() => {
    if (accessToken && id) {
      getDataAndMetadata(accessToken, id).then((data) => {
        if (data) {

          // get general details from data and set
          setSelectedParty({ ...(data?.["General Details"] as GeneralDetailsType), id })

          // set the rest of the data as profileDetails
          let details = { ...data };

          delete details?.["General Details"];

          setProfileDetails(details)
          setUserName(user!.name)
        };
      })
      .catch((err: Error) => toast(err.toString(), StatusProps.ERROR))
    }
    
  }, [accessToken, id]);

  useEffect(() => {
    if (tab === PARTY_TAB.DEFAULT) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [tab])

  if(!profileDetails) return <LoadingSpinner />;
  return (
    <Container>
      <Banner className="container">
        <Tabs selectedTab={tab} setTab={setTab} />
        <GeneralDetails data={generalDetails as GeneralDetailsType} />
      </Banner>

      {/* content */}
      <div className="container">
        <Accordion title="Contact Information" noTopBorder opened={open} dep={tab}>
          <ContactCover>
            <ContactInfo
              data={profileDetails?.['Contact Information']}
              accordionName='Contact Information'
              showMap={true}
              column
              hideTitle
            />
          </ContactCover>
        </Accordion>

        {/* all party profile tabs */}
        <Accordion title="Profile" opened={open} dep={tab}>
          <PartyProfile partyId={id!} data={profileDetails} />
        </Accordion>

        {tab === PARTY_TAB.FUNDS && <Accordion noPaddingTop title="Funds" opened={tab === PARTY_TAB.FUNDS}>
          <ManagedFunds
            accordionName="Managed Funds"
            token={accessToken}
            partyId={id!} 
            data={profileDetails}
          />
        </Accordion>}
        {tab === PARTY_TAB.CASH && <Accordion title="CASH" opened={tab === PARTY_TAB.CASH}><PartyCash partyId={id!} partyData={profileDetails} /></Accordion>}
        {tab === PARTY_TAB.AFME && <Accordion title="AFME" opened={tab === PARTY_TAB.AFME}><PartyAfme partyId={id!} partyData={profileDetails} /></Accordion>}
        {tab === PARTY_TAB.T360_PAYMENTS && <Accordion title="Payments" opened={tab === PARTY_TAB.T360_PAYMENTS}><PartyPayments partyId={id!} partyData={profileDetails} /></Accordion>}
      </div>
    </Container>
  );
};

export default Party;
