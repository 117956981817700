import { dataFilter } from "components/dataFilter/DataFilter";
import { fieldValueFilterFromBVD } from "components/FieldValueFilter";
import { NoDataToDisplay } from "components/noData/ComingSoon";
import { TableContent } from "components/tableContent/TableContent";
import { FieldName, SingleRow } from "styles/party/Identifiers.styled";
import { getTableType } from "utils/party";
import { IdentifiersData, IdentifiersFilteredData, IdentifiersArrays } from "../../types";
import IdentifierLists from "./IdentifierLists";



type Obj = { [key: string]: any };

function present(arr: Obj[], c: string) {
  let check = false;

  arr.forEach((item) => {
    if (item?.['National ID Label'] === c) {
      check = true
    }
  })

  return check;
}

function format(obj: Obj): Obj[] {
  if (!obj) return [];

  let arr: Obj[] = [];

  Object.entries((obj)).forEach(([key, value]) => {

    if (Array.isArray(value)) {

      value.forEach((v) => {

        if (!present(arr, v?.['National ID Label'])) {
          arr.push({ 'National ID Label': v?.['National ID Label'], 'National ID Number': v?.['National ID Number'] })
        }
      })
    } else {
      arr.push({ 'National ID Label': key, 'National ID Number': value })
    }

  })

  return arr;
}

function convertToList(arr: Obj[]): Obj[][] {
  if (!arr || !arr.length) return [];
  let mid = Math.ceil((arr.length) / 2);

  const arrs = [arr.slice(0, mid > 3 ? mid : 3), arr.slice(mid > 3 ? mid : 3)]

  return arrs as Obj[][]

}

// general layout for Accordion's data (not for specific sections)
export function Identifiers({
  data,
  accordionName,
}: {
  data: IdentifiersData;
  accordionName: string;
}) {
  // dataFilter is a function that chooses if we should display BanksOnly (bo) or NON-BanksOnly (no_bo)
  // data = dataFilter({ data });

  return (
    <>
      {!data ? (
        <NoDataToDisplay />
      ) : (
        <IdentifierLists data={convertToList(format(data))} />
      )}
    </>
  );
}
