import styled from "styled-components";

export const Table = styled.table<{ left?: boolean }>`
  width: 100%;

  thead {
    background-color: rgba(54, 64, 115, 0.1);
    color: ${({theme}) => theme.colors.PRIMARY[400]};
    th {
      padding: 10px 20px;
      width: 33%;
    }
  }
`;

export const TD = styled.td<{ left?: boolean }>`
    padding: 10px 20px;
    width: 33%;
    text-align: ${({ left }) => left ? "left" : "center"};
  color: ${({theme}) => theme.colors.GREY[500]};
    font-weight: 500;
    font-size: 16px;
    line-height: 18.75px;
`;

export const DownloadIcon = styled.img`
  filter: ${({theme}) => theme.colors.WHITE!=='#FFFFFF'?'saturate(0.8) brightness(0.86) hue-rotate(180deg) invert(1)':'none'};
`