import { FC } from "react";
import { Table, TD, DownloadIcon } from "styles/party/funds/document.styled";
import { FundDocumentsType } from "types/funds";
import { format } from 'date-fns'
import NoData from "components/noData/NoData";

const downloadIcon = process.env.PUBLIC_URL+'/assets/downloadIcon.png';

const getName = (name:string) => {
    if(!name) return '';
    return name.split('/').pop()
}

const FundDocuments: FC<{ data: FundDocumentsType }> = ({ data }) => {


    const onButtonClick = (url:string, name:string) => {
        // using Java Script method to get PDF file
        fetch(url).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = name;
                alink.click();
            })
        })
    }

    if(!data?.items) return <NoData />;
    return (
        <Table>
            <thead>
                <tr>
                    <th>2023</th>
                    <th>DATE</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data.items.map((datum) => (
                    <tr key={datum.id}>
                        <TD left>{getName(datum.name)}</TD>
                        <TD>{datum.updated ? format(new Date(datum.updated), 'dd MMM yyy') : ''}</TD>
                        <TD style={{cursor:"pointer"}} onClick={() => onButtonClick(datum.mediaLink, getName(datum.name)!)}><DownloadIcon src={downloadIcon} alt="download" /></TD>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default FundDocuments;