export  const OPTIONS = [ "Full Search", "Identifier Search", "Fund Only", "Corporate Only", "Banks Only" ];
export const FUND_OPTIONS = ["Full Search", "ISIN", "SEDOL", "Fund Name"]
export const DISABLED_OPTIONS = ["Fund Only", "Corporate Only", "Banks Only", "ISIN", "SEDOL", "Fund Name"]
export const FUNDS_SEARCH_OPTIONS = ["Full Search", "ISIN", "SEDOL", "Fund Name"]
export const DISABLED_FUNDS_SEARCH_OPTIONS = ["ISIN", "SEDOL", "Fund Name"]

export enum SEARCH {
    FULL_SEARCH = 'Full Search',
    FUNDS_SEARCH = "Funds Search",
    ISIN = 'Isin',
    SEDOL = 'Sedol',
    FUND_NAME = 'Fund Name',
    IDENTIFIER_SEARCH = 'Identifier Search',
    BASIC_SEARCH = 'Basic Search'
}