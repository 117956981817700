import { useNavigate } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { getOktaConfig } from "services/okta/okta";
import AppRoutes from "routes/AppRoutes";
import { AppProvider } from "contexts/App";
import ThemeProvider from "theme";
import DynamicFavIcon from "components/DynamicFavIcon";
import { ModalProvider } from "contexts/AliceModalContext";

const oktaAuth = new OktaAuth(getOktaConfig());

function App() {
  const navigate = useNavigate();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };


  return (
    <DynamicFavIcon>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <AppProvider>
          <ThemeProvider>
            <ModalProvider>
              <div className="App">
                  <AppRoutes />
              </div>
            </ModalProvider>
          </ThemeProvider>
        </AppProvider>
      </Security>
    </DynamicFavIcon>
  );
}

export default App;
