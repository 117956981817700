import {FormEvent, useState} from "react";
import Subtitle from "../../alertAirtableTesting/Subtitle";
import {Metadata, MetadataProps, SelectBoxDetails, SelectBoxOption, SelectedDocumentsProps} from "../../../types";
import SubmitButton from "../../alertAirtableTesting/SubmitButton";
import {SelectBox} from "./SelectBox";
import SelectedDocumentsList from "./SelectedDocumentsList";
import {AirTableSelection} from "./AirTableSelection";
import { META_DATA } from "constants/index";
import {
    MetadataContainer,
    EmptyAlert,
    MetadataInput,
    MetadataInputField
 } from 'styles/alert/airTable/DummyDataSelector.styled';

const selectDocumentOptions:SelectBoxOption[] = [
    {optionValue: "passport", optionContent: "passport"},
    {optionValue: "driving_licence", optionContent: "driving_licence"},
    {optionValue: "utility_bill", optionContent: "utility_bill"},
]

const selectDocument:SelectBoxDetails = {
    selectBoxName: "documentType",
    selectListOfOptions: selectDocumentOptions
}

const initialTypeOfDocument = selectDocumentOptions[0].optionValue

const autogeneratedDataEditable = "Automatically generated, but can be edited by user"
const autogeneratedDataNotEditable = "Automatically generated, and CANNOT be edited by user"

const alertString:{[key:string]:string} = {
    'date_time' : autogeneratedDataEditable,
    'transaction_id' : autogeneratedDataEditable,
    'event_id' : autogeneratedDataNotEditable,
    'seconds' : autogeneratedDataEditable,
    'milliseconds' : autogeneratedDataEditable,
    'default' : 'Empty!'
}

/**
 * Main component for all the dummy data selection process.
 * @param metadataProps state and setter for the metadata
 * @param selectedDocumentProps state and setter for selectedDocuments
 */
const DummyDataSelector = ({metadataProps, selectedDocumentProps}:{metadataProps:MetadataProps, selectedDocumentProps:SelectedDocumentsProps})=> {

    let metadata = metadataProps.metadata
    let setMetadata = metadataProps.setMetadata
    let selectedDocuments = selectedDocumentProps.selectedDocuments
    let setSelectedDocuments = selectedDocumentProps.setSelectedDocuments

    const [typeOfDocument, setTypeOfDocument] = useState<string>(initialTypeOfDocument)
    const [showAirTableData, setShowAirTableData] = useState<boolean>(false)

    const onChange = (e: FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        const key = e.currentTarget.name
        const newMetaData:Metadata = {
            pid: key === META_DATA.P_ID ? newValue : metadata.pid,
            user_id: key === META_DATA.USER_ID ? newValue : metadata.user_id,
            event_id: metadata.event_id,
            transaction_id: key === META_DATA.TRANSACTION_ID ? newValue : metadata.transaction_id,
            date_time: key === META_DATA.DATE_TIME ? newValue : metadata.date_time
        }
        setMetadata(newMetaData)
    }

    return(
        <>
            <Subtitle text={"Please enter the metadata necessary for the Google Cloud Function controller call"}/>
            <h3>This is the current metadata</h3>
            <MetadataContainer>
                {Object.entries(metadata).map(([key, value]:[string, string])=> {
                    return <p key={key}>{key}: {
                        value === ""
                            ? <EmptyAlert>{alertString[key] || alertString['default']}</EmptyAlert>
                            : value
                    }</p>
                })}
            </MetadataContainer>
            <MetadataInput>
                <h3>You can edit the metadata using the following fields: </h3>
                <MetadataInputField>
                    <span>pid *: </span>
                    <input type="text"
                           name={META_DATA.P_ID} 
                           value={metadata.pid || ""}
                           onChange={(event) => onChange(event)}
                    />
                </MetadataInputField>
                <MetadataInputField>
                    <span>user_id *:</span>
                    <input
                        type="text"
                        name={META_DATA.USER_ID}
                        value={metadata.user_id || ""}
                        onChange={(event) => onChange(event)}
                        maxLength={3}
                    />
                    <span>max-length: 3 chars</span>
                </MetadataInputField>
                <MetadataInputField>
                    <span>transaction_id: </span>
                    <input
                        type="text"
                        name={META_DATA.TRANSACTION_ID}
                        value={metadata.transaction_id || ""}
                        onChange={(event) => onChange(event)}
                        maxLength={6}
                    />
                    <span>max-length: 6 chars</span>
                </MetadataInputField>
                <br/>
                <MetadataInputField>
                    <span>date_time: </span>
                    <input
                        type="datetime-local"
                        name={META_DATA.DATE_TIME}
                        value={metadata.date_time || ""}
                        onChange={(event) => onChange(event)}
                    />
                </MetadataInputField>
            </MetadataInput>
            <h3>Please choose 1 or more documents to check with the Google Cloud Function controller</h3>
            <div>Documents to Check</div>
            <SelectBox selectBoxDetails={selectDocument} stateSetter={setTypeOfDocument} />
            <SubmitButton
                onClick={() => setShowAirTableData(!showAirTableData)}
                text={`${showAirTableData ? "Hide" : "Show"} AirTable Data` }
                type={showAirTableData ? "reset" : "submit"}
                disabled={false}
            />
            {selectedDocuments.length === 0
                ? <div>No dummy data document has been selected</div>
                : <SelectedDocumentsList selectedDocuments={selectedDocuments} />}
            <SubmitButton
                onClick={() => setSelectedDocuments([])}
                text={"Clear Selection"}
                type={"reset"}
                disabled={selectedDocuments.length === 0}
            />
            {showAirTableData && <AirTableSelection typeOfDocument={typeOfDocument} selectedDocumentsProps={selectedDocumentProps}/>}
        </>
    )
}
export default DummyDataSelector