//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the Party Profile section called Key People
 */
 import { NoDataToDisplay } from 'components/noData/ComingSoon'
 import { dataFilter } from 'components/dataFilter/DataFilter'
 import { FieldValueFilter } from 'components/FieldValueFilter'
 import { Tiles } from './Tiles'
 import {
     CustomFieldsGroupContainer,
     SingleRowContainer,
     SingleRowInternalContainer,
     SingleRow,
     SingleRowTwoColumns,
     FieldName,
     FieldValue
 } from 'styles/SectionSharedItems.styled'
import {IndividualPersonData, KeyPeopleData} from "../../../types";
 
 function FieldsGroup({ data }: { data: [string, IndividualPersonData[]][] }) {
     return (
         <CustomFieldsGroupContainer>
             {data.length === 0
                 ? (<NoDataToDisplay />)
                 : data.map(([name, field]: [string, string | IndividualPersonData[]], j: number) => (
                     // check if data is an array
                     Array.isArray(field)
                         // if it's an array, let's display people's data as a series of tiles
                         ? <Tiles data={field} key={j}/>
                         // else, if data is not an array, check if the field as some value
                         
                         // if it's General Description, let's take all the availale space
                         :   field && field.toString().trim()!=="" && name==="General Description"   // this part is hardcoded and has to be changed in the future!
                             &&  <SingleRowTwoColumns key={j}>
                                     <FieldName>{name}</FieldName>
                                     <FieldValue>
                                         <FieldValueFilter fieldToFilter={field}/>
                                     </FieldValue>
                                 </SingleRowTwoColumns>
                             ||
                             field && field.toString().trim()!==""
                             &&  <SingleRowContainer key={j}>
                                     <SingleRowInternalContainer>
                                         <SingleRow>
                                             <FieldName>{name}</FieldName>
                                             <FieldValue>{field}</FieldValue>
                                         </SingleRow>
                                     </SingleRowInternalContainer>
                                 </SingleRowContainer>
                             ||
                             null
             ))}
         </CustomFieldsGroupContainer>
     )
 }
 
 // main function
 export function KeyPeople({ data, accordionName }: { data: KeyPeopleData, accordionName: string }) {
     return (
         <div>
             <FieldsGroup data={dataFilter({ data })} />
         </div>
     )
 }