import styled from 'styled-components';

export const closeIcon =  `${process.env.PUBLIC_URL}/assets/crown-alert-close-icon.svg`;


export const MainContainer = styled.div<{header?: boolean}>`
      min-height: 403px;
      width: 543px;
      border-radius: 8px;
      top: ${({header}) => header === true ? '42%' : '50%'};
      left: ${({header}) => header === true ? '76%' : '50%'};
      transform: translate(-50%, -50%);
      z-index: 8;
      position: fixed;
      background: ${({theme}) => theme.colors.WHITE};
      font-family: "Roboto Condensed", "sans-serif";
      z-index: 10;
      @media (max-width: 768px) {
        width: 80%;
        top: 20%;
        left: 50%;
        transform: translate(-50%, 0);

      }
`

export const DarkLayer = styled.div`
    z-index: 10;
    position: fixed;
    top: 74px;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${({theme}) => theme.colors.DARK[500]};
    opacity: 20%;
`

export const ContentContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
`

export const Title = styled.div`
    margin-top: 63px;
    font-size: 24px;
    color: ${({theme}) => theme.colors.PRIMARY[300]};
    display: flex;
    align-items: center;
    justify-content: center;
`

export const PagePath = styled.div`
    margin-top: 16px;
    font-size: 18px;
    color: ${({theme}) => theme.colors.DARK[400]};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 5px;
`

export const CloseIcon = styled.div<{iconURL:string}>`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    background-image: ${props => `url(${props.iconURL})`};
    background-size: 32px;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
`