import { FunctionComponent } from 'react'
import {
  Container,
  Wrapper,
  Header,
  HeaderTextBig,
  HeaderActions,
  HeaderTextSmall,
  HeaderText,
  Content,
  TableContentContainer,
  SearchWrapper,
  closeIcon,
  rabbitImage
} from '../../styles/admin/AliceRequest.styled'
import AdminTable from './table';
import ModalComponent from 'components/modal';
import useModal from 'hooks/useModal';
import { AliceRabbitImage, CancelButton, CloseButton, Icons, ModalContent, TextOne, TextTwo } from 'styles/admin/AliceRequest.styled';
import { Search } from '../filter/Search'
import DATA from 'data/adminAliceRequests.json'
import { FilterByDate, FilterByStatus } from 'components/filter/Index';
import useFilters from 'hooks/useFilter';
import { DataProps } from 'types';

const AliceRequest:FunctionComponent<{data: DataProps[]; isParty?: boolean}> = ({data, isParty}) => {
  const modal = useModal();
  const {filteredData, setFilter} = useFilters(DATA)
  const setData = (str: string) => {
    setFilter({search: str})
  }
  return (
      <Container>
        <Wrapper>
          <Header>
            <HeaderText>
              <HeaderTextBig>ALICE Requests</HeaderTextBig>
              <HeaderTextSmall>Keep track of requests and their activities</HeaderTextSmall>
            </HeaderText>

          <HeaderActions>
            <FilterByDate setFilter={setFilter} />
            <FilterByStatus setFilter={setFilter} />
          </HeaderActions>

        </Header>
        <Content>
          <SearchWrapper>
            <Search setData={setData} />
          </SearchWrapper>
          <TableContentContainer>
            <AdminTable data={filteredData} controller={modal} isParty={isParty} />
          </TableContentContainer>
        </Content>
        <ModalComponent controller={modal}>
          <ModalContent>
            <CancelButton>
              <Icons onClick={modal.close} src={closeIcon} />
            </CancelButton>
            <AliceRabbitImage src={rabbitImage} />
            <TextOne>Thanks for asking ALICE</TextOne>
            <TextTwo>We will respond to you within 24 hours.</TextTwo>
            <CloseButton onClick={modal.close}>Close</CloseButton>
          </ModalContent>
        </ModalComponent>
      </Wrapper>
    </Container>
  )
}

export default AliceRequest
