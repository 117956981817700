import styled from "styled-components"

export const dropdownIcon = `${process.env.PUBLIC_URL}/assets/dropdown-arrow.svg`;
export const closeIcon = `${process.env.PUBLIC_URL}/assets/close.svg`;
export const fileIcon = `${process.env.PUBLIC_URL}/assets/file.svg`;
export const uploadIcon = `${process.env.PUBLIC_URL}/assets/cloud-upload.svg`;
export const warningIcon = `${process.env.PUBLIC_URL}/assets/warning.svg`;

export const Container = styled.div`
    max-width: 480px;
    background-color: ${({ theme }) => theme.colors.GREY[400]};
`
export const Subtitle = styled.div`
    font-weight: bold;
    font-size: 18px;
    margin: 0 0 10px 0;
`
export const Description = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.BLACK[400]};
`

export const DocumentsTitle = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.BLACK[400]};
    margin-top: 32px;
`

export const SelectContainer = styled.div`
    display: flex;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.DARK[400]};
    padding: 8px 12px;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.WHITE};
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 24px;
`

export const SelectedValue = styled.div`
    flex: 1;
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.GREY[500]};
`

export const Dropdown = styled.div<{ width: number }>`
    z-index: 10;
    position: absolute;
    background: ${({ theme }) => theme.colors.WHITE};
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    margin-top: -16px;
    div:hover {
      background: ${({ theme }) => theme.colors.PRIMARY[400]};
      color: ${({ theme }) => theme.colors.WHITE};
    }
    ${prop => prop.width ? 'width: ' + (prop.width - 40) + 'px' : ''};
`

export const DropdownItem = styled.div`
    display: flex;
    justify-content: center;
    background: ${({ theme }) => theme.colors.WHITE};
    border-radius: 4px;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.DARK[500]};
    padding: 16px 12px;
    cursor: pointer;
`

export const UploadTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.BLACK[400]};
    margin-bottom: 12px;
`

export const DropContainer = styled.div`
    padding-top: 30px;
    padding-bottom: 25px;
    background: ${({ theme }) => theme.colors.WHITE};
    border: 1px dashed ${({ theme }) => theme.colors.DARK[400]};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
`

export const WarningContainer = styled.div`
    padding: 28px 20px;
    background: ${({ theme }) => theme.colors.WHITE};
    border: 1px dashed ${({ theme }) => theme.colors.DARK[400]};
    border-radius: 4px;
    display: flex;
    margin-bottom: 40px;
    justify-content: center;
    gap: 12px;
`

export const WarningDetails = styled.div`
    display: flex;
    flex-direction: column;
`

export const WarningTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.BLACK[400]};
  margin-bottom: 8px;
`

export const WarningMessage = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.BLACK[400]};
  margin-bottom: 16px;
`

export const WarningActionRow = styled.div`
  display: flex;
  justify-content: end;
`

export const CancelButton = styled.div`
  background: ${({ theme }) => theme.colors.WHITE};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
`

export const RetryButton = styled.div`
  background: ${({ theme }) => theme.colors.PRIMARY[400]};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
`

export const DragDropDocuments = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.DARK[400]};
    margin-top: 17px;
    text-align: center;
`

export const SupportedFiles = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.DARK[400]};
    margin-top: 8px;
    text-align: center;
`

export const BrowseContainer = styled.div`
    display: flex;
    gap: 11px;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
`

export const OrText = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.DARK[400]};
`

export const BrowseLink = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.PRIMARY[400]};
    cursor: pointer;
`

export const FileGrid = styled.div`
  display: grid;
  //grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
  grid-auto-rows: 1fr;
  margin-bottom: 24px;
`

export const FileCard = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.DARK[400]};
  border-radius: 4px;
  padding: 12px 12px 40px 12px;
`

export const DeleteButton = styled.div`
    display: flex;
    margin-bottom: 8px;
    justify-content: end;

    img {
      cursor: pointer;
    }
`

export const FileDetailsContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`

export const FileDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`

export const FileName = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.BLACK[400]};
`

export const FileSize = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.BLACK[300]};
`

export const SaveButton = styled.div`
  background-color: ${({ theme }) => theme.colors.PRIMARY[400]};
  font-size: 12px;
  font-weight: 700;
  color: white;
  padding: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
`

export const HiddenFileInput = styled.input`
    display: none;
`

export const UploadImg = styled.img`
    height: 21px;
`