import {
    Wrapper,
    Title,
    TitleIcon,
    TitleText,
    IconWrapper,
    Content,
    Field,
    Value,
    FieldItem,
    ValueItem,
    Icon1,
    Icon2,
    Icon3,
    TBody
} from 'styles/client/Cards.styled';

const Cards = ({num, data}: {num: number; data: Object}) => {
  return (
    <Wrapper num={num}>
      <Title>
        <IconWrapper>
            {num === 1 && <TitleIcon src={Icon1}/>}
            {num === 2 && <TitleIcon src={Icon1}/>}
            {num === 3 && <TitleIcon src={Icon2}/>}
            {num === 4 && <TitleIcon src={Icon3}/>}
        </IconWrapper>
        <TitleText>
            {num === 1 && 'DATA ENQUIRY'}
            {num === 2 && 'FEEDBACK'}
            {num === 3 && 'COMPANIES VIEWED'}
            {num === 4 && 'SEARCHES'}
        </TitleText>
      </Title>
      <Content>
        <TBody>
        <Field>
                  {
                      Object.keys(data).map((name: string, j) => (
                          <FieldItem key={j}>{name}</FieldItem>
                      ))
                  }
        </Field>
        <Value>
                  {
                      Object.values(data).map((field: string, j) => (
                          <ValueItem key={j}>{field}</ValueItem>
                      ))
                  }
        </Value>
        </TBody>
      </Content>
    </Wrapper>
  )
}

export default Cards
