import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import DashboardComponent from "../../components/admin/Dashboard";

const Dashboard = () => {
  const {track} = useTrackActivity();
  
  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, "Alice Admin Dashboard")
  })
  
    return <DashboardComponent />;
  };
  
  export default Dashboard;
  