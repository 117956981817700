import { FC, ReactNode, useContext } from "react";
import { oneOfType, node, arrayOf } from "prop-types";
import { ThemeProvider as Provider, ThemeContext } from "styled-components";
import {useAppData} from "../hooks/useAppData";

type Props = {
  children: ReactNode;
};
const ThemeProvider: FC<Props> = ({ children }) => {
  const {colors} = useAppData()
  
  return (
    <Provider theme={{ colors }}>
      {children}
    </Provider>
  );
};

export const useAppTheme = () => useContext(ThemeContext);

export default ThemeProvider;

ThemeProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};
