import data from 'data/notifications.json'
import {Notifications, NotificationsData} from "types";
import {timeSince} from "utils/timeSince";
import {
    hsbcIcon,
    barclaysIcon,
    swiftIcon,
    defaultIcon,
    DailyContainer,
    DayContainer,
    Container,
    AvatarIcon,
    MessageContainer,
    TimeStamp
} from 'styles/notificationTab/NotificationReader.styled';

const NotificationsReader = () => {

    //object literal that returns an icon based on the name of the notification object
    const avatarSelection: {'HSBC': string, 'Barclays': string, 'Swift': string, 'T360': string} = {
        'HSBC': hsbcIcon,
        'Barclays': barclaysIcon,
        'Swift': swiftIcon,
        'T360': defaultIcon
    }

    /**
     * Sorts notifications starting with the most recent.
     * @return an array that contains the notification sorted by time.
     */
    const sortNotifications = (data: NotificationsData) => {
        return data["Notifications"].sort(function (a: Notifications, b: Notifications) {
            return parseInt(b.timeStamp) - parseInt(a.timeStamp)
        })
    }

    const sortedNotificationsArray: Notifications[] = sortNotifications(data);

    /**
     * Groups notifications by day.
     * @return an array that contains the jsx code for each group of notification
     * example: [day, notificationOfTheDay, otherDay, notificationOfOtherDay, ...].
     */
    const groupByDay = (sortedArray: Notifications[]) => {
        const group = [];
        const dateGroup: {[key:string]: []} = {};

        //for each notification it pushes its code in an array(group) and its date into another one(dateGroup)
        for (let i = 0; i < sortedArray.length; i++) {
            const itemArray = parseInt(sortedArray[i].timeStamp)*1000
            const date = new Date(itemArray).toDateString()
            //if the date is already in dateGroup it doesn't push a second time
            if (!(date in dateGroup)) {
                //if the date is today, returns 'Today' instead the date of the notification
                group.push(
                    <DayContainer key={date}><b>{date !== new Date().toDateString() ? date.substring(4) : 'Today'}</b></DayContainer>
                )
                dateGroup[date] = []
            }
            const index = sortedArray[i].name as keyof {'HSBC': string, 'Barclays': string, 'Swift': string, 'T360': string}
            group.push(
                <DailyContainer key={i}>
                    <Container>
                        <AvatarIcon iconURL={avatarSelection[index]}/>
                        <MessageContainer>{sortedArray[i].messageContent}</MessageContainer>
                        <TimeStamp>{timeSince(new Date(parseInt(sortedArray[i].timeStamp)))}</TimeStamp>
                    </Container>
                </DailyContainer>
            )
        }
        return group;
    }
    const groupedArray = groupByDay(sortedNotificationsArray)

        return(
            <>
                {groupedArray}
            </>
        )
}
export default NotificationsReader