import DATA from 'data/SSI.json';
import { FC } from "react";
import { getTableType } from "utils/party";
import { TableContent } from "components/tableContent/TableContent";
import { NoDataToDisplay } from 'components/noData/ComingSoon';
import SSIImage from './SSIImage';

const SSI: FC<{ accordionName: string, data:typeof DATA | null }> = ({ accordionName, data }) => {
    // country initials
    const Cinitials = data?.[1]?.map((c) => c?.['BENEFICIARY (58A)']?.substring(4,6)) || []

    if(!data) return <NoDataToDisplay />
    return (
        <div>
            <SSIImage Cinitials={Array.from(new Set(Cinitials))} />

            <TableContent
                data={data?.[1]}
                name={data?.[0]!}
                tableType={getTableType(accordionName)}
            />

        </div>
    )
}

export default SSI;