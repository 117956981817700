import { SEARCH_CLASS_TYPE } from 'constants/filter';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import {
    FilterInputWrapper,
    FilterIcon,
    FilterInput
} from 'styles/filter/Search.styled';

type Props = {
    setData?: (arg:string) => void;
    classType?: SEARCH_CLASS_TYPE;
}

export const Search:FunctionComponent<Props> = ({setData, classType=SEARCH_CLASS_TYPE.DEFAULT}) => {
    const [_value, setValue] = useState('');

    const handleValue = (event:ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
        setData?.(event.target.value)
    }
    return (
        <FilterInputWrapper classType={classType}>
                <FilterIcon icon="Search" />
                <FilterInput
                    value={_value}
                    onChange={handleValue}
                    placeholder="Search"
                    aria-label='input'
                    classType={classType}
                />
            </FilterInputWrapper>
    )
}