//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * Shared types, interfaces and styles that are useful for using our React Table implementation.
 */

 import styled, {keyframes} from 'styled-components'

 const animatedLoadingBar = keyframes`
     0% {
     left:0;
     right:100%;
     width:0;
   }
   10% {
     left:0;
     right:75%;
     width:25%;
   }
   90% {
     right:0;
     left:75%;
     width:25%;
   }
   100% {
     left:100%;
     right:0;
     width:0;
   }
 `;

 export const Loader = styled.div`
     width: auto;
     margin: 0 auto;
     border-radius: 10px;
     border: 4px solid transparent;
     position: relative;
     padding: 1px;

     &:before{
         content: '';
         border: 1px solid ${({theme}) => theme.colors.WHITE };
         border-radius: 10px;
         position: absolute;
         top: -4px;
         right: -4px;
         bottom: -4px;
         left: -4px;
     }
 `;

 export const LoaderBar = styled.div`
     position: absolute;
     border-radius: 10px;
     top: 0;
     right: 100%;
     bottom: 0;
     left: 0;
     background: ${({theme}) => `${theme.colors.PRIMARY[400]}` };
     width: 0;
     animation: ${animatedLoadingBar} 2s linear infinite;
 `;

 export const Suggestions = styled.div<{ noMargin?: boolean }>`
     overflow-y: auto;
     max-height: 350px;
     width: 100%;
     border-bottom-left-radius:10px;
     border-bottom-right-radius:10px;
     background: ${({theme}) => theme.colors.GREYED[800] };
     margin: ${({noMargin}) => noMargin ? '' : '-56px 0 100px 0'} ;
     padding: ${({noMargin}) => noMargin ? '10px 0' : ''} ;
     border-radius: ${({noMargin}) => noMargin && '0 0 10px 10px'} ;

     /* scroll bar width */
     &::-webkit-scrollbar {
       width: 10px;
       display:none;

     }

     /* scroll bar track */
     &::-webkit-scrollbar-track {
       box-shadow: inset 0 0 2px ${({theme}) => theme.colors.DARK[400] };
       border-radius: 10px;
     }

     /* scroll bar handle */
     &::-webkit-scrollbar-thumb {
       background: ${({theme}) => theme.colors.GREYED[80] };
       opacity: 0.6;
       border-radius: 10px;
     }

     /* scroll bar handle on hover */
     &::-webkit-scrollbar-thumb:hover {
       background: ${({theme}) => theme.colors.GREYED[100] };
     }
 `;

export const TopSearches = styled.div`
padding: 60px 0 3px 15px;
width: 100%;
background: ${({theme}) => theme.colors.GREYED[800] };
color: ${({theme}) => theme.colors.DARK[500] };
font-weight: 500;
border-top-right-radius:10px;
border-top-left-radius:10px;
font-size: 14px;
`;

 export const SuggestionNotFound = styled.div`
     margin: 10px 0;
 `
