import { FunctionComponent, useState } from "react";
import { ModalController } from "../../hooks/useModal";
import ModalComponent from "../modal";
import { CsuStyle } from "../../styles/ConfirmSubmitUpload.styled";
import { docTypes } from "data/docTypes";

const ConfirmSubmitUpload:FunctionComponent<{controller:ModalController, editButtonAction:(editParameter: string) => void, nextButtonAction:() => void}> = ({controller, editButtonAction, nextButtonAction}) => {

    const cNSInitialValues = ["Confirm and Submit", "Confirm and Submit", "Confirm and Submit", "Confirm and Submit"];
    const [confirmedAndSubmitted, setConfirmedAndSubmitted] = useState(cNSInitialValues);

    const confirmAndContinueClick = (docIndex: number) => {
        let cNSStateChange = [...confirmedAndSubmitted];
        if (cNSStateChange[docIndex] === cNSInitialValues[0]) {
            cNSStateChange[docIndex] = "Submitted"
        } else {
            cNSStateChange[docIndex] = cNSInitialValues[0]
        }
        setConfirmedAndSubmitted(cNSStateChange)
    }

    return (
        <ModalComponent controller={controller} >
            <CsuStyle.ModalLayout>
                <CsuStyle.ModalPartition>
                    <CsuStyle.ModalHouse>
                        <CsuStyle.ModalTitle>{"Please check the text fields for each document before submitting"}</CsuStyle.ModalTitle>
                        {docTypes.map((docType)=>{
                            return (
                                <CsuStyle.DocumentTypeContainer key={docType.index}>
                                    <CsuStyle.DocumentTitle>{docType.DocTypeName}</CsuStyle.DocumentTitle>
                                    {docType.DocDetails.map((docDetail, i)=>{
                                        return (
                                            <CsuStyle.DocumentDetailsContainer key={i}>
                                                <CsuStyle.EditDetailLabel>{docDetail.label}</CsuStyle.EditDetailLabel>
                                                <CsuStyle.EditInput placeholder={"pre-filled information from doc"} />
                                                <CsuStyle.EditButton onClick={()=>editButtonAction(docDetail.label)} />
                                            </CsuStyle.DocumentDetailsContainer>
                                        );
                                    })}
                                    <CsuStyle.ConfirmNSubmitButton onClick={()=>{confirmAndContinueClick(docType.index)}}>{confirmedAndSubmitted[docType.index]}</CsuStyle.ConfirmNSubmitButton>
                                </CsuStyle.DocumentTypeContainer>
                            )
                        })}
                        <CsuStyle.NextButtonContainer>
                            <CsuStyle.NextButton onClick={nextButtonAction}>Next</CsuStyle.NextButton>
                        </CsuStyle.NextButtonContainer>
                    </CsuStyle.ModalHouse>
                </CsuStyle.ModalPartition>
            </CsuStyle.ModalLayout>
        </ModalComponent>
    )
}

export default ConfirmSubmitUpload;