//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated function for retrieving coordinates from a given address (geocoding).
 * Geocoding feature is provided by HERE API: (https://www.here.com/)
 */

import { Coordinates, GeoCoderItem} from "../types";
import axios, {AxiosResponse} from "axios";

const hereApiKey = "IxTsLrem9vn5bEqhNF1lfxMRbr9vo3nUpHemNI83Ado"; // TODO remove ApiKey from here
const apiCall = "https://geocode.search.hereapi.com/v1/geocode";

export const getCoordinatesFromHereApi = async (
  query: string
): Promise<Coordinates> => {
  // positionstack API parameters
  const params = {
    q: query,
    apiKey: hereApiKey,
  };

  const initialState: Coordinates = {
    latitude: 91,
    longitude: 181,
  };

  let coordinatesToReturn: Coordinates = initialState;

  // fetch coordinates from HERE API using axios
  await axios
    .get(apiCall, { params })
    .then((response: AxiosResponse) => {
      // if we have at least one result from the HERE api call, it makes sense to update the coordinates for the map
      if (response.data.items.length > 0) {
        // pick the highest queryScore (HERE accuracy about a given address) item in the response array
        const mostAccurateItem = response.data.items.reduce(
          (itemWithMaxScore: GeoCoderItem, singleItem: GeoCoderItem) =>
            itemWithMaxScore.scoring.queryScore > singleItem.scoring.queryScore
              ? itemWithMaxScore
              : singleItem
        );

        coordinatesToReturn = {
          latitude: mostAccurateItem.position.lat
            ? mostAccurateItem.position.lat
            : 92,
          longitude: mostAccurateItem.position.lng
            ? mostAccurateItem.position.lng
            : 182,
        };
      }
    })

  return coordinatesToReturn;
};
