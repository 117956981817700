import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { PARTY_TAB, PARTY_TABS } from "constants/party";
import { Tab, TabsWrapper, TabItem } from "styles/party/Tabs.styled";
import {useAppData} from "../../hooks/useAppData";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
//recieve the id of the current profile and pass it to the TabItem link as href for navigating
const Tabs: FunctionComponent<{
  selectedTab: PARTY_TAB;
  setTab: Dispatch<SetStateAction<PARTY_TAB>>;
}> = ({ setTab, selectedTab }) => {
    const {status} = useAppData()
    const {track} = useTrackActivity();

  return (
    <Tab>
      <TabsWrapper>
        {PARTY_TABS.map((tab) => (
          <TabItem key={tab} data-testid='tab' onClick={() => {
            setTab(tab)
            track(ActivityType.BUTTON_CLICK, tab.toLocaleLowerCase(), {button_clicked: `${tab} TAB`})
          }} active={tab === selectedTab} status={status}>
            {tab}
          </TabItem>
        ))}
      </TabsWrapper>
    </Tab>
  );
};

export default Tabs;
