/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import DropDownContent from "components/admin/table/DropDownContent";
import {
    DropDownWrapper,
    DropDown,
    DropDownName,
    InnerCover,
    Left,
    Right,
    Icon,
    ArrowDownIcon,
    calendarIcon,
    arrowDown,
    statusIcon
} from "styles/FilterSearch.styled"
import { DATE_RANGE, DATE_RANGES, FILTER_STATUS, STATUS } from 'constants/filter'
import { Filter } from "types";

type FilterProps = {
    setFilter: (filterBy:Partial<Filter>) => void
}


export const FilterByDate = ({ setFilter }: FilterProps) => {

    const [dateOpen, setDateOpen] = useState<boolean>(false)
    const [dateRangeSelected, setDateRangeSelected] = useState<string>(DATE_RANGE.DEFAULT);


    useEffect(() => {
        setFilter({date:dateRangeSelected})
    }, [dateRangeSelected])

    return (
        <DropDownWrapper>
            <DropDown onClick={() => setDateOpen(!dateOpen)} role="button">
                <InnerCover>
                    <Left>
                        <Icon src={calendarIcon} />
                        <DropDownName>{dateRangeSelected === DATE_RANGE.DEFAULT ? 'Date range' : dateRangeSelected}</DropDownName>
                    </Left>
                    <Right>
                        <ArrowDownIcon src={arrowDown} />
                    </Right>
                </InnerCover>
            </DropDown>
            {dateOpen && <DropDownContent onSelect={setDateRangeSelected} setOpen={setDateOpen} items={DATE_RANGES} />}
        </DropDownWrapper>
    )
}

export const FilterByStatus = ({ setFilter }: FilterProps) => {
    const [statusSelected, setStatusSelected] = useState<string>(STATUS.DEFAULT);
    const [statusOpen, setStatusOpen] = useState<boolean>(false);

    useEffect(() => {
        setFilter({status:statusSelected})

    }, [statusSelected])


    return (
        <DropDownWrapper>
            <DropDown onClick={() => setStatusOpen(!statusOpen)} role="button">
                <InnerCover>
                    <Left>
                        <Icon src={statusIcon} />
                        <DropDownName>{statusSelected === STATUS.DEFAULT ? 'Status' : statusSelected}</DropDownName>
                    </Left>
                    <Right>
                        <ArrowDownIcon src={arrowDown} />
                    </Right>
                </InnerCover>
            </DropDown>
           {statusOpen && <DropDownContent onSelect={setStatusSelected} setOpen={setStatusOpen} items={FILTER_STATUS} />}
        </DropDownWrapper>
    )
}