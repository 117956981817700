import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: 40px;
`;
export const Item = styled.div`
    padding-right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    font-family: Roboto Condensed;
    border-bottom: 1px solid ${({theme}) => theme.colors.SECONDARYHOVER[100]};
    @media (max-width: 768px) {
        display:flex;
        flex-direction: column;
        padding-right: 5%;
        }
`; 
export const Value = styled.div`
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({theme}) => theme.colors.GREYED[700]};
    width: 250px;
    margin-right: 10px;
    @media (max-width: 768px) {
        width: 100%;
        }
`;
export const Field = styled.div<{legal: boolean, isArray: boolean}>`
    font-family: Roboto Condensed;
    width: 716px;
    height: ${({isArray}) => isArray ? 'fit-content' : '39px'};
    background: ${({legal, theme}) => legal ? theme.colors.PRIMARY[25] : theme.colors.WHITE};
    border: ${({legal, theme}) => legal ? `1px solid ${theme.colors.PRIMARY[400]}` : `1px solid ${theme.colors.GREYED[300]}`};
    border-radius: 8px;
    color: ${({theme}) => theme.colors.GREY[500]};
    padding-left: 14px;
    padding: ${({isArray}) => isArray ? '10px 8px' : ''};
    display: flex;
    flex-direction: ${({isArray}) => isArray ? 'column' : 'row'};
    align-items: ${({isArray}) => isArray ? 'flex-start' : 'center'};
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 20px;
        }
`;
export const FieldListItem = styled.div`
    padding-bottom: 24px;
    font-family: Roboto Condensed;
`;
export const FieldItem = styled.div``;

export const List = styled.div`
`