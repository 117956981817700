import CrownAlert from "components/crownAlert/CrownAlert";
import { FunctionComponent, PropsWithChildren, createContext, useContext, useState } from "react";

interface ModalContextType {
    isOpen: boolean;
    menuToggle: (selectedField: string, selectedKey: string) => void;
}

export const AliceModalContext = createContext<ModalContextType>({
    isOpen: false,
    menuToggle: () => {},
})

export const ModalProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [field, setField] = useState('');
    const [key, setKey] = useState('')

    const menuToggle = (name?: string, key?: string) => {
        setIsOpen(!isOpen)
        setField(name || '')
        setKey(key || '')
    }

    const contextValue: ModalContextType = {
        isOpen,
        menuToggle,
    }

    return (
        <AliceModalContext.Provider value={contextValue}>
            {children}
            <CrownAlert
                alertIsActive={isOpen}
                menuToggle={menuToggle}
                selectedField={field}
                selectedKey={key}
            />
        </AliceModalContext.Provider>
    )
}

export const useAliceContext = () => useContext(AliceModalContext)