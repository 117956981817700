/**
 * This is the dedicated component for the Search Page Results of Party Manager.
 * It uses a GCF to get the results.
 * Before displaying the Search Results:
 * a) runs some checks:
 * - a1. to retrieve useful data from the GCF results, and
 * - a2. to avoid displaying empty or duplicate items
 * b) places as first items the Ultimate Owner and the target entity (the entity selected by user in Search Suggestions)
 * c) sorts all the other Search Results by Total Assets in descending order
 */

// TODO: add the ErrorDisplay, avoid to do many calls inside the component, move out some of the code, refactoring in order to simplify code, remove duplicates in search results

import { useOktaAuth } from "@okta/okta-react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
    ASPECT_FILES_PATH,
    ASPECT_FILES_SOURCE_BUCKET_NAME,
    FINANCIAL_GROUP_CACHE_DESTINATION_BUCKET_NAME
} from "../../config/googleCloudConfig";
import { getFederatedToken } from "../../services/okta/federatedAccessToken";
import { isResultEmpty, setResult } from "../../utils/searchResultsUtils";
import {
  CreateFinancialGroupCachePayload,
  FinancialGroupItem,
  Result,
} from "../../types";
import { CREATE_FINANCIAL_GROUP_CACHE_GCF } from "../../config/googleCloudFunctionsConfig";
import { SearchResultsItem } from "../../components/searchResults/searchResultsItem/SearchResultsItem";
import useEffectOnce from "../../hooks/useEffectOnce";
import { SearchResultsContainer } from "../../styles/searchResult/SearchResult.styled";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { useNavigate } from "react-router-dom";
import NoDataFound from "components/noData/NoDataFound";

const callGCFunction = async (apiURL: string, accessToken: string, entityId: string, limit?: string) => {
    let payload:CreateFinancialGroupCachePayload = {
        "accessToken": accessToken,
        "fromBucketName": ASPECT_FILES_SOURCE_BUCKET_NAME,
        "filePath": ASPECT_FILES_PATH,
        "toBucketName": FINANCIAL_GROUP_CACHE_DESTINATION_BUCKET_NAME,
        "storagePath": "entityZip",
        "mod": "6",
        "toGit": "[1, 1, 1]",
        "fromGit": "[1, 1, 1]",
        "entityId": entityId,
    }

  if (limit) payload.limit = limit;

  try {
    return await fetch(apiURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
  } catch (err) {
    // ErrorDisplay(err, "Calling create-financial-group-cache GCF has failed", "SearchResults")
  }
};

export let downloadCacheFromGCS = async (
  accessToken: string,
  bucketName: string,
  financialGroupGuoId: string
) => {
  const url = `https://storage.googleapis.com/storage/v1/b/${bucketName}/o/${financialGroupGuoId}.json?alt=media`;
  const stsToken = await getFederatedToken(accessToken);
  return fetch(url, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + stsToken,
      "Content-Type": "application/json"
    }
  });
};

export const SearchResults = () => {
  const { track } = useTrackActivity();

  const { targetEntityId } = useParams<{ targetEntityId: string }>();
  const { authState, oktaAuth } = useOktaAuth();
  const [resultList, setResultList] = useState<Result[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const topSearchResults: Result[] = [];
  const searchResults: Result[] = [];
  const [loadTime, setLoadTime] = useState(0);
  const [errorMessage, setErrorMessage] = useState(false);
  const [countdown, setCountdown] = useState(7);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // main entry-point function
  const loadSearchResults = (accessToken: string) => {
    // call the GCF function that attempts to create a new financial group cache
    if (targetEntityId) {
      const timer = Date.now();
      callGCFunction(
        CREATE_FINANCIAL_GROUP_CACHE_GCF,
        accessToken,
        targetEntityId,
          "1000"
      )
        .then((response: Response | undefined) => {
          if (response) {
            // we need to know the returned GUO id in order to download the Financial Group from cache
            response.text().then((guoId: string) => {
              downloadCacheFromGCS(
                accessToken,
                FINANCIAL_GROUP_CACHE_DESTINATION_BUCKET_NAME,
                guoId
              )
                .then((gcsResponse) => gcsResponse.json())
                .then((financialGroup) => {
                  if (financialGroup.length !== 0)
                    financialGroup.forEach(
                      (financialGroupItem: FinancialGroupItem) => {
                        const tmpSRItem: Result = setResult(
                          financialGroupItem,
                          guoId,
                          targetEntityId
                        );
                        // do not add duplicates or empty Search Results items to the searchResults[]
                        if (
                          !searchResults.some(
                            (srItem: Result) => srItem.id === tmpSRItem.id
                          ) &&
                          !isResultEmpty(tmpSRItem)
                        ) {
                          if (tmpSRItem.isGuo)
                            topSearchResults.unshift(tmpSRItem);
                          else if (tmpSRItem.isTargetEntity)
                            topSearchResults.push(tmpSRItem);
                          else searchResults.push(tmpSRItem);
                        }
                      }
                    );
                })
                .finally(() => {
                  // TODO: verify that sorting by total assets (t.a.) is working correctly
                  searchResults.sort((a, b) => b.totalAssets - a.totalAssets);
                  setResultList(topSearchResults.concat(searchResults));
                  setIsLoading(false);
                  setLoadTime((Date.now() - timer) / 1000);
                })
                .catch((downErr: Error) => {
                  // ErrorDisplay(downErr, "downloadCacheFromGCS has failed", "SearchResults")
                });
            });
          } else if (response === undefined) {
            setErrorMessage(true);
            setIsLoading(false);
          }
        })
        .catch((err: Error) => {
          setErrorMessage(true);
          // ErrorDisplay(err, "callGCFunction has failed", "SearchResults");
        });
    }
  };

  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, "Search Results");
    if (authState && authState.isAuthenticated) {
      const accessToken = oktaAuth.getAccessToken();
      if (accessToken) loadSearchResults(accessToken);
    }
  });

  if (!authState) return <LoadingSpinner />;

  if (isLoading) return <LoadingSpinner />; // TODO: add here a proper 'Searching Data' spinner
  if (errorMessage) {
    if (countdown === 0) {
      navigate("/search");
    }
    return <NoDataFound countdown={countdown} />;
  }
  return (
    <SearchResultsContainer className="container">
      <p>
        About {resultList.length} results ({loadTime.toFixed(2)} second
        {loadTime > 1 ? "s" : ""})
      </p>
      {resultList.length === 0 ? (
        <div>Sorry, no data available for the selected entity </div> // TODO: add here a proper no-data screen
      ) : (
        resultList.map((searchResultsItem, index) => (
          <SearchResultsItem {...searchResultsItem} key={index} />
        ))
      )}
    </SearchResultsContainer>
  );
};
