import { useState } from 'react'
import { FunctionComponent } from "react";
import { ModalController } from "../../hooks/useModal";
import ModalComponent from "../modal";
import {logout} from "../../services/okta/okta";
import {useOktaAuth} from "@okta/okta-react";
import {
SoModalHouse, SoCancelButton, SoModalDepictionImage,
SoModalTitle, SoModalMessage, SoActionButtonsContainer, SoActionButton,
 } from "../../styles/SignOut.styled";
import LoadingSpinner from '../LoadingSpinner';
import { ActivityType, trackActivity } from 'hooks/useTracker';

const SignOut:FunctionComponent<{controller:ModalController}> = ({controller}) => {

    let [loading, setLoading] = useState<boolean>(false);

    const { oktaAuth } = useOktaAuth();

  const closeModal = () => {
      setLoading(false);
      controller.close();
  }

  const signOutAction = () => {
      setLoading(true);
      logout(oktaAuth);
      setLoading(false);
      controller.close();
      trackActivity(ActivityType.SIGNOUT, 'signout page')
  }

    return (
        <>
            <ModalComponent controller={controller} >
                <SoModalHouse>
                    <SoCancelButton onClick={closeModal} />
                    <SoModalDepictionImage />
                    <SoModalTitle>Sign out from T360</SoModalTitle>
                    <SoModalMessage>Are you sure you want to sign out from your T360 account?</SoModalMessage>
                    <SoActionButtonsContainer>
                        <SoActionButton onClick={controller.close}><span>Cancel</span></SoActionButton>
                        <SoActionButton onClick={signOutAction}>{loading ? <LoadingSpinner /> : <span>Sign Out</span>}</SoActionButton>
                    </SoActionButtonsContainer>
                </SoModalHouse>
            </ModalComponent>
        </>
    )
}

export default SignOut;