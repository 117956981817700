import styled from "styled-components"

export const MetadataContainer = styled.div`
  border: 1px solid black;
`

export const EmptyAlert = styled.span`
  color: red;
  font-weight: bolder;
`

export const MetadataInput = styled.div`
  margin: 20px 0;
`

 export const MetadataInputField = styled.div`
    
    & span {
      display: inline-block;
      min-width: 25%;
    }
`