//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * Page for testing first implementation of T360 CheckMate.
 */

import {useOktaAuth} from "@okta/okta-react"
import {useEffect, useState, MouseEvent} from "react";
import AlertFeedbackComp from "../../components/alert/AlertFeedback";
import {createControllerPayload} from "../../utils/airTableForTesting";
import {generateRandomString} from "../../utils/randomStringGenerator";
import AlertWaitingIcon from "../../components/alert/AlertWaitingIcon";
import {AirTableSelectedDocument, AlertFeedbackType, initialMetadataValue, Metadata} from "../../types";
import {StatusProps} from "constants/index";
import {CHECKMATE_CONTROLLER_GCF} from "../../config/googleCloudFunctionsConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import {callGCFunction} from "../../services/callGCFunction";
import MainTitle from "../../components/alertAirtableTesting/MainTitle";
import SubmitButton from "../../components/alertAirtableTesting/SubmitButton";
import DummyDataSelector from "../../components/alert/airTable/DummyDataSelector";
import EXAMPLE_DATA from "../../data/checkmateControllerResponseExample";
import useModal from "hooks/useModal";
import { MainContainer } from "styles/alertAirtableTesting/AlertAirtableTesting.styled";
import { useToast } from "hooks/useToast";

const CheckMateAirtableTesting = () => {
    const {toast} = useToast();
    const { authState, oktaAuth } = useOktaAuth();
    const [accessToken, setAccessToken] = useState<string|null>(null);
    const [selectedDocuments, setSelectedDocuments] = useState<AirTableSelectedDocument[]>([])
    const [metadata, setMetadata] = useState<Metadata>(initialMetadataValue)
    const [callGCF, setCallGCF] = useState(true);
    const [showWaitingIcon, setShowWaitingIcon] = useState<boolean>(false);

    const controllerResponse = useModal<AlertFeedbackType>();

    const handleSubmit = (event:MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const dateToUse = metadata.date_time.trim() === ""
            ? new Date().toISOString()
            : metadata.date_time + `:00.000Z`

        const randomString = generateRandomString(6)

        const transactionId = metadata.transaction_id.trim() === ""
            ? randomString
            : metadata.transaction_id.padStart(6, '0')

        if (accessToken){
            const meta:Metadata = {
                pid: metadata.pid,
                user_id: `${metadata.pid}_u_${metadata.user_id.padStart(3, '0')}`,
                event_id: `${metadata.pid}_ev_${randomString}`,
                transaction_id: `${metadata.pid}_tr_${transactionId}`,
                date_time: dateToUse,
            }
            const dataForGCF:Object = createControllerPayload(accessToken, meta, selectedDocuments);

            setShowWaitingIcon(true)

            callGCFunction(CHECKMATE_CONTROLLER_GCF,
                dataForGCF, "CheckMate GCF controller", "checkMate")
                .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
                .then((data:AlertFeedbackType) => {
                    controllerResponse.open(data)
                })
                .catch((err: Error) => toast(err.toString(), StatusProps.ERROR))
                .finally(() => setShowWaitingIcon(false))
        }
    }

    // TODO: remove when is not more necessary to test with static testing data
    const handleSubmitDev = (event:MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        controllerResponse.open(EXAMPLE_DATA)
    }

    useEffect(() => {
        if (authState && authState.isAuthenticated) {
            const token = oktaAuth.getAccessToken();
            if (token) setAccessToken(token)
        }
    },[authState, oktaAuth])

    if (!authState) return <LoadingSpinner/>

    const string1 = "Pam's Dummy Data Entry";
    const string2 = "Devs' testing"

    return (
        <MainContainer>
            <button onClick={() => setCallGCF(true)}>{string1}</button>
            <button onClick={() => setCallGCF(false)}>{string2}</button>
            <MainTitle text={callGCF ? string1 : string2}/>
            { callGCF &&
                <DummyDataSelector
                    metadataProps={{metadata: metadata, setMetadata: setMetadata}}
                    selectedDocumentProps={{selectedDocuments: selectedDocuments, setSelectedDocuments: setSelectedDocuments}}
                />
            }
            <SubmitButton
                onClick={(event:MouseEvent<HTMLButtonElement>) => callGCF ? handleSubmit(event) : handleSubmitDev(event)}
                text={"Check the docs usage"}
                type={"submit"}
                disabled={callGCF ? metadata.pid.trim() === "" || metadata.user_id.trim() === "" || selectedDocuments.length === 0 : false}
            />
            {/* TODO: add a check for errors from the controller before mounting the component */}
            { showWaitingIcon && <AlertWaitingIcon/>}
            <AlertFeedbackComp controller={controllerResponse}/>
        </MainContainer>
    )
}
export default CheckMateAirtableTesting;
