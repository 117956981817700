import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
`;
export const DashboardWrapper = styled.div`
  position: relative;
  padding-left: 5px;
`;

export const Top = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.GRAY[200]};
  border-radius: 12px;
  margin-bottom: 60px;
`;

export const Title = styled.p<{ h1?: boolean }>`
  color: ${({theme}) => theme.colors.DARK[400]};
  font-size: ${({ h1 }) => (h1 ? "36px" : "20px")};
  font-weight: 700;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.GRAY[200]};
`;

export const Button = styled.button`
  background: ${({theme}) => theme.colors.WHITE};
  color: ${({theme}) => theme.colors.DARK[500]};
  border: ${({theme}) => `1px solid ${theme.colors.GREYED[300]}`};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 125px;
  height: 40px;
`;

export const ChartCover = styled.div`
  padding: 24px;
  display: flex;

  @media (max-width:1000px) {
    flex-direction: column ;
  }
`;

export const ImageCover = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
`;

export const Image = styled.img`
  display: flex;
  margin-bottom: 20px ;

  @media (max-width:600px) {
    width: 100%;
  }
`;

export const Boxes = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Box = styled.div`
  width: calc(100% / 3 - 70px);
  height: 260px;
  margin-right: 20px;
  margin-bottom: 30px;
  background: ${({theme}) => theme.colors.WHITE};
  box-shadow: ${({theme}) => theme.colors.WHITE!=='#FFFFFF'?'0px 0px 8px rgba(255, 255, 255, 0.3)':'0px 0px 8px rgba(0, 0, 0, 0.1)'};
  border-radius: 12px;
  min-width: 200px;
  padding: 24px;

  @media (max-width: 768px) {
    width: calc(100%/2 - 70px);
  }
  
  @media (max-width: 420px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const Bottom = styled.div`
  margin-bottom: 50px;
`;

export const TopBox = styled.div`
  width: 350px;
`;

export const IconCover = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
`;

export const Icon = styled.div<{ src: string }>`
    width: 100px;
    height: 80px;
    display: inline-block;
    vertical-align: middle;
    background-image: ${props => `url(${props.src})`};
    background-repeat: no-repeat;
`

export const Text = styled.p`
  font-size: 16px;
  color: ${({theme}) => theme.colors.GREYED[700]};
  margin: 0;
`;
