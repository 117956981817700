import { useState, useEffect, FunctionComponent, useMemo } from "react";
import _ from "lodash";
import {
  ViewButton,
  ViewPageContainer,
  ViewPageImage,
  Title,
  SubTitle,
  TransactionContainer,
  TransactionContent,
  TransactionIcon,
  TransactionItem,
  TransactionTitle,
  GoToTransactionContainer,
  GoToTransactionTitle,
  GreenIcon,
  GreenStatus,
  GreenSubTitle,
  GreenTransactionContainer,
  GrrenPageContainer,
  DetailContainer,
  DetailContent,
  DetailTitle,
  DetailContentItem,
  DetailItem,
  AlertStatusContainer,
  AlertStatusIcon,
  AlertStatusItem,
  AlertStatusList,
  AlertStatusText,
  ArrowBack,
  Details,
  assetDir
} from "styles/AlertFeedback.styled";
import {
  AlertFeedbackType,
  ComparatorSingleObject,
  CounterSingleObject,
  SingleFeedbackResult,
  StructuredData,
} from "../../types";
import ModalComponent from "components/modal";
import { ModalController } from "hooks/useModal";
import { TRANSACTION_STATUS } from "constants/index";

const GreenPage = ({ onClick }: { onClick: () => void }) => (
  <GrrenPageContainer>
    <Title>Alert Status</Title>
    <GreenIcon src={`${assetDir}checkmate-verified.svg`} alt="green status" />
    <GreenSubTitle>Normal Due Diligence sufficient</GreenSubTitle>
    <GreenStatus>Transaction Status</GreenStatus>
    <GreenTransactionContainer>
      <TransactionItem type={TRANSACTION_STATUS.APPROVED} onClick={onClick}>
        <TransactionIcon icon="approved" />
        <TransactionTitle>Approved</TransactionTitle>
      </TransactionItem>
      <TransactionItem type={TRANSACTION_STATUS.IN_PROGRESS} onClick={onClick}>
        <TransactionIcon icon="in-progress" />
        <TransactionTitle>In Progress</TransactionTitle>
      </TransactionItem>
      <TransactionItem type={TRANSACTION_STATUS.DENIED} onClick={onClick}>
        <TransactionIcon icon="denied" />
        <TransactionTitle>Denied</TransactionTitle>
      </TransactionItem>
    </GreenTransactionContainer>
  </GrrenPageContainer>
);

const ViewPage = ({ rag, onClick }: { rag: string; onClick: () => void }) => (
  <ViewPageContainer>
    <ViewPageImage
      src={`${assetDir}checkmate-${rag.toLowerCase()}-bg.svg`}
      alt="Checkmate"
    />
    <Title>Alert Status</Title>
    <SubTitle>Proceed to view usage feedback here!!!</SubTitle>
    <ViewButton onClick={onClick}>View</ViewButton>
  </ViewPageContainer>
);

const AlertStatusPage = ({
  onClick,
  onGotoTransaction,
  data,
}: {
  data: StructuredData[];
  onClick: (data: SingleFeedbackResult) => void;
  onGotoTransaction: () => void;
}) => (
  <AlertStatusContainer>
    <Title>Alert Status</Title>
    <AlertStatusList>
      {data.map(([, value]: [string, SingleFeedbackResult]) => (
        <AlertStatusItem
          key={value.comment}
          rag={value.rag}
          onClick={() => onClick(value)}
        >
          <AlertStatusText>{value.comment} </AlertStatusText>
          <AlertStatusIcon
            src={`${assetDir}alert-status-icon.svg`}
            alt="alert status"
          />
        </AlertStatusItem>
      ))}
    </AlertStatusList>
    <GoToTransactionContainer>
      <GoToTransactionTitle onClick={onGotoTransaction}>
        Go to Transaction Status
      </GoToTransactionTitle>
    </GoToTransactionContainer>
  </AlertStatusContainer>
);

const DetailPage = ({
  data,
  goBack
}: {
  data: SingleFeedbackResult | null;
  goBack: () => void
}) => (
  <Details>
    <Title>DETAILS</Title>
    <DetailContainer>
      <ArrowBack onClick={goBack} />
      {data &&
        Array.isArray(data.data) &&
        data.data.map((d, index) => (
          <DetailContentItem key={index}>
            {d.document_address && (
              <DetailItem>
                <DetailTitle>Client Address</DetailTitle>
                <DetailContent>{d.document_address}</DetailContent>
              </DetailItem>
            )}
            {d.client_pid && (
              <DetailItem>
                <DetailTitle>Client PID</DetailTitle>
                <DetailContent>{d.client_pid}</DetailContent>
              </DetailItem>
            )}
            <DetailItem>
              <DetailTitle>Name</DetailTitle>
              <DetailContent>{d.document_name}</DetailContent>
            </DetailItem>
          </DetailContentItem>
        ))}
    </DetailContainer>
  </Details>
);

const TransactionStatusPage = ({ onClick }: { onClick: () => void }) => (
  <div>
    <Title>Transaction Status</Title>
    <TransactionContainer>
      <TransactionContent>
        <TransactionItem type={TRANSACTION_STATUS.APPROVED} onClick={onClick}>
          <TransactionIcon icon="approved" />
          <TransactionTitle>Approved</TransactionTitle>
        </TransactionItem>
        <TransactionItem type={TRANSACTION_STATUS.IN_PROGRESS} onClick={onClick}>
          <TransactionIcon icon="in-progress" />
          <TransactionTitle>In Progress</TransactionTitle>
        </TransactionItem>
        <TransactionItem type={TRANSACTION_STATUS.DENIED} onClick={onClick}>
          <TransactionIcon icon="denied" />
          <TransactionTitle>Denied</TransactionTitle>
        </TransactionItem>
      </TransactionContent>
    </TransactionContainer>
  </div>
);

const AlertFeedback: FunctionComponent<{
  controller: ModalController<AlertFeedbackType>;
}> = ({ controller }) => {
  const response = useMemo(
    () => controller.modalData || { comparator: {}, counter: {}, rag: "" },
    [controller.modalData]
  );

  const [statusInfoItems, setStatusInfoItems] = useState<any[]>([]);
  const [singleFeedback, setSingleFeedback] =
    useState<SingleFeedbackResult | null>(null);
  const [step, setStep] = useState(1);
  useEffect(() => {
    setStep(response?.rag === "GREEN" ? 5 : 1);
    let tempStatusItems: [ _.LoDashStatic, SingleFeedbackResult | ComparatorSingleObject][] = [];
    const isEmptyCounterSingleObject = (
      value: CounterSingleObject
    ): boolean => {
      return _.isEqual(value, {
        Bank: {},
        CarRental: {},
        Feedback: {},
        PhoneShop: {},
      });
    };

    const counterArray: [string, CounterSingleObject][] = Object.entries(
      response.counter
    ).filter(([, value]: [string, CounterSingleObject]) => {
      return !isEmptyCounterSingleObject(value);
    });

    counterArray.forEach(
      ([, counterSingleObject]: [string, CounterSingleObject]) => {
        Object.entries(counterSingleObject).forEach(
          ([, value]: [string, SingleFeedbackResult]) => {
            if (!_.isEqual(value, {})) {
              tempStatusItems.push([_, value]);
            }
          }
        );
      }
    );

    const comparatorArray: [
      string,
      ComparatorSingleObject | SingleFeedbackResult
    ][] = Object.entries(response.comparator).filter(
      ([, value]: [
        string,
        ComparatorSingleObject | SingleFeedbackResult | {}
      ]) => {
        return !_.isEmpty(value);
      }
    );

    comparatorArray.forEach(
      ([, value]: [string, ComparatorSingleObject | SingleFeedbackResult]) => {
        /* Check if the value object it's a SingleFeedbackResult.
         * > Ideally we should use a TypeScript type guard:
         * > https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
         * > But the custom function isSingleFeedbackResult() throws an Unexpected token error at runtime.
         * If it's not a SingleFeedbackResult, then it's a ComparatorSingleObject.
         * > So we need to iterate in order to extract the SingleFeedbackResult objects.
         */
        return "rag" in value
          ? tempStatusItems.push([_, value])
          : Object.entries(value).forEach(
              ([, value]: [string, SingleFeedbackResult]) => {
                tempStatusItems.push([_, value]);
              }
            );
      }
    );
    setStatusInfoItems(tempStatusItems);
  }, [response]);

  const onClickItem = (data: SingleFeedbackResult) => {
    setSingleFeedback(data);
    setStep(3);
  };

  const close = () => {
      controller.close();
      setStep(1)
  }

  return (
    <ModalComponent controller={controller} width="660px" padding="40px">
      {step === 1 && (
        <ViewPage rag={response?.rag} onClick={() => setStep(2)} />
      )}
      {step === 2 && (
        <AlertStatusPage
          data={statusInfoItems}
          onClick={onClickItem}
          onGotoTransaction={() => setStep(4)}
        />
      )}
      {step === 3 && (
        <DetailPage data={singleFeedback} goBack={() => setStep(2)} />
      )}
      {step === 4 && (
        <TransactionStatusPage onClick={close} />
      )}
      {step === 5 && <GreenPage onClick={close} />}
    </ModalComponent>
  );
};
export default AlertFeedback;
