import {HttpHeaders} from "../types";

/**
 * Abstraction of the built-in fetch function for retrieving data from an endpoint.
 * @param {string} endpointPath the complete path to the endpoint (e.g. `https://my-api.com/get/something`)
 * @param {HttpHeaders} headers optional headers for the HTTP request
 */
export const fetchData = (endpointPath:string, headers?:HttpHeaders):Promise<ArrayBuffer> => {
    return fetch(endpointPath, {headers})
        .then(response => {
            if(!response.ok)
                throw new Error('Network response was not OK');
            return response.arrayBuffer()
        })
        .catch((error:Error) => {
            return new ArrayBuffer(0);
        })
}