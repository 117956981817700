import ReportComponent from 'components/client/Report'
import useEffectOnce from 'hooks/useEffectOnce';
import { ActivityType, useTrackActivity } from 'hooks/useTracker';

const Report = () => {
    const {track} = useTrackActivity();
    
    useEffectOnce(() => {
        track(ActivityType.LOAD_PAGE, "Client Reports")
    })
    return (
        <ReportComponent />
    )
}

export default Report;