//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * Shared types, interfaces and styles that are useful for using our React Table implementation.
 */

import styled from "styled-components";

const searchIcon = process.env.PUBLIC_URL + "/assets/searchIcon.svg";
const searchIconWhite = process.env.PUBLIC_URL + "/assets/searchIcon-white.svg";

export const SearchWrapper = styled.div`
  z-index: -1;
`;

export const SearchMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 40px 0;

  & > div {
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;
  }
`;
export const SearchContainer = styled.div<{ marginTop?: string }>`
  display: flex;
  justify-content: center;
  margin-top: ${({ marginTop }) => marginTop || "25vh"};
`;

export const DefaultSearchContainer = styled.div`
  display: block;
  width: 80%;
  margin: auto;
  position: relative;
`;

export const SearchInputForm = styled.form`
  width: 100%;
  position: relative;
  color: ${({ theme }) => theme.colors.DARK[500]};
  background: ${({ theme }) => theme.colors.GREYED[800]};
`;

export const BasicSearchContainer = styled.div`
  width: 100%;
  height: 56px;
  position: relative;
  border-radius: 08px;
`;

export const BasicSearchInput = styled.div`
  width: 100%;
  overflow-x: hidden;

  & input {
    width: 100%;
    height: 56px;
    color: ${({ theme }) => theme.colors.DARK[500]};
    background: ${({ theme }) => theme.colors.GREYED[800]};
    border: 0;
    padding: 3px 40px 0 50px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
    font-size: 16px;
    outline: none;
    &:input:active {
      border-color: transparent;
    }
    &:focus {
      border-color: transparent;
      border-bottom: 1px solid ${({ theme }) => theme.colors.GREYED[800]};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers */
      font-weight: normal;
      font-style: italic;
      color: ${({ theme }) => theme.colors.DARK[400]};
    }
    &:input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      font-weight: normal;
      font-style: italic;
      color: ${({ theme }) => theme.colors.DARK[400]};
    }
    &:input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      font-weight: normal;
      font-style: italic;
      color: ${({ theme }) => theme.colors.DARK[400]};
    }
    &:input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      font-weight: normal;
      font-style: italic;
      color: ${({ theme }) => theme.colors.DARK[400]};
    }
  }
`;

export const SearchIconContainer = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  padding-right: 8px;
`;

export const SearchIconButton = styled.button`
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;

  &:hover {
    opacity: 0.8;
  }

  & span {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(${({theme}) => theme.colors.WHITE!=='#FFFFFF'? searchIconWhite : searchIcon});
    background-repeat: no-repeat;
  }
`;

export const DropDownContainer = styled("div")`
  position: absolute;
  z-index: 3;
  color: ${({ theme }) => theme.colors.DARK[500]};
`;

export const DropDownHeader = styled("div")`
  width: 150px;
  font-size: 14px;
  padding: 13px 20px 5px 20px;
  background: ${({ theme }) => theme.colors.GREYED[800]};
  border: 1px solid ${({ theme }) => theme.colors.GREYED[300]};
  box-shadow: 0px 1px 2px ${({ theme }) => theme.colors.BLUEISH[4]};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  position: relative;
  & > div {
    margin-top: -4px;
  }
`;

export const DropDownListContainer = styled("div")``;

export const DropDownList = styled("ul")`
  width: 150px;
  font-size: 14px;
  padding: 10px 20px;
  background: ${({ theme }) => theme.colors.GREYED[800]};
  border: 1px solid ${({ theme }) => theme.colors.GREYED[300]};
  box-shadow: 0px 1px 2px ${({ theme }) => theme.colors.BLUEISH[4]};
  border-radius: 8px;
`;

export const ListItem = styled.li<{ disabled: boolean }>`
  list-style: none;
  margin: 1rem auto;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.GREY[500] : theme.colors.DARK[500]};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;
export const CancelIconContiner = styled("div")`
  position: absolute;
  color: ${({ theme }) => theme.colors.DARK[500]};
  right: 8px;
  top: 20px;
  padding-right: 8px;
`;
