import { FC, useState, useEffect } from "react";
import {
  Container,
  Title,
  SubHeader,
  SubHeaderNav,
  SubContent
} from 'styles/party/funds/ManageFunds.styled'

import { FundDocumentsType, FundType } from "types/funds";
import useOktaAccessToken from "hooks/useOktaHooks";
import { useParams } from "react-router-dom";
import { getSubFundById, listFundsDocuments, getSubFunds } from "services/funds";
import Funds from "../index";
import Close from "@material-ui/icons/Close";
import { useAppData } from "hooks/useAppData";
import LoadingSpinner from "components/LoadingSpinner";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { SingleFund } from "../../../../types";
import { FilterableTable } from "components/filterableTable";

const getSubFundsByChildID = (subFunds: SingleFund[], childFundsIds: string) => {
  const fundIds = childFundsIds.split(",");
  const data: FundType[] = [];
  subFunds.forEach((fund: FundType) => {
    if (fundIds.includes(fund["Fund ID"])) {
      data.push(fund);
    }
  });
  return data;
};

const SubFunds: FC<{
  data: { [key: string]: string };
  childFundIds: string;
  filter: string;
}> = ({ data, childFundIds, filter }) => {
  const [isSub, setSub] = useState(false);
  const { colors } = useAppData();
  const [fundData, setFundData] = useState<{ [key: string]: string } | null>(null);
  const [loading, setLoading] = useState(false);
  const [subFundsData, setSubFundsData] = useState<FundType[]>([]);
  const [fundDocuments, setFundDocuments] = useState<FundDocumentsType | null>(
    null
  );
  const [selectedIndex, setSelectedIndex] = useState("");
  const accessToken = useOktaAccessToken();
  const { id } = useParams<{ id: string }>();
  const close = () => {
    setSub(false);
    setSelectedIndex("");
  };
  const [limit] = useState(40);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const viewedPagesLength = currentPage * limit;
  const total = subFundsData.length;
  const { track } = useTrackActivity();

  const displayableData = subFundsData.slice(viewedPagesLength - limit, viewedPagesLength)

  const onClickRow = (id: string) => {
    if (selectedIndex === id) {
      close();
    } else {
      setSelectedIndex(id);
      setSub(true);
      const clickedItem = displayableData.find((item) => item['Fund ID'] === id)
      track(ActivityType.CLICK, 'Party Profile Sub Funds', { activity_info: 'Sub Funds Table Row Clicked', sub_fund_clicked: clickedItem!['Fund Name'] })
    }
  };
  useEffect(() => {
    if (accessToken && id && selectedIndex) {
      listFundsDocuments(accessToken, selectedIndex).then((data) => {
        setFundDocuments(data);
      });
      getSubFundById(accessToken, id, selectedIndex).then((data) => {
        setFundData(data);
      });
    }
  }, [accessToken, id, selectedIndex]);

  useEffect(() => {
    if (accessToken && id) {
      setLoading(true)
      getSubFunds(accessToken, id).then((data) => {
        setSubFundsData(getSubFundsByChildID(data, childFundIds));
      }).finally(() => {
      });
    }
  }, [accessToken, id]);

  return (
    <Container>
      {!isSub ?
        displayableData.length ? <FilterableTable data={displayableData} onClickRow={onClickRow} idKey="Fund ID" tableType="FP" filter={filter} /> : null
        :
        <>
          {fundData ? (
            <SubHeader>
              <SubHeaderNav>
                <Title>{fundData?.["Fund Name"]}</Title>
                <Close
                  onClick={close}
                  style={{ cursor: "pointer" }}
                  htmlColor={colors.PRIMARY[400]}
                />
              </SubHeaderNav>
              <SubContent maxHeight="40vh">
                <Funds
                  data={fundData}
                  fundDocuments={fundDocuments!}
                  hideSubFund
                />
              </SubContent>
            </SubHeader>
          ) : (
            <LoadingSpinnerDiv><LoadingSpinner /></LoadingSpinnerDiv>
          )}
        </>
      }
    </Container>
  );
};

export default SubFunds;
