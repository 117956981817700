//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * A series of functions and getters useful for the React Table implementations.
 */

import { ColumnDetails, ColumnHeader, TableConfiguration, HeaderConfiguration } from "../types/filterableTable"
import { tableConfigurations } from '../constants/tableConfigurations';

// given a table name, find (if present) the specific table configuration
const findTableConfiguration = (tableName: string): TableConfiguration | false => {
    const tableConfigurationMatch: TableConfiguration | undefined = tableConfigurations.find(singleTableConfiguration => singleTableConfiguration['name'] === tableName)
    return (tableConfigurationMatch || false)
}

// given a table configuration and an accessor name, find (if present) the specific header configuration for that accessor
const findColumnConfiguration = (tableConfiguration: TableConfiguration, accessorName: string): HeaderConfiguration | false => {
    const columnConfigurationMatch: HeaderConfiguration | undefined = tableConfiguration.columnDetails.find(singleHeaderConfiguration => singleHeaderConfiguration['accessor'] === accessorName)
    return (columnConfigurationMatch || false)
}

// given a table configuration and an accessor name, find - if present - the specific data format configuration for that accessor
const findFormatConfiguration = (tableConfigurationMatch: TableConfiguration | false, accessorName: string): any | false => {

    return (
        tableConfigurationMatch && tableConfigurationMatch.dataFormatConfiguration?.length !== 0
            ? tableConfigurationMatch.dataFormatConfiguration?.find(singleDataConfiguration => singleDataConfiguration['accessorName'] === accessorName)
            : false
    )
}

// return a string-date in the dd/mm/yyyy format
const getDateFormat = (valueToFormat: any): string => {

    // convert into the desidered dd/mm/aaaa format
    const convertToDesideredFormat = (): string => {

        /**
         * Case A: generally the date format coming from banks only is mm/dd/yyy and has got already the slashes '/'.
         * Case B: generally the date format coming not-BO only is yyyymmdd.
         * In both cases we have to change the order to have the dd/mm/aaaa format and in case B we have to add also the slashes.
         */

        const formattedValue:string = String(valueToFormat)

        return (
            formattedValue.includes('/')
                ? formattedValue.substring(3, 6) + formattedValue.substring(0, 2) + formattedValue.substring(5)
                : formattedValue.substring(6) + "/" + formattedValue.substring(4, 6) + "/" + formattedValue.substring(0, 4)
        )
    }

    return (isEmptyString(valueToFormat) ? "00/00/0000" : convertToDesideredFormat())

}

// return a decimal in string format
const getDecimalFormat = (valueToFormat: any, decimalFigures: number): string => {

    const addZerosToDecimalFigures = (decimalPart: string): string => {

        /**
         * If decimalPart is shorter than the decimalFigures coming from the configuration, 
         * add so many zeros to the end as the difference between decimalFigures and the decimalPart length.
         * If the decimalPart is equal or longer than the decimalFigures, return the value (truncating if too long).
         */

        return (
            decimalPart.length < decimalFigures
                ? decimalPart + "0".repeat(decimalFigures - decimalPart.length)
                : (decimalPart.length === decimalFigures && decimalPart) || decimalPart.substring(0, decimalFigures)
        )

    }

    const convertToDesideredFormat = (): string => {

        /** 
         * If there's a decimal point (e.g. "12.453"), add just the necessary zeros to the decimal figures in order to have the same length.
         * If there's no decimal point (e.g. "48"), add as many zeros as the decimalFigures value.
         */

        const indexOfDecimalPoint: number = valueToFormat.indexOf(".")

        const formattedValue:string = String(valueToFormat)

        return indexOfDecimalPoint >= 0
            ? formattedValue.substring(0, indexOfDecimalPoint + 1) + addZerosToDecimalFigures(formattedValue.substring(indexOfDecimalPoint + 1, formattedValue.length))
            : formattedValue + "." + "0".repeat(decimalFigures)

    }

    return (isEmptyString(valueToFormat) ? "-" : convertToDesideredFormat())
}

// remove the commas from some kind of 
const removeCommas = (valueToChange:any):number => {
    return ( isEmptyString(valueToChange) ? 0 : parseInt( (valueToChange.includes(",") && valueToChange.replace(/,/g, '')) || valueToChange )) 
}

// check if it's an empty string or a null/undefined
const isEmptyString = (valueToCheck: any): boolean => {
    return (!valueToCheck || (typeof valueToCheck === 'string' && valueToCheck.trim() === ""))
}


// create a new header
const getNewHeader = (key: string, tableType: string): ColumnHeader => {

    let newHeader = {
        Header: key,
        accessor: key
    }

    const tableConfigurationMatch: TableConfiguration | false = findTableConfiguration(tableType)

    // if there's a configuration for the specific table (name) in object
    if (tableConfigurationMatch) {

        const columnConfigurationMatch: HeaderConfiguration | false = findColumnConfiguration(tableConfigurationMatch, key)

        // if there's a configuration for the specific accessor
        if (columnConfigurationMatch) {

            // let's add the configuration properties
            newHeader = {
                ...newHeader,
                ...columnConfigurationMatch
            }

        }
    }

    return newHeader

}

// extract the headers by analyzing the first row of data
export const getHeadersForTable = (data: ColumnDetails[], tableType: string): ColumnHeader[] => {

    let headersForTable: ColumnHeader[] = []

    // let's take just hte first row of data and extract the key-value pair to get just the key (where key is the header name)
    Object.entries(data[0]).forEach(([key, value]: [string, string]) => {

        // create the new header and add it to the array of headers
        headersForTable.push(getNewHeader(key, tableType))

    })

    return headersForTable

}

// provide - if present - all the necessary additional table option coming from the tableConfiguration
export const getAdditionalTableOptions = (tableType: string): any | false => {

    const tableConfigurationMatch: TableConfiguration | false = findTableConfiguration(tableType)

    return (
        tableConfigurationMatch && tableConfigurationMatch.additionalTableOptions
            ? tableConfigurationMatch.additionalTableOptions
            : false
    )

}

// apply - if present - configuration for data formatting (es. decimal, dates, etc)
export const formatDataForTable = (data: any, tableName: string) => {

    const tableConfigurationMatch: TableConfiguration | false = findTableConfiguration(tableName)

    let formattedData: any[] = []

    data.forEach((rowOfData: any) => {

        let formattedRow: any = {}

        Object.entries(rowOfData).forEach(([key, value]: [string, any]) => {

            const formatConfigurationMatch: any | false = findFormatConfiguration(tableConfigurationMatch, key)

            if (formatConfigurationMatch) {

                switch (formatConfigurationMatch.format) {
                    case "date":
                        value = getDateFormat(value)
                        break
                    case "decimal_2":
                        value = getDecimalFormat(value, 2)
                        break
                    case "decimal_5":
                        value = getDecimalFormat(value, 5)
                        break
                    case "comma_figures":
                        value = removeCommas(value)
                        break
                    default:
                        if (isEmptyString(value)) value = "-"
                }
            }

            formattedRow[key] = value

        })

        formattedData.push(formattedRow)
    });

    return formattedData

}