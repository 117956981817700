import styled from "styled-components";

export const Container = styled.div<{light?:boolean}>`
    display: flex ;
    align-items: center ;
    justify-content: center ;
    height: 100%;
    color: ${({theme}) => theme.colors.DARK[400]};

    h1 {
        font-size: ${({light}) => light && '20px'} ;
        font-family: 'Roboto Condensed';
    }
`