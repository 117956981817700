import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`;


export const TooltipText = styled.span<{askAlice: boolean | undefined, propInModal: boolean | undefined}>`
  visibility: hidden;
  width: 100%;
  min-width: 60px;
  font-size: 11px;
  background-color: ${({ theme }) => theme.colors.GRAY[300]};
  color: ${({ theme }) => theme.colors.GRAY[800]};
  text-align: center;
  padding: 2px 0;
  margin-top: 10px;
  left: ${({askAlice}) => askAlice ? '120%' : '30%'};
  top: ${({askAlice}) => askAlice && '-60%'};
  top: ${({propInModal}) => propInModal && '100%'};
  position: absolute;
  z-index: 1;
`;

export const TooltipWrapperHover = styled(TooltipWrapper)`
  cursor: default;
  &:hover ${TooltipText} {
    visibility: visible;
  }
`;