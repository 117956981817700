import { useEffect, useState } from "react";
import { iFrameSize, emptyIFrameSize } from "../../types";
import { interactiveData } from "../../constants/dido";
import TemporaryLoading from "./TemporaryLoading";
import {
    Container,
    Content,
    TabContent,
    InteractiveCol,
    InteractiveItem,
    ClientAdminItem,
    ClientTitle,
    ModalWrapper,
    CloseModal
} from '../../styles/didoDemo/RightContent.styled';
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";

const clientAdminData = Array(12).fill('').map((_, index) => `${process.env.PUBLIC_URL}/assets/${index + 1}-client-admin.svg`);

export const InteractiveTab = () => {
    const {track} = useTrackActivity();
    useEffectOnce(() => {
        track(ActivityType.LOAD_PAGE, "Dido Demo Interactive")
    }) 
    
    const [showModal, setShowModal] = useState<string>('')
    const [iFrameSize, setIFrameSize] = useState<iFrameSize>(emptyIFrameSize);
    // we add the "%26hide-ui%3D1" string at the bottom for do not display Figma UI dashboard in the embedding
    const handleClick = (prototypeURL: string) => setShowModal(prototypeURL ? prototypeURL + "%26hide-ui%3D1" : '')

    const setResponsiveness = () => {
        setIFrameSize({
            width: "" + (window.innerWidth),
            height: "" + (window.innerHeight)
        })
    };

    useEffect(() => {
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        }
    }, []);


    return (
        <Container>
            <h1>Dido's Demo Room</h1>
            <Content>

                <TabContent tab="interactive-demo">
                    {
                        interactiveData.map(data => {
                            return (
                                <InteractiveCol key={data.iconURL} tab="interactive-demo">
                                    <InteractiveItem iconURL={data.iconURL} onClick={() => handleClick(data.prototypeURL)} />
                                </InteractiveCol>
                            )
                        })
                    }
                </TabContent>
            </Content>
            {showModal !== '' &&
                <ModalWrapper>
                    <CloseModal onClick={() => handleClick('')} />
                    <iframe
                        width={iFrameSize.width}
                        height={iFrameSize.height}
                        src={showModal}
                        allowFullScreen
                        title="dido_demo"
                    />
                    <TemporaryLoading />
                </ModalWrapper>
            }
        </Container>

    )
}

export const ClientAdminTab = () => {
    const {track} = useTrackActivity();
    track(ActivityType.LOAD_PAGE, "Dido Demo Client Admin Room")

    return (
        <Container>
            <h1>Client Admin Room</h1>
            <Content>
                <ClientTitle>Client Folder</ClientTitle>
                <TabContent>
                    {
                        clientAdminData.map(data => {
                            return (
                                <InteractiveCol key={data}>
                                    <ClientAdminItem iconURL={data} />
                                </InteractiveCol>
                            )
                        })
                    }
                </TabContent>
            </Content>
        </Container>
    )
}
