//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

import {ChangeEvent} from "react";
import {SelectBoxDetails, SelectBoxOption, stringStateSet} from "../../../types";
import { CustomSelectBox } from 'styles/alert/airTable/SelectBox.styled';

/**
 * Component that renders a select box with custom options.
 * @param selectBoxDetails: an object that represents the name of the select and the list of options for the user
 * @param stateSetter: once the user selects an option, call the specific state setter
 */
export const SelectBox = (
    {selectBoxDetails, stateSetter}:{selectBoxDetails:SelectBoxDetails, stateSetter: stringStateSet}) => {

    const handleSelectOption = (event:ChangeEvent<HTMLSelectElement>) =>{
        stateSetter(event.target.value)
    }

    return (
        <CustomSelectBox onChange={handleSelectOption} id={selectBoxDetails.selectBoxName}>
            <option value="" hidden={true}>Select a document</option>
            {selectBoxDetails.selectListOfOptions.map((selectBoxOption:SelectBoxOption) => {
                return (
                    <option
                        value={selectBoxOption.optionValue}
                        key={selectBoxOption.optionValue}>
                        {selectBoxOption.optionContent}
                    </option>)
            })}
        </CustomSelectBox>
    )
}