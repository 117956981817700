//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * Using React Table for having a filterable, sortable and paginated table.
 * To know more about React Table see the official doc here: https://react-table.tanstack.com/docs/overview
 */

 import React from 'react';
 import { TableOptions, useTable, usePagination, useSortBy, useFilters, Row, Column, HeaderGroup, Cell } from 'react-table';
 import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
 import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
 import {
     WrapTable,
     TableBasicStyles,
     TableHeaderCell,
     TableHeaderContent,
     TableHeaderTitle,
     TableHeaderRow,
     TableBodyRow,
     TableBodyCell,
     PaginationContainer,
     PaginationControl,
     PaginationControlNext,
     PaginationPage,
     firstPageIcon,
     prevPageIcon,
     firstPageIconWhite,
     prevPageIconWhite
 } from 'styles/FilterableTable.styled';
 import { DefaultColumnFilter } from "./DefaultColumnFilter";
 import { formatDataForTable, getAdditionalTableOptions, getHeadersForTable } from 'utils/sharedFunctionsForTables';
 import {useAppData} from "../../hooks/useAppData";
import AliceModalTooltip from 'components/AliceModalTooltip';

 const Table = ({ columns, data, tableType, filter, idKey, onClickRow }: { columns: Column[], data: string[] | number[], tableType:string, filter: string; idKey?:string; onClickRow?:(arg:string) => void }) => {

     const defaultColumn = React.useMemo(
         () => ({
             // Let's set up our default Filter UI
             Filter: DefaultColumnFilter
         }),
         []
     );
 
     // const columnFilter = columns.filter(c => !c.disableFilters);
     const columnFilter = React.useMemo(() => columns.filter(c => !c.disableFilters), [columns]);
 
     const _data = React.useMemo(() => {
         if (filter === '') {
             return data
         }
         let res: any[] = [];
         data.forEach((d: any) => {
             if (columnFilter.some(c => (c.accessor && d[c.accessor?.toString()] || '').toString().toLowerCase().includes(filter.toLowerCase()))) {
                 res.push(d);
             }
         })
         return res;
     }, [data, columnFilter, filter]);
 
     // here we define the basic TableOptions for React Table
     let tableOptions:TableOptions<{}> = {
         columns,
         data: _data,
         disableSortRemove: true, // un-sorted state will not be available to columns once they have been sorted
         defaultColumn,  // this is for filtering
     }
 
     // let's see if we have more additional table options coming from the table configurations
     const additionalTableOptions:any|false = getAdditionalTableOptions(tableType)
 
     if (additionalTableOptions){
         tableOptions = {
             ...tableOptions,
             ...additionalTableOptions
         }
     }
 
     // Use the state and functions returned from useTable to build your UI
     const {
         getTableProps,
         getTableBodyProps,
         headerGroups,
         prepareRow,
         page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
         canPreviousPage,
         canNextPage,
         pageCount,
         gotoPage,
         nextPage,
         previousPage,
         state: { pageIndex },
     } = useTable(
         tableOptions,
         useFilters, // Important! Place the useFilters before useSortBy and usePagination hooks!
         useSortBy,
         usePagination,
     )

     const { status } = useAppData()

     // Render the UI for your table
     return (
         <>
             <WrapTable>
                 <TableBasicStyles {...getTableProps()}>
                     <thead>
                         {headerGroups.map((headerGroup: HeaderGroup<object>) => (
                             <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                                 {headerGroup.headers.map((column: HeaderGroup<object>, i: number) => (
                                     // Add the sorting props to control sorting. For this example
                                     // we can add them into the header props
                                     <TableHeaderCell key={i}>
 
                                         <TableHeaderContent {...column.getHeaderProps(column.getSortByToggleProps())}>
                                             <TableHeaderTitle>{column.render('Header')}</TableHeaderTitle>
                                             {/* Add a sort direction indicator */}
                                             <span>
                                                 {column.canSort &&
                                                     column.isSorted
                                                     ? (column.isSortedDesc && <ArrowDropDownIcon style={{ color: status==='dark'?'rgb(186, 192, 222)':"#364073" }} />) || (!column.isSortedDesc && <ArrowDropUpIcon style={{ color: status==='dark'?'rgb(186, 192, 222)':"#364073" }} />)
                                                     : <ArrowDropDownIcon style={{ color: status==='dark'?'rgb(186, 192, 222)':"#364073" }} />
                                                 }
                                             </span>
                                         </TableHeaderContent>
 
                                     </TableHeaderCell>
                                 ))}
                             </TableHeaderRow>
                         ))}
                     </thead>
                     <tbody {...getTableBodyProps()}>
                         {page.map((row: Row<object>) => {
                             prepareRow(row)
                             return (
                                 <TableBodyRow {...row.getRowProps()}>
                                     {row.cells.map((cell:Cell,i) => {

                                         /**
                                          * If it's a number and is equal to zero, return a dash.
                                          * If it's a number and is not equal to zero, then add the comma for the thousands figures.
                                          * If it's not a number, just render the cell.
                                          */

                                         return (
                                             <TableBodyCell {...cell.getCellProps()} onClick={() => onClickRow?.((cell.row.values)?.[idKey!])}>
                                                {
                                                    cell.value && (
                                                        <AliceModalTooltip selectedField={cell.value} selectedKey={headerGroups?.[0].headers?.[i]?.Header as string} isSmall>
                                                     {
                                                         (typeof cell.value === 'number' && cell.value === 0 && "-") ||
                                                         (typeof cell.value === 'number' && cell.value !== 0
                                                             ? cell.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")   // add the comma with regex
                                                             : cell.render('Cell'))
                                                             
                                                     }
                                                 </AliceModalTooltip>
                                                    )
                                                }
                                                 
                                             </TableBodyCell>
                                         )
                                     })}
                                 </TableBodyRow>
                             )
                         })}
                     </tbody>
                 </TableBasicStyles>
             </WrapTable>
             {
                 pageCount > 1 && <PaginationContainer>
                     <PaginationControl onClick={() => canPreviousPage && gotoPage(0)}>
                         <img src={status==='dark'?firstPageIconWhite:firstPageIcon} alt='first-page' />
                     </PaginationControl>
                     <PaginationControl onClick={() => canPreviousPage && previousPage()}>
                         <img src={status==='dark'?prevPageIconWhite:prevPageIcon} alt='prev-page'></img>
                     </PaginationControl>
                     {Array.from(Array(pageCount).keys()).map(page =>
                         <PaginationPage key={'pagination_' + page} active={pageIndex === page} onClick={() => gotoPage(page)}>{page + 1}</PaginationPage>
                     )}
                     <PaginationControlNext onClick={() => canNextPage && nextPage()}>
                         <img src={status==='dark'?prevPageIconWhite:prevPageIcon} alt='prev-page'></img>
                     </PaginationControlNext>
                     <PaginationControlNext onClick={() => canNextPage && gotoPage(pageCount - 1)}>
                         <img src={status==='dark'?firstPageIconWhite:firstPageIcon} alt='first-page'></img>
                     </PaginationControlNext>
                 </PaginationContainer>
             }
         </>
     )
 }
 
 // main function
 export const FilterableTable = ({ data, tableType, filter, onClickRow, idKey }: { data: any, tableType:string, filter: string; idKey?:string; onClickRow?:(arg:string) => void }) => {
 
     // React Table requires columns and data memoisation for avoiding too many table re-rendering.
 
     // define the columns (and the respective headers) and memoise it (React Table want)
     const columns = React.useMemo(() => getHeadersForTable(data, tableType),[])
     // memoise the data
     const dataForTable = React.useMemo<string[] | number[]>(() => formatDataForTable(data, tableType), []);
     return <Table columns={columns} data={dataForTable} tableType={tableType} filter={filter} onClickRow={onClickRow} idKey={idKey} />;
 }
 