import { ManagedFundsTableContent } from "components/tableContent/ManagedFundsTableContent";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAllFunds } from "services/funds";
import { getTableType } from "utils/party";
import LoadingSpinner from "components/LoadingSpinner";
import NoData from "components/noData/NoData";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";
import { PartyDetails } from "types";
import { ContactInformation } from "types/party";

export function ManagedFunds({
  accordionName,
  token,
  data,
  partyId
}: {
  accordionName: string;
  token?: string;
  data: PartyDetails;
  partyId: string;
}) {
  const { id } = useParams<{ id: string }>();
  const [fundsData, setFundsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const {track} = useTrackActivity()

  useEffectOnce(() => {
    track(
      ActivityType.LOAD_PARTY_PROFILE,
      'Party Profile Funds',
      {
        party_name: (data['Contact Information'] as ContactInformation).Name,
        party_id: partyId
      }
    )
  })

  useEffect(() => {
    if (token && id) {
      setLoading(true);
      getAllFunds(token, id)
        .then((data) => {
          setFundsData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, id]);
  return (
    <>
      {loading ? (
        <LoadingSpinnerDiv>
          <LoadingSpinner />
        </LoadingSpinnerDiv>
      ) : !fundsData || fundsData?.length === 0 ? (
        <NoData />
      ) : (
        <ManagedFundsTableContent
          data={fundsData}
          name="name"
          tableType={getTableType(accordionName)}
        />
      )}
    </>
  );
}
