import IframeDivBasic from "../../components/ecp/EcpBasic";

const Ecp = () => {

    return(
        <>
            <div className="Home">
                <IframeDivBasic />
            </div>
        </>

    )
}
export default Ecp;