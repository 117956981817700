import React from 'react';
import {
  TooltipText,
  TooltipWrapperHover
} from 'styles/ToolTip.styled';
import {TooltipProps} from 'types'

function Tooltip({ content, askAlice, propInModal, children }: React.PropsWithChildren<TooltipProps>) {
    return (
        <TooltipWrapperHover>
            {children}
            <TooltipText askAlice={askAlice} propInModal={propInModal}>{content}</TooltipText>
        </TooltipWrapperHover>
    )
}

export default Tooltip;