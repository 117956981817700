import {generateRandomString} from "../../../utils/randomStringGenerator";
import {AirTableSelectedDocument} from "../../../types";
import { DocumentContainer } from 'styles/alert/airTable/SelectedDocumentsList.styled';

const SelectedDocumentsList = ({selectedDocuments}:{selectedDocuments:AirTableSelectedDocument[]}) => {
    return (
        <div>
            <div><b>You selected the following documents:</b></div>
            {selectedDocuments.map( (selectedDocument:AirTableSelectedDocument) => {
                return (
                    <DocumentContainer key={generateRandomString(10)}>
                        <p><b>{selectedDocument.typeOfDocument}</b></p>
                        <p>{ JSON.stringify(selectedDocument.documentData)}</p>
                    </DocumentContainer>
                )
            })}
        </div>
    )
}
export default SelectedDocumentsList;