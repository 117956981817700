import _ from "lodash";
import {FinancialGroupItem, ObjectType, Result, TotalAssetsWithClosingDate} from "../types";

/*
 * TODO VERY IMPORTANT
 *      to remove hardcoded filtering
 *      use json rules instead
 */
export function setResult(
    financialGroupItem:FinancialGroupItem,
    financialGroupGuoId:string,
    targetEntityId: string
    ): Result {

    const rawEntityData:ObjectType = financialGroupItem.previewData
    const currentFinancialGroupItemId:string = financialGroupItem.entityId

    /**
     * In the following lines we are using a general - but still to be verified - rule/check:
     * 1) first of all check if we have Banks_only data (BO) about that specific entity
     * 2) if not, get the data from Not Banks_only data (NO_BO)
     */

    let nameMap = rawEntityData.hasOwnProperty("Banks_only/BvD_ID_and_Name.txt")
        ? new Map(Object.entries(rawEntityData["Banks_only/BvD_ID_and_Name.txt"]))
        : rawEntityData.hasOwnProperty("Name") && new Map(Object.entries(rawEntityData["Name"])) || undefined

    let overviewsMap = rawEntityData.hasOwnProperty("Banks_only/Overviews.txt")
        ? new Map(Object.entries(rawEntityData["Banks_only/Overviews.txt"]))
        : rawEntityData.hasOwnProperty("Overviews.txt") && new Map(Object.entries(rawEntityData["Overviews.txt"])) || undefined

    let legal_infoMap = rawEntityData.hasOwnProperty("Banks_only/Legal_info.txt")
        ? new Map(Object.entries(rawEntityData["Banks_only/Legal_info.txt"]))
        : rawEntityData.hasOwnProperty("Legal_info.txt") && new Map(Object.entries(rawEntityData["Legal_info.txt"])) || undefined

    let contact_infoMap = rawEntityData.hasOwnProperty("Banks_only/Contact_info.txt")
        ? new Map(Object.entries(rawEntityData["Banks_only/Contact_info.txt"]))
        : rawEntityData.hasOwnProperty("Contact_info.txt") && new Map(Object.entries(rawEntityData["Contact_info.txt"])) || undefined

    let identifiersArray = rawEntityData.hasOwnProperty("Banks_only/Identifiers.txt") && Object?.entries(rawEntityData["Banks_only/Identifiers.txt"])
        || rawEntityData.hasOwnProperty("Identifiers.txt") && Object?.entries(rawEntityData["Identifiers.txt"])

    // EntitiesWithArchive is not in BO folder, so we do not need to do the check
    let entitiesMap = rawEntityData.hasOwnProperty("EntitiesWithArchive.txt") ? new Map(Object.entries(rawEntityData["EntitiesWithArchive.txt"])) : undefined


    let identifiersMapsArray: Map<string, any>[] = []
    identifiersArray && identifiersArray?.forEach((row: any) => {
        identifiersMapsArray.push(new Map(Object.entries(row[1])))
    })

    let identifiers: Array<Array<string>> = []
    identifiersMapsArray.forEach(row => {
        identifiers.push([row?.get("National ID label"), row?.get("National ID number")])
    })

    /*
    * Now we have to extract the U1 totalAssets (if possible) or the highest totalAssets value from 3 different files (coming from BO and NO_BO)
    */

    let dataForFindTotalAssets: any[] = []

    rawEntityData.hasOwnProperty("Banks_only/Key_financials-USD.txt") && dataForFindTotalAssets.push(new Map(getEntries(rawEntityData["Banks_only/Key_financials-USD.txt"])))
    rawEntityData.hasOwnProperty("Key_financials-USD.txt") && dataForFindTotalAssets.push(new Map(getEntries(rawEntityData["Key_financials-USD.txt"])))
    rawEntityData.hasOwnProperty("Banks_only/Industry-Global_financials_and_ratios-USD.txt") && dataForFindTotalAssets.push(new Map(getEntries(rawEntityData["Banks_only/Industry-Global_financials_and_ratios-USD.txt"])))
    rawEntityData.hasOwnProperty("Industry-Global_financials_and_ratios-USD.txt") && dataForFindTotalAssets.push(new Map(getEntries(rawEntityData["Industry-Global_financials_and_ratios-USD.txt"])))
    rawEntityData.hasOwnProperty("Banks_only/Insurances-Global_financials_and_ratios-USD.txt") && dataForFindTotalAssets.push(new Map(getEntries(rawEntityData["Banks_only/Insurances-Global_financials_and_ratios-USD.txt"])))
    rawEntityData.hasOwnProperty("Insurances-Global_financials_and_ratios-USD.txt") && dataForFindTotalAssets.push(new Map(getEntries(rawEntityData["Insurances-Global_financials_and_ratios-USD.txt"])))

    let category = (legal_infoMap ? legal_infoMap.get("Category of the company") : undefined) as string
    let country = (contact_infoMap ? contact_infoMap.get("Country") : undefined) as string
    let description = (overviewsMap ? overviewsMap.get("Full overview") : undefined) as string;
    let history = (overviewsMap ? overviewsMap.get("History") : undefined) as string;
    let entityType = (legal_infoMap ? legal_infoMap.get("Type of entity") : undefined) as string;
    let entityTypeInternal = (entitiesMap ? entitiesMap.get("Entity type") : undefined) as string;
    let internationalName = (contact_infoMap
        ? contact_infoMap.get("NAME_INTERNAT")
        : nameMap?.get("NAME") ? nameMap?.get("NAME") : undefined) as string;
    let entitiesWithArchiveName = (entitiesMap ? entitiesMap.get("Name") : undefined) as string;
    let iso = (entitiesMap ? entitiesMap.get("Country ISO code") : undefined) as string;
    let mainActivity = (overviewsMap ? overviewsMap.get("Main activity") : undefined) as string;

    return ({
        category: category,
        country: country,
        description: description,
        history: history,
        entityType: entityType,
        entityTypeInternal: entityTypeInternal,
        identifiers: identifiers,
        name: internationalName ? internationalName : entitiesWithArchiveName,
        iso: iso,
        mainActivity: mainActivity,
        id: currentFinancialGroupItemId,
        isGuo: currentFinancialGroupItemId === financialGroupGuoId,
        isTargetEntity: currentFinancialGroupItemId === targetEntityId,
        totalAssets: getTotalAssets(dataForFindTotalAssets)
    })

}

// we use this utility function to have all data as Object.entries (same 'format')
const getEntries = (entityData: any): [string, unknown][] => {
    if (Array.isArray(entityData)) {
        return Object.entries(entityData)
    } else {
        // so if it's not an array, create a 1-element array
        let tmpArray: any[] = []
        tmpArray.push(entityData)
        return Object.entries(tmpArray)
    }
}

// function for retrieving the totalAssets (necessary for ordering search results) from the Party Details
const getTotalAssets = (mapData: Map<string, any>[]): number => {

    if (mapData.length === 0) {
        return 0
    } else {

        let highestU1TotalAssets: TotalAssetsWithClosingDate = {
            totalAssetsValue: 0,
            closingDate: new Date(Date.UTC(1970, 0, 1))
        }
        let highestOtherTotalAssets: TotalAssetsWithClosingDate = {
            totalAssetsValue: 0,
            closingDate: new Date(Date.UTC(1970, 0, 1))
        }

        mapData.forEach(mapItem => {
            mapItem.forEach(mapEntry => {


                // first of all check the U1 consolidation code values
                if (mapEntry["Consolidation code"] && mapEntry["Consolidation code"] === "U1") {
                    highestU1TotalAssets = getTotalAssetsWithClosingDate(mapEntry["Closing date"], mapEntry["Total assets"], highestU1TotalAssets)

                    // if we do not have any U1 consolidation codes to consider, let's find out the most recent totalAssets info across all the other consolidation codes
                } else if (mapEntry["Consolidation code"] && mapEntry["Consolidation code"] !== "U1") {
                    highestOtherTotalAssets = getTotalAssetsWithClosingDate(mapEntry["Closing date"], mapEntry["Total assets"], highestOtherTotalAssets)
                }

            })
        })

        return highestU1TotalAssets.totalAssetsValue > 0 ? highestU1TotalAssets.totalAssetsValue : highestOtherTotalAssets.totalAssetsValue
    }
}

const getTotalAssetsWithClosingDate = (mapEntryClosingDate: any, mapEntryTotalAssets: any, highestTotalAssets: TotalAssetsWithClosingDate): TotalAssetsWithClosingDate => {

    // check if we have a closing date
    if (mapEntryClosingDate && mapEntryClosingDate.trim() !== "") {

        /*
        * So far we noticed that PartyDetails has 2 kind of date format strings:
        * 1) mm/dd/yyyy
        * 2) yyyymmdd
        * It means that we need to convert the formats into a UTC date format (year, month - 1, day)
        */

        let tmpClosingDate: Date = mapEntryClosingDate.includes("/")
            ? new Date(Date.UTC(parseInt(mapEntryClosingDate.substring(6, 10)), parseInt(mapEntryClosingDate.substring(0, 2)) - 1, parseInt(mapEntryClosingDate.substring(3, 5))))
            : new Date(Date.UTC(parseInt(mapEntryClosingDate.substring(0, 4)), parseInt(mapEntryClosingDate.substring(4, 6)) - 1, parseInt(mapEntryClosingDate.substring(6, 8))))

        // ensure that we have total assets data
        if (mapEntryTotalAssets && mapEntryTotalAssets.trim() !== "") {

            // try to take the most recent one
            if (tmpClosingDate.getTime() > highestTotalAssets.closingDate.getTime()) {
                highestTotalAssets.totalAssetsValue = parseInt(mapEntryTotalAssets.replace(/,/g, ""))
                highestTotalAssets.closingDate = tmpClosingDate
            } else if (tmpClosingDate.getTime() === highestTotalAssets.closingDate.getTime() && parseInt(mapEntryTotalAssets) > highestTotalAssets.totalAssetsValue) {
                // and if they have the same date, consider the one with highest totalAssets
                highestTotalAssets.totalAssetsValue = parseInt(mapEntryTotalAssets.replace(/,/g, ""))
            }
        }

    }

    return highestTotalAssets
}

// utility function that tells us if the result has empty values by comparing it with an 'empty object'
export const isResultEmpty = (result: Result): boolean => {

    const emptyResultObject: Result = {
        id: result.id,
        mainActivity: undefined,
        category: undefined,
        identifiers: [],
        description: undefined,
        history: undefined,
        name: undefined,
        iso: undefined,
        country: undefined,
        entityType: undefined,
        entityTypeInternal: undefined,
        isGuo: result.isGuo,
        isTargetEntity: result.isTargetEntity,
        totalAssets: result.totalAssets
    }

    // use lodash to determine if the the two objects are the same (stringify comparison is not strong enough)
    return _.isEqual(result, emptyResultObject)
}
