import React, { useEffect } from "react";
import { Title } from "./Title";
import { AccordionProps } from "types";
import {
  Container,
  Content
} from "styles/party/accordion/Accordion.styled";
import { ActivityType, useTrackActivity } from "hooks/useTracker";

export function Accordion({
  title,
  children,
  opened,
  dep,
  wrapper,
  noTopBorder,
  borderBottom,
  noPaddingTop,
  light,
  small
}: AccordionProps & {noTopBorder?: boolean; borderBottom?: boolean; light?:boolean; noPaddingTop?: boolean; small?:boolean}) {
  const [isOpen, setOpen] = React.useState(false);
  const AccordionPrintWrapper = wrapper;
  const {track} = useTrackActivity();

  useEffect(() => {
    setOpen(opened!)
  },[opened, dep])

  const toggle = () => {
    setOpen(!isOpen);

    if(isOpen) {
      track(ActivityType.ACCORDION_OPEN, 'Party Profile', {opened_accordion: `${title} Accordion closed`})
    } else {
      track(ActivityType.ACCORDION_OPEN, 'Party Profile', {opened_accordion: `${title} Accordion opened`})
    }
  }

  return (
    <Container noTopBorder={noTopBorder} borderBottom={borderBottom} >
      <Title light={light} text={title} toggle={toggle} isOpen={isOpen} small={small}/>
      {isOpen ? (
        <Content noPaddingTop={noPaddingTop}>
          {AccordionPrintWrapper
            ? <AccordionPrintWrapper>{children}</AccordionPrintWrapper>
            : children
          }
        </Content>
      ) : null}
    </Container>
  );
}
