import styled from 'styled-components';

export const Wrapper = styled.div<{num: number}>`
    height: 164px;
    background-color: ${({num}) => {
        switch (num) {
            case 1:
                return '#2E1A61';
            case 2:
                return '#3F24F7';
            case 3:
                return '#048A42';
            case 4:
                return '#3F24F7';
        }
    }};
    border-radius: 12px;
  @media (max-width: 768px) {
    width: 300px;
  }
`;
export const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 23px 0 25px 18px;
`;
export const TitleIcon = styled.img`
   
`;
export const TitleText = styled.div`
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 14px;
    line-height: 125.3%;
    color: white;
    letter-spacing: 0.03em;
`;
export const IconWrapper = styled.div`
    width: 48px;
    height: 48px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const Content = styled.table`
    border-collapse: collapse;
    width: 70%;
    margin-left: 18px;
    table-layout: fixed;
`;
export const TBody = styled.tbody``;
export const Field = styled.tr``;
export const Value = styled.tr``;
export const FieldItem = styled.th`
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    font-family: 'Roboto Condensed';
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
`;
export const ValueItem = styled.th`
    text-align: left;
    font-family: 'Roboto Condensed';
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: white;
`;

export const Icon1 = `${process.env.PUBLIC_URL}/assets/comment-icon1.svg`;
export const Icon2 = `${process.env.PUBLIC_URL}/assets/comment-icon2.svg`;
export const Icon3 = `${process.env.PUBLIC_URL}/assets/comment-icon3.svg`;