import {useAppData} from "../../hooks/useAppData";
import {COLOR_MODE} from "../../constants/index";
import ProgressBar from "components/atoms/ProgressBar";
import { SettingsContainer, Section, ProgressCover, Title, Option, CheckBox, ColorOptions } from "styles/SettingsMenu.styled";
import { ActivityType, useTrackActivity } from "hooks/useTracker";


const SettingsMenu = ({ display }: { display: string }) => {
    const { setStatus, status } = useAppData()
    const {track} = useTrackActivity();

    const handleThemeChange = (color: COLOR_MODE) => {
        setStatus(color)
        localStorage.setItem('status', JSON.stringify(color))
    }

    return (
        <SettingsContainer display={display}>
            <Section>
                <Title>Brightness</Title>
                <ProgressCover>
                    <ProgressBar />
                </ProgressCover>
            </Section>
            <Section>
                <Title>Colour</Title>
                <ColorOptions>
                    <Option style={{marginBottom: '24px'}}>
                        <CheckBox onClick={() => {
                           handleThemeChange(COLOR_MODE.LIGHT)
                           track(ActivityType.BUTTON_CLICK, "Header", {button_clicked: COLOR_MODE.LIGHT}) 
                        }} 
                        checked={status===COLOR_MODE.LIGHT}
                        />
                        <Title>Bright</Title>
                    </Option>
                    <Option>
                        <CheckBox onClick={() => {
                            handleThemeChange(COLOR_MODE.DARK)
                            track(ActivityType.BUTTON_CLICK, "Header", {button_clicked: COLOR_MODE.DARK}) 
                        } 
                        } 
                        checked={status===COLOR_MODE.DARK}
                        />
                        <Title>Dim</Title>
                    </Option>
                </ColorOptions>
            </Section>
        </SettingsContainer>
    )
}
export default SettingsMenu;