import DATA from 'data/SEPA.json';
import { FC,} from "react";
import { getTableType } from "utils/party";
import { TableContent } from "components/tableContent/TableContent";
import { NoDataToDisplay } from "components/noData/ComingSoon";

const SEPA: FC<{ accordionName: string, data:typeof DATA }> = ({ accordionName, data }) => {

    if (!data) return <NoDataToDisplay />
    return (
        <TableContent
            data={data?.[1]}
            name={data?.[0]!}
            tableType={getTableType(accordionName)}
        />
    )
}

export default SEPA;