import styled from "styled-components";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";

export const t360LogoPurple = `${process.env.PUBLIC_URL}/assets/t360-header-logo-purple.svg`;
export const signoutIcon = `${process.env.PUBLIC_URL}/assets/signout-icon.svg`;

export const StyledCloseIcon = styled(CloseIcon)`
  font-size: 30px !important ;
`;

export const Logo = styled.div<{ iconURL: string }>`
  width: 28px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  background-image: ${(props) => `url(${props.iconURL})`};
  margin: 0 14px 0 0;
`;

export const Header = styled.div`
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.DARK[500]};
`;

export const Container = styled.div<{ show?: boolean }>`
  padding: 32px 15px;
  position: fixed;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.WHITE};
  z-index: 10;
  top: 0;
  left: ${({ show }) => (show ? 0 : "-100%")};
  width: 70%;
  transition: all 0.6s ease-in-out;
  box-shadow: ${({ theme }) => `1px 1px 1px ${theme.colors.GRAY[50]}`};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MenuContainer = styled.div`
  margin-bottom: 242px;
`;

export const MenuLink = styled(Link)`
  text-decoration: none;
`;

export const MenuItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.PRIMARY[400] : "#344054"};
  background: ${({ isActive }) => (isActive ? "#F6F4F9" : "transparent")};
  height: 25px;
  padding: 8px 12px;
  margin: 10px 0;
  border-radius: 6px;
  cursor: pointer;
  width: 248px;
`;

export const MenuItemIcon = styled.div<{
  iconURL: string;
  isActive?: boolean;
  path?: string;
}>`
  width: 22px;
  height: 22px;
  mask: ${(props) => `url(${props.iconURL})`} no-repeat center;
  background-size: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: ${({ path, theme, isActive }) =>
    path === "signout"
      ? theme.colors.ACCENT[700]
      : isActive
      ? theme.colors.PRIMARY[400]
      : "#344054"};
`;

export const MenuItemText = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding-left: 8px;
  font-weight: 500;
`;
