import SideBar from "../../components/didoDemo/SideBar";
import { Navigate, Outlet} from "react-router-dom";
import { Container, Content, Nav, OutletCover } from "../../styles/admin/index.styled";
import Navbar from "../../components/admin/Navbar";
import { useAppData } from "hooks/useAppData";
import { PATH } from "constants/index";

const AdminRoom = () => {
  const {isDidoDemo} = useAppData();

  if(!isDidoDemo) return <Navigate to={PATH.INDEX} />
  return (
    <Container>
      <SideBar />
      <Content>
        {/* nav */}
        <Nav>
          <Navbar />
        </Nav>

        {/* outlet  */}
        <OutletCover>
          <Outlet />
        </OutletCover>
      </Content>
    </Container>
  );
};

export default AdminRoom;
