//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the initial Default Search Page of Party Manager.
 */
import {
    SearchMenu,
    SearchContainer,
    DropDownContainer,
    DropDownHeader,
    DropDownListContainer,
    DropDownList,
    ListItem,
    SearchWrapper,
} from '../../styles/Search.styled';
 import {useState} from 'react'
 import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import {SEARCH, FUNDS_SEARCH_OPTIONS, DISABLED_OPTIONS, DISABLED_FUNDS_SEARCH_OPTIONS} from 'constants/search'
import { ActivityType, useTrackActivity } from 'hooks/useTracker';
import useEffectOnce from 'hooks/useEffectOnce';
import BasicSearch from 'components/funds/search/BasicSearch';

 export const Search = () => {
     const {track} = useTrackActivity()
     const [isOpen, setIsOpen] = useState(false);
     const [selectedOption, setSelectedOption] = useState("Full Search");

     const toggling = () => setIsOpen(!isOpen);


     useEffectOnce(() => {
       track(ActivityType.LOAD_PAGE, "Search")
     })

     const onOptionClicked = (value: string) => () => {
        if(DISABLED_FUNDS_SEARCH_OPTIONS.includes(value)){
            return null;
        } else{
            setSelectedOption(value);
            setIsOpen(false);
        }
        
        };

     return (
         <SearchWrapper className='container'>
             <SearchMenu>
                <DropDownContainer>
                    <DropDownHeader onClick={toggling}> {selectedOption || "Select"}<div><KeyboardArrowDownOutlinedIcon/></div></DropDownHeader>
                    {isOpen && ( 
                        <DropDownListContainer>
                            <DropDownList>
                            {FUNDS_SEARCH_OPTIONS.map(option => (
                                <ListItem disabled={DISABLED_FUNDS_SEARCH_OPTIONS.includes(option)} onClick={onOptionClicked(option)} key={Math.random()}>
                                    {option}
                                </ListItem>
                            ))}
                            </DropDownList>
                        </DropDownListContainer> 
                    )}
                </DropDownContainer>
             </SearchMenu>

             <SearchContainer>
                 {selectedOption === SEARCH.FULL_SEARCH && <BasicSearch />}
             </SearchContainer>
         </SearchWrapper>
     )
 }
 
 export default Search
 