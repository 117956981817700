//Copyright Marco Rapaccini, Marcello Di Fronzo Gravallese and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for each Key People's person (one person = one tile)
 */

import { Person, PersonPositionDepartment, SetModal } from 'types/keyPeopleModal'
import {
    NameDiv,
    TileAndModal,
    TileContainer,
    TilePersonDetailsContainer,
    TilePersonIcon,
    TilePersonIconContainer,
    TilePersonName,
    TilePersonTitle
} from 'styles/party/keyPeople/TilePerson.styled' ;
import { Modal } from './modal/Modal';
import AliceModalTooltip from 'components/AliceModalTooltip';



 export function TilePerson({personPosDep, modalUCI, setModalUCI, peopleList}: {personPosDep: PersonPositionDepartment, modalUCI?: string | string[], setModalUCI:SetModal, peopleList:Person[]}) {
    //single key people card component
     return (
         <TileAndModal>
             <TileContainer onClick={()=> setModalUCI(personPosDep.uci && typeof personPosDep.uci === 'string' ? personPosDep.uci : "")}>
                 <TilePersonIconContainer>
                     <TilePersonIcon/>
                 </TilePersonIconContainer>
                 <TilePersonDetailsContainer>
                     <TilePersonName>
                         {personPosDep.fullName &&
                             <AliceModalTooltip isCard selectedField={personPosDep.fullName.toString()} selectedKey='name'>
                                 <NameDiv>{personPosDep.fullName}</NameDiv>
                             </AliceModalTooltip>
                         }
                     </TilePersonName>
                     {personPosDep.position && <TilePersonTitle>{personPosDep.position}</TilePersonTitle>}
                 </TilePersonDetailsContainer>
             </TileContainer>
             {
                 /*
                  * Show the modal only if the modalUCI is not undefined and is a string that holds a value.
                  * Then pass to the Modal component the Person who has the same UCI setted by modalUCI.
                 */
             }
             {modalUCI && typeof modalUCI === 'string' && modalUCI.trim()!=="" && personPosDep.uci === modalUCI
             && <Modal
                 person={peopleList.find((element:Person) => element.uci === modalUCI)}
                 setModalUCI={setModalUCI}
                 isLeft
                 modalUCI={modalUCI}
                 />
             }
         </TileAndModal>
     )
 
 }