import AliceRequestComponent from "../../components/admin/AliceRequest"
import data from 'data/adminAliceRequests.json'
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";

const AliceRequests = () => {
  const {track} = useTrackActivity()
  
  useEffectOnce(() => {
    track(ActivityType.LOAD_PAGE, "Alice Request")
  })
  return <AliceRequestComponent data={data} />
};

export default AliceRequests;
