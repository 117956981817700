import { DASHBOARD_BAR_TITLE } from "constants/index";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const StyledBar = styled.div<{percent:number, title: string, status: string, bgColor?:string}>`
  background: ${({title, theme, bgColor}) => {
      switch (title) {
          case DASHBOARD_BAR_TITLE.BANKS:
              return theme.colors.LINEAR_GRADIENT[400]
        
          case DASHBOARD_BAR_TITLE.FINANCIAL_INST:
              return theme.colors.LINEAR_GRADIENT[100]

          case DASHBOARD_BAR_TITLE.GOV_RELATED_INST:
              return theme.colors.LINEAR_GRADIENT[300]
      
          default:
              return bgColor
      }
  }};
  filter: brightness(1.4);
  border-radius: 12px;
  width: ${({percent}) => `${percent !== undefined ? percent : 100}%`};
  height: 26px;
  display: flex;
  align-items: center;
  padding-left: ${({percent}) => percent === 0 ? 0 : '12px'};
  margin-right: 8px;
`;

export const Text = styled.p<{ white?: boolean, isGrey?:boolean }>`
  font-size: 10px;
  color: ${({ theme, white, isGrey }) => isGrey ? theme.colors.DARK[500] : (white && theme.colors.WHITE)};
`;

export const Cover = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`