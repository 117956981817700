export enum SEARCH_CLASS_TYPE {
    DEFAULT = "is-default",
    PRIMARY = "is-primary",
}

export const DATE_RANGES: string[] = ['Today', 'Previous 7 Days', 'Previous 30 Days', 'Default']
export enum DATE_RANGE {
    TODAY = 'Today',
    SEVEN_DAYS = 'Previous 7 Days',
    THIRTY_DAYS = 'Previous 30 Days',
    DEFAULT = 'Default'
}

export const FILTER_STATUS: string[] = ['Active', 'Closed', 'Unread', 'Default']
export enum STATUS {
    ACTIVE = 'Active',
    CLOSED = 'Closed',
    UNREAD = 'Unread',
    DEFAULT = 'Default'
}


export enum FILTER_BY  {
    STATUS = "status",
    DATE = "date",
    SEARCH = "search"
}
