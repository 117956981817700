import styled from "styled-components";

export const Container = styled.div<{ tab?: string }>`
    h1 {
      color: ${({theme}) => theme.colors.DARK[500]};
      margin-top: 0;
    }
`

export const Content = styled.div<{ tab?: string }>`
    background-color: ${({theme}) => `${theme.colors.PRIMARY[25]}` };
    border-radius: 20px ;
    padding: 20px 0;
`

export const TabContent = styled.div<{ tab?: string }>`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @media only screen and (min-width: 600px) {
      /* For tablets and desktop: */
      & { flex-direction: row; }
    justify-content: center;
`
export const InteractiveCol = styled.div<{ tab?: string }>`
    /* For mobile phones: */
    flex-basis: ${props => props.tab === 'interactive-demo' ? '177' : '130'}px;
    height: ${props => props.tab === 'interactive-demo' ? '177' : '130'}px;
    padding: 0 ${props => props.tab === 'interactive-demo' ? '15' : '27'}px;
    margin-top: 10px;
    margin-bottom: 10px ;

    @media only screen and (min-width: 600px) {
      /* For tablets: */
      & {
        flex-basis: ${props => props.tab === 'interactive-demo' ? '187' : '140'}px;
        height: ${props => props.tab === 'interactive-demo' ? '187' : '140'}px;
        padding: 0 ${props => props.tab === 'interactive-demo' ? '25' : '37'}px;
        margin-top: 15px;
        margin-bottom: 15px ;
      }

    @media only screen and (min-width: 768px) {
      /* For desktop: */
      & {
        flex-basis: ${props => props.tab === 'interactive-demo' ? '197' : '150'}px;
        height: ${props => props.tab === 'interactive-demo' ? '197' : '150'}px;
        padding: 0 ${props => props.tab === 'interactive-demo' ? '35' : '47'}px;
        margin-top: 20px;
        margin-bottom: 20px ;
      }
    }
`

export const InteractiveItem = styled.div<{ iconURL: string }>`
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px ${({theme}) => `${theme.colors.BLACK_SHADES[20]}` };
    border-radius: 20px;
    background: ${props => `url(${props.iconURL})`} no-repeat center;
    background-color: #fff;
    cursor: pointer;
`
export const ClientAdminItem = styled.div<{ iconURL: string }>`
    width: 100%;
    height: 100%;
    background: ${props => `url(${props.iconURL})`} no-repeat center;
    background-size: contain;
`
export const ClientTitle = styled.p`
    font-weight: 600;
    font-size: 28px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: ${({theme}) => `${theme.colors.PRIMARY[400]}` };
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0;
`

export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: ${({theme}) => `${theme.colors.BLACK_SHADES[20]}` };
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const CloseModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    color: ${({theme}) => theme.colors.WHITE };
    margin: 30px;
    z-index: 200;
    background-image: url(${process.env.PUBLIC_URL + "/assets/close.svg"});
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius:50%;
    box-shadow: ${({theme}) => `${theme.colors.BLACK_SHADES[24]}` } 0 3px 8px;
`
