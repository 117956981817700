import styled from 'styled-components';

export const closeIcon = `${process.env.PUBLIC_URL}/assets/cancel.svg`;
export const rabbitImage = `${process.env.PUBLIC_URL}/assets/alice-rabbit.svg`;

export const Container = styled.div`
  flex: 1;
  margin-bottom: 72px;
`;
export const Wrapper = styled.div``;
export const Header = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 1024px) {
        flex-direction: column;
        gap: 20px;
    }
`;
export const HeaderText = styled.div``;
export const HeaderActions = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const HeaderTextBig = styled.div`
    font-family: "Roboto Condensed", "sans-serif";
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: ${({ theme }) => theme.colors.GRAY[900]};
`;
export const HeaderTextSmall = styled.div`
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.GRAY[600]};
`;
export const DropDown = styled.div`
    width: 181px;
    height: 40px;
    background: ${({ theme }) => theme.colors.GREYED[800]};
    border: 1px solid ${({ theme }) => theme.colors.GRAY[300]};
    border-radius: 8px;
    cursor: pointer;
`;
export const DropDownCover = styled.div`
    display: block;
    position: relative;
`;
export const InnerCover = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin-top: 6px;
`;
export const Icon = styled.img`
    width: 15px;
    height: 16.67px;
`;
export const ArrowDownIcon = styled.img`
    width: 24px;
    height: 24px;
`;
export const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const Right = styled.div``;
export const DropDownName = styled.div`
    font-family: "Roboto Condensed", "sans-serif";
    font-size: 12px;
    line-height: 16px;
`;
export const Content = styled.div`
    margin-top: 34px;
    padding-bottom: 60px;
`;
export const SearchBox = styled.div`
    width: 320px;
    height: 44px;
    border: 1px solid ${({ theme }) => theme.colors.GRAY[300]};
    box-shadow: 0px 1px 2px ${({ theme }) => theme.colors.SHADOW[200]};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.WHITE};
    display: flex;
    align-items: center;
    padding-left: 16.5px;
    gap: 10px;
    margin-bottom: 24px;
`;
export const SearchInput = styled.input`
    flex: 1;
    height: 80%;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.GRAY[500]};
`;
export const SearchIcon = styled.img`
    width: 15px;
    height: 15px;
`;
export const TableContentContainer = styled.div`
  display: block;
  column-span: all;
  overflow-x: auto;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-shadow: 0px 1px 3px ${({ theme }) => theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.GRAY[200]};
  border-radius: 12px;
  padding: 12px 0;
  padding-bottom: 0;
  white-space: nowrap;
  overflow-y: auto;
  &:last-child {
    margin-bottom: 0px;
  }
  &:first-child {
    margin-top: 20px;
  }
`;
export const AliceRabbitImage = styled.img`
  width: 100px;
  height: 165px;
  margin-bottom: 20px;
`;
export const CancelButton = styled.div`
  width: 100%;
  position: absolute ;
  top: 20px;
  right: 20px;
`;
export const Icons = styled.img`
  width: 12px;
  height: 12px;
  float: right;
  cursor: pointer;
`;
export const CloseButton = styled.button`
  width: 352px;
  height: 44px;
  background-color: ${({ theme }) => theme.colors.PRIMARY[300]};
  color: ${({ theme }) => theme.colors.WHITE};
  border: none;
  border-radius: 8px;
  margin-top: 32px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
`;
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 365px;
  position: relative;
`;

export const TextOne = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.GRAY[900]};
  margin-bottom: 10px;
`;
export const TextTwo = styled.div`
  font-family: Roboto Condensed;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
`;
export const SearchWrapper = styled.div`
  width: 320px;
`;