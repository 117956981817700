import styled from "styled-components";
import { CustomFieldsGroupContainer } from "styles/SectionSharedItems.styled";

export const Image = styled.img`
  margin-bottom: 10px;
`;

export const GeneralDetailsContainer = styled.div`
  display: flex;
  column-gap: 40px;
`;

export const CenterContent = styled.div`
  flex: 1;
`;

export const GeneralDetailsRightColumnContent = styled.div`
  flex: 1;
  font-size: 16px;
`;

export const GeneralDetailsFGC = styled(CustomFieldsGroupContainer)`
  margin-top: 33px;
  columns: 2 auto;
  overflow: hidden;
  @media (max-width: 1200px) {
    column-count: 1;
  }
`;

export const TitleCover = styled.div<{isAlert:boolean}>`
  /* display: ${({isAlert}) => isAlert && 'flex' }; */
  flex-direction: row-reverse;
  justify-content: space-between ;
  align-items: center;
`

export const GeneralDetailsEntityTitle = styled.div`
  color: ${({theme}) => theme.colors.PRIMARY[400]};
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
`;

export const GeneralDetailsEntitySubTitle = styled.div`
  color: ${({theme}) => theme.colors.DARK[500]};
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-top: 14px;
`;

export const GeneralDetailsSingleRow = styled.div`
  color: ${({theme}) => theme.colors.PRIMARY[500]};
  font-size: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  &>div{
    display: flex;
  }
  @media (max-width: 500px) {
    display: inline;
    &>div{
    align-items: center;
  }
  }
`;

export const GeneralDetailsFieldName = styled.div`
  color: ${({theme}) => theme.colors.PRIMARY[400]};
  font-weight: 700;
  font-size: 18px;
  line-height: 21.09px;
  flex: 0 0 180px;
  font-family: Roboto Condensed;
`;

export const GeneralDetailsFieldValue = styled.div`
  color: ${({theme}) => theme.colors.GREY[500]};
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  padding: 0 11px 0 10px;
  font-family: Roboto Condensed;
  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 10px;
  }
`;
export const WarningIcon = styled.div`
  padding-left: 10px;
`;


export const OpenModal = styled.div`
background-image: url(${process.env.PUBLIC_URL+"/assets/OpenIconModal.svg"});
background-repeat:no-repeat;
display: block;
width: 18px;
height: 18px;
cursor: pointer;
@media (max-width: 768px) {
  width: 28px;
  }
`;
export const Modal = styled.div`
width: 100vw;
height: 100vh;
top: 80px;
left: 0;
right: 0;
bottom: 0;
position: fixed;
`;
export const Overlay = styled.div`
width: 100vw;
height: 100vh;
top: 0;
left: 0;
right: 0;
bottom: 0;
position: fixed;
background: rgba(49,49,49,0.3);
`;
export const ModalContent = styled.div`
position: absolute;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
top: 40%;
left: 50%;
transform: translate(-50%, -50%);
line-height: 1.4;
background:  ${({theme}) => theme.colors.WHITE};
padding: 14px 28px;
border-radius: 3px;
max-width: 400px;
min-width: 300px;
box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
border-radius: 12px;

& h2{
    margin-top: 0;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color:  ${({theme}) => theme.colors.DARK[400]};
    font-family: Roboto Condensed;
   }
& p{
    margin-top: 8px;
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-family: Roboto Condensed;
    text-align: center;
    color: ${({theme}) => theme.colors.GREYED[600]};
}
`;
export const CloseModal = styled.div`
position: absolute;
top: 22px;
right: 22px;
padding: 5px 7px;
background-image: url(${process.env.PUBLIC_URL+"/assets/CloseIconModal.svg"});
background-repeat:no-repeat;
display: block;
width: 15px;
height: 15px;
cursor: pointer;
`;
export const LizardImg = styled.div`
background-image: url(${process.env.PUBLIC_URL+"/assets/LizardImg.svg"});
display: block;
width: 100px;
height: 104px;
display: flex;
justify-content: center;
align-items: center;
margin-top: 14px;
margin-bottom: 8px;
`;
export const ButtonDiv = styled.div`
display: flex;
margin-bottom: 14px;
font-weight: 700;

   &>:nth-child(1) {
    color: ${({theme}) => theme.colors.PRIMARY[400]};
    &:hover{

    background-color: ${({theme}) => theme.colors.GREYED[300]};
   }
  }
&>:nth-child(2) {
    background: ${({theme}) => theme.colors.PRIMARY[400]};
    color: ${({theme}) => theme.colors.WHITE};
  }
`;
export const Button = styled.div`
 width: 170px;
text-align: center;
margin-left: 5px;
margin-right:5px;
padding: 10px 0px;
border: 1px solid ${({theme}) => theme.colors.GREYED[300]};
box-shadow: 0px 1px 2px ${({theme}) => theme.colors.GREYED[300]};
border-radius: 8px;
cursor: pointer;
`;