import AliceRequestComponent from "../../components/admin/AliceRequest"
import data from 'data/clientAliceRequests.json'
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import useEffectOnce from "hooks/useEffectOnce";

const AliceRequests = () => {
    const {track} = useTrackActivity();
    
    useEffectOnce(() => {
        track(ActivityType.LOAD_PAGE, "Client Alice Requests")
    })
    return <AliceRequestComponent data={data} isParty={false} />;
}

export default AliceRequests;