import { PARTY, PATH } from "constants/index";
import { PageCardDetails } from "types";

export const pageCardDetails = ({
  isDidoDemo,
  isT360,
}: {
  isDidoDemo: boolean;
  isT360: boolean;
}): PageCardDetails[] => {
  return [
    {
      link: PATH.SEARCH,
      iconURL: "/assets/party-profile-search-icon-grey.svg",
      iconURLHover: "/assets/party-profile-search-icon-purple.svg",
      text: "Search",
      isUserAllowed: true,
    },
    {
      link: PATH.ALERT,
      iconURL: "/assets/checkmate-landing-page-menu-icon-grey.svg",
      iconURLHover: "/assets/checkmate-landing-page-menu-icon-purple.svg",
      text: "Alert",
      isUserAllowed: true,
    },
    {
      link: PATH.ADMIN_ALICE_REQUEST,
      iconURL: "/assets/party-manager-admin-room-grey.svg",
      iconURLHover: "/assets/party-manager-admin-room-purple.svg",
      text: "ALICE Admin",
      isUserAllowed: isT360,
    },
    {
      link: PATH.CLIENT_ALICE_REQUEST,
      iconURL: "/assets/party-manager-admin-room-grey.svg",
      iconURLHover: "/assets/party-manager-admin-room-purple.svg",
      text: "Client Room",
      isUserAllowed: !isT360,
    },
    {
      link: PATH.DIDO_DEMO_INTERACTIVE,
      iconURL: "/assets/party-manager-admin-room-purple.svg",
      iconURLHover: "/assets/party-manager-admin-room-purple.svg",
      text: "DIDO's Demo",
      isUserAllowed: isDidoDemo,
    },
  ];
};

export const fundPageCardDetails = () => {
  return [
    {
      link: PATH.FUND_SEARCH,
      iconURL: "/assets/party-profile-search-icon-grey.svg",
      iconURLHover: "/assets/party-profile-search-icon-purple.svg",
      text: "Search",
      isUserAllowed: true,
    },
  ];
};

export const pageDetails = ({
  isDidoDemo,
  isT360,
  isUserPartOfOrginaization,
}: {
  isDidoDemo: boolean;
  isT360: boolean;
  isUserPartOfOrginaization: (organizations: string[]) => boolean;
}): PageCardDetails[] => {
  return isUserPartOfOrginaization([PARTY.HUBWISE])
    ? fundPageCardDetails()
    : pageCardDetails({ isDidoDemo, isT360 });
};
