//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the Party Profile section called History
 */

 import { NoDataToDisplay } from 'components/noData/ComingSoon';
 import { TableContent } from '../tableContent/TableContent'
 import { dataFilter } from '../dataFilter/DataFilter'
 import { FieldValueFilter } from '../FieldValueFilter'
 import {
     CustomFieldsGroupContainer,
     SingleRowContainer,
     SingleRowInternalContainer,
     SingleRow,
     SingleRowTwoColumns,
     FieldName,
     FieldValue,
     HistoryContainer,
     FieldNameAlt,
     FieldValueAlt,
     SingleRowAlt,
 } from "../../styles/SectionSharedItems.styled";
import {HistoryArray, HistoryData} from "../../types";
import { useState } from 'react';
import AliceModalTooltip from 'components/AliceModalTooltip';
 
 
 function FieldsGroup({ data }: { data: HistoryData }) {
    const [alertIsActive, setAlertIsActive] = useState(false)
    const menuToggle = () => setAlertIsActive(!alertIsActive)

     return (
       <CustomFieldsGroupContainer>
         {data.length === 0
           ? (<NoDataToDisplay/>)
           : data.map(([name, field]:[string, string | HistoryArray], j:number) => (
               // check if data is an array
               Array.isArray(field)
                 // if it's an array, check if it's an array of IDs then pass it to a table
                 ? <TableContent data={field} name={name} tableType="Other" key={j}/> // field.map((item, i) => <FieldsGroup data={item} key={i} />)
                 // else, if data is not an array, check if the field as some value, then display the values at the top
 
                 // if it's General Description, let's take all the availale space
                 :   field && field.trim()!=="" && name==="General Description"   // this part is hardcoded and has to be changed in the future!
                     &&  <SingleRowTwoColumns key={j}>
                             <FieldName>{name}</FieldName>
                             <FieldValue>
                                 <FieldValueFilter fieldToFilter={field}/>
                             </FieldValue>
                         </SingleRowTwoColumns>
                     ||
                     field && field.trim()!==""
                     &&  <SingleRowContainer key={j}>
                             <SingleRowInternalContainer>
                                 <SingleRowAlt>
                                     <FieldNameAlt>{name}</FieldNameAlt>
                                     <AliceModalTooltip selectedField={field} selectedKey={name}>{field}</AliceModalTooltip>
                                 </SingleRowAlt>
                             </SingleRowInternalContainer>
                         </SingleRowContainer>
                     ||
                     null
         ))}
       </CustomFieldsGroupContainer>
     )
 }
 
 // main function
 export function History({ data, accordionName }: { data: HistoryData, accordionName:string }) {
 
     
     return (
         <HistoryContainer>
             <FieldsGroup data={dataFilter({data})} />
         </HistoryContainer>
     )
 }