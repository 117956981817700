import { PartyData } from "types/party";
import {
    AllSameValues, AspectDetails, AspectDetailsTransformDefn, AspectTransform,
    BufferAndLoadUrlData, Entity,
    FileDetails,
    FingerPrint,
    ImportVariables, NamesAndValues, PartyDetails, PartyDetailsTransformDefn,
    ReduceAccumulator,
    RowNumberAndLines,
    ObjectType
} from "../types";
import {getResourcePath} from "./networkingUtils";

export function subStringIndexsFor(ns: number[]): number[][] {
    let mutableResult: number[][] = []
    const recurseSubString = (ns: number[], startIndex: number) => {
        if (ns.length === 0) return;
        let newIndex = startIndex + ns[0]
        mutableResult.push([startIndex, newIndex])
        recurseSubString(ns.slice(1), newIndex)
    }
    recurseSubString(ns, 0)
    return mutableResult
}

/**
 * Take a string and encode it with SHA-1 algorithm.
 * @param {string} s the string that needs to be converted
 */
export let findSha = async (s: string):Promise<string> => {
    const msgUint8 = new TextEncoder().encode(s);
    const hashBuffer = await window.crypto.subtle.digest('SHA-1', msgUint8 );
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashHex.toUpperCase();
}

/**
 * Get all the necessary FileDetails regarding a specific entity, identified uniquely by an id.
 * @param {string} id the Entity unique identifier
 * @param {ImportVariables} importVariables the configuration file created during the data import
 */
export const idToFileDetails = async (id: string, importVariables:ImportVariables):Promise<FileDetails> => {
    const entityModNumber = parseInt(importVariables.entityMod);
    const splittedId = id.split("");
    let normalizedId = "";
    let rest = 0;
    const idLength = splittedId.length;
    if (idLength !== 0){
        idLength % 2 !== 0 ? rest = (idLength/2)+1 :  rest = (idLength/2);
        if(rest < entityModNumber){
            splittedId.forEach((value, index) => {
                if (index % 2 !== 0)
                    normalizedId += value;
            })
        }else{
            let digitCounter = 0;
            splittedId.forEach((value, index) => {
                if (index % 2 === 0 && digitCounter < entityModNumber){
                    normalizedId += value;
                    digitCounter++;
                }

            })
        }
    }
    const shaOfTruncatedId = await findSha(normalizedId)
    const parseIntoArrayOfNumbers = (stringToParse:string) => stringToParse.split(',').map(Number);
    const partitionsToStrings = (partitions: number[]) => subStringIndexsFor(partitions).map(([from, to]) => shaOfTruncatedId.substring(from, to));
    const partitionToGitArray = parseIntoArrayOfNumbers(importVariables.partitionToGit)
    const partitionFromGitArray = parseIntoArrayOfNumbers(importVariables.partitionFromGit);
    const allPartitions = [...partitionToGitArray, ...partitionFromGitArray]
    const parts = partitionsToStrings(allPartitions)
    const maxIndex = subStringIndexsFor(allPartitions)[allPartitions.length - 1][1]
    const name = shaOfTruncatedId.substring(maxIndex)
    const entitylistTarOnGoogleCloud = getResourcePath(importVariables.entityZipPath, ...parts.slice(0, partitionFromGitArray.length - 1), parts[partitionFromGitArray.length - 1] + ".tar.gz")
    console.log(id, shaOfTruncatedId, parts, name, allPartitions)
    return (
        {
            id,
            shaOfTruncatedId, parts, name,
            intermediateEntitylistPath: getResourcePath(importVariables.intermediatePath, importVariables.entityLocalPath, ...parts, name),
            entitylistTarPath: getResourcePath(importVariables.outputMount, entitylistTarOnGoogleCloud),
            entitylistTarOnGoogleCloud,
            pathInsideTar: './' + getResourcePath(...parts.slice(partitionFromGitArray.length), name),
            cachedEntityPath: id + '.json'
        }
    )
}

/**
 * Parse a raw downloaded finger print into a meaningful FingerPrint file ready for use.
 * @param {string} rawFingerPrint
 */
export let makeFingerprint = (rawFingerPrint:string): FingerPrint => {
    let lines = rawFingerPrint.split('\n');
    if (lines.length < 3)
        throw new Error('fingerPrint file is very short:')
    return ({
        lines: lines.map(line => line.split('\t').slice(3).join("\t").trim()),
        fileNames: lines.map(line => line.split('\t')[1])
    })
}

export let makeAccumulatedLines = (fingerPrint: FingerPrint) => (id: string, fileContents: string): RowNumberAndLines[] => {
    let lines = fileContents.split('\n')
    let initialValue: ReduceAccumulator = {currentHeader: -1, currentLines: [], result: []};
    let result = lines.reduce((acc, line) => {
        let tabs = line.split('\t')
        if (line.startsWith('f1')) {return acc } else if (line.startsWith(`v1\t${id}\t`)) {
            let headerNumber = Number.parseInt(tabs[2]);
            let row = fingerPrint.lines[acc.currentHeader];
            if (headerNumber>=fingerPrint.lines.length) {
                // console.error(`the header number was ${headerNumber}. CurrentHeader is ${acc.currentHeader} FingerPrints length is ${fingerPrint.lines.length}, row is ${row}`)
                throw new Error(`the header number was ${headerNumber}. FingerPrints length is ${fingerPrint.lines.length}\nAre you looking at the correct fingerPrints file`)
            }
            return {result: [...acc.result, {row, rowName: fingerPrint.fileNames[acc.currentHeader], lines: acc.currentLines}], currentHeader: headerNumber, currentLines: []}
        } else if (line.startsWith(`\t${id}\t`)) {
            return {...acc, currentLines: [...acc.currentLines, line.trimRight()]}
        } else return acc
    }, initialValue)
    if (result.currentLines.length > 0)
        return [...result.result.slice(1), {row: fingerPrint.lines[result.currentHeader], rowName: fingerPrint.fileNames[result.currentHeader], lines: result.currentLines}]
    else return result.result.slice(1)
}

export let toNamesAndValues = (numberAndLines: RowNumberAndLines[]): NamesAndValues[] =>
    numberAndLines.map(nl => {
        try {
            return ({
                rowName: nl.rowName,
                names: nl.row.split('\t'),
                values: nl.lines.map(line => line.split('\t').slice(1))
            })
        } catch (e) {
            throw Error("Cannot transform accumulatedLinesIntoJson\n" + JSON.stringify(numberAndLines, null, 2) + "\n" + e)
        }
    })

export let namesAndValuesToJson = (namesAndValues: NamesAndValues[]) => {
    let result:ObjectType = {}
    namesAndValues.forEach(nv => {
        let array = nv.values.map((values) => {
            if (nv.names.length < values.length) {
                throw Error(`Length mismatch
                                                rowname ${nv.rowName}
                                                names:  [${nv.names.length}]  ${nv.names}
                                                values: [${values.length}],  ${values})`)
            }
            let newObject: {[key: string]: string} = {}
            values.forEach((v, i) => newObject[nv.names[i]] = v)
            return newObject
        })
        result[nv.rowName] = (nv.values.length === 1) ? array[0] : array
    })
    return result
}

export function mapAndFilterKeysAndValues<V>(o: AllSameValues<V>, filter: (key: string) => boolean, keyMap: (key: string) => string, valueMap: (value: V) => V): AllSameValues<V> {
    let result: AllSameValues<V> = {}
    Object.entries(o).filter(([name]) => filter(name)).forEach(([name, value]) => result[keyMap(name)] = valueMap(value))
    return result
}

let transformKeyDefn: {[key: string]: string} = { 'BvD_ID_and_Name.txt': 'Name' }

let fileBlackList = new Set<string>(['BvD9.txt'])

const defaultPartyDetailsTransformDefn: PartyDetailsTransformDefn = {
    aspectKeyFilter: (name: string) => name !== 'BvD ID number',
    aspectKeyMap: (name: string): string => name,
    partyKeyFilter: (name: string) => true,
    partyKeyMap: (name: string): string => name
}

const partyDetailsTransformDefn:PartyDetailsTransformDefn = {
    ...defaultPartyDetailsTransformDefn,
    partyKeyFilter: (key: string) => !fileBlackList.has(key),
    partyKeyMap: (key: string) => transformKeyDefn.hasOwnProperty(key) ? transformKeyDefn[key] : key
}

export let transformAspect: AspectTransform = defn => aspect => mapAndFilterKeysAndValues(aspect, defn.aspectKeyFilter, defn.aspectKeyMap, v => v)

export let transformAspectOrArray = (defn: AspectDetailsTransformDefn) => (a: AspectDetails | AspectDetails[]) =>
    Array.isArray(a) ? a.filter(aspect=>Object.keys(aspect).length>0).map(transformAspect(defn)) : transformAspect(defn)(a)

export function transformParty(defn: PartyDetailsTransformDefn): (party: ObjectType) => PartyDetails {
    return party => mapAndFilterKeysAndValues(party, defn.partyKeyFilter, defn.partyKeyMap, transformAspectOrArray(defn)) as unknown as PartyDetails;
}

/**
 * Transform a raw EntityList file into an Entity object.
 * @param {FingerPrint} fingerPrint the Finger Print file
 * @param {BufferAndLoadUrlData} entityListAndLoadUrlData object with all the data and metadata
 */
export let parseEntityListFile = (fingerPrint:FingerPrint, entityListAndLoadUrlData: BufferAndLoadUrlData):Entity => {
    const rowNumberAndLines = makeAccumulatedLines(fingerPrint)(entityListAndLoadUrlData.fileDetails.id, new TextDecoder().decode(entityListAndLoadUrlData.buffer) )
    const namesAndValues = toNamesAndValues(rowNumberAndLines)
    const rawPartyDetails = namesAndValuesToJson(namesAndValues) as PartyData
    const partyDetails = transformParty(partyDetailsTransformDefn)(rawPartyDetails)
    return {entityListAndLoadUrlData: entityListAndLoadUrlData, rowNumberAndLines, namesAndValues, rawPartyDetails, partyDetails}
}

// this little function returns the type of custom table
export const getTableType = (accordionName: string): string => {
    switch (accordionName) {
      case "Financial Performance":
        return "FP";
      default:
        return "Other";
    }
  };