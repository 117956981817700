import React from 'react';
import { ErrorMessageContainer, NoData } from 'styles/NoDataFound.styled'

interface NoDataFoundProps {
    countdown: number;
  }
const NoDataFound: React.FC<NoDataFoundProps> = ({countdown}) => {
  return (
    <ErrorMessageContainer>
    <NoData></NoData>
    <p>Something went wrong while fetching your Data</p>
    <h3>Redirecting in {countdown} seconds...</h3>
  </ErrorMessageContainer>
  )
}

export default NoDataFound