/**
 * This is the dedicated component for every search result item that is showed for every Search Results page in Party Manager.
 */

import {useState} from 'react';
import ReactCountryFlag from "react-country-flag"
import {Result} from "../../../types";
import {SearchResultsAccordion} from "../searchResultsAccordion/SearchResultsAccordion";
import {
    SearchResultItemContainer,
    SearchResultItemArea,
    SearchResultItemLeftArea,
    SearchResultItemRightArea,
    SearchResultsItemName,
    SearchResultsItemCountry,
    AccordionActionButton,
    OpenPartyIcon,
    CloseAccordionIcon,
    OpenAccordionIcon,
    GuoIconContainer,
    GuoImage,
    RightIcons,
} from '../../../styles/searchResult/SearchResultsItem.styled';
import { Link } from "react-router-dom";
import { useAppData } from 'hooks/useAppData';
import { PARTY } from 'constants/index';

export function SearchResultsItem({category, id, isGuo, history, mainActivity, name, identifiers, entityTypeInternal,
                                      entityType, description, country, iso, isTargetEntity, totalAssets}: Result) {

    const [showItem, setShowItem] = useState(false)
    const { isUserPartOfOrginaization } = useAppData();
 
    return (
    <div>
        <SearchResultItemContainer show={showItem}>
            <SearchResultItemArea onClickCapture={() => setShowItem(true)}>
                <SearchResultItemLeftArea>
                    {isGuo && <GuoIconContainer><GuoImage src={process.env.PUBLIC_URL+"/assets/guoIcon.svg"}/></GuoIconContainer>}
                    {name && <SearchResultsItemName>{ name }</SearchResultsItemName>}
                    <SearchResultsItemCountry>
                        {iso &&
                            <div>
                                <ReactCountryFlag countryCode={iso} svg style={{width: '1.5em', height: '1.5em'}} title={iso}/>
                            </div>
                        }
                        {country && <div>{country}</div>}
                    </SearchResultsItemCountry>
                    {entityType && <div>{entityType}</div>}

                    <RightIcons>
                    <AccordionActionButton>
                            <Link to={isUserPartOfOrginaization([PARTY.HUBWISE]) ? "/funds/" + id : "/party/" + id}><OpenPartyIcon title={isUserPartOfOrginaization([PARTY.HUBWISE]) ? "Open Funds Profile" :"Open Party Profile"}/></Link>
                        </AccordionActionButton>
                        <AccordionActionButton onClick={() => setShowItem(!showItem)}>
                            { showItem === true ? (
                              <CloseAccordionIcon title="Close this result"/>
                            ) : (
                              <OpenAccordionIcon title="Open this result"/>
                            )}
                        </AccordionActionButton>
                    </RightIcons>
                </SearchResultItemLeftArea>
                <SearchResultItemRightArea onClick={!showItem ? () => setShowItem(true) : undefined}>
                    {showItem &&
                        <SearchResultsAccordion
                            idForNeo4j={id}
                            mainActivity={mainActivity}
                            category={category as string}
                            description={description} identifiers={identifiers}
                        />
                    }
                </SearchResultItemRightArea>
            </SearchResultItemArea>
        </SearchResultItemContainer>
    </div>
    );
}
