import styled from "styled-components";

const CustomLoadingSpinner = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: ${({theme}) => theme.colors.WHITE!=='#FFFFFF'?'saturate(0.8) brightness(0.86) hue-rotate(180deg) invert(1)':'none'};
`;

/**
 * Custom loading spinner that can be used in every part of the application.
 */
const LoadingSpinner = () => {
    return <CustomLoadingSpinner alt="Loading spinner" src={process.env.PUBLIC_URL + "/assets/loadingSpinner.svg"} />
}
export default LoadingSpinner;
