/**
 * Function that calls a Google Cloud Function (GCF) and - if successful - returns a Promise<ArrayBuffer>.
 * @param {string} apiURL the GCF url
 * @param {Object} dataForGCF the payload
 * @param {string} about the name of the GCF
 * @param {string} where which part of the code calls the GCF
 */
export const callGCFunction = async (apiURL: string, dataForGCF:Object, about:string, where:string):Promise<ArrayBuffer> => {
    try {
        const response = await fetch(apiURL, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dataForGCF)
        });
        if(!response.ok)
            throw new Error('Network response was not OK');
        return response.arrayBuffer();
    } catch (err) {
        return new ArrayBuffer(0);
    }
}