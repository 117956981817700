//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for Key People's modal.
 */

 import { useEffect, useState } from 'react';
 import { FieldValueFilter } from 'components/FieldValueFilter';
 import { Person, PersonProperty, SetModal, PropertiesToDisplayInModal } from 'types/keyPeopleModal';
 import {
    ModalBoxCloseIcon,
    ModalBoxContainer,
    ModalBoxContent,
    ModalBoxControls,
    NonScrollableContent,
    FullName,
    DateOfBirth,
    Country,
    ScrollableContent,
    Biography,
    Positions,
    SinglePosition,
    ModalTitleWrapper
} from 'styles/party/keyPeople/modal/Modal.styled';

  
 export const Modal = ({person, setModalUCI, isLeft=false, modalUCI}:{person:Person | undefined, setModalUCI:SetModal, isLeft?:boolean, modalUCI: string | string[] | undefined}) => {

     const [propertiesToDisplay, setPropertiesToDisplay] = useState<PropertiesToDisplayInModal>()
     
     useEffect(() => {
 
         let personPropertiesToDisplay:PropertiesToDisplayInModal = {}
 
         person?.properties.forEach((personProperty:PersonProperty) => {
             switch(personProperty.propertyName){
                 case "Full Name":
                     personPropertiesToDisplay.fullName = personProperty.propertyValue
                     break
                 case "Date of Birth":
                     personPropertiesToDisplay.dateOfBirth = personProperty.propertyValue
                     break
                 case "Country of Nationality":
                     personPropertiesToDisplay.countryOfNationality = personProperty.propertyValue
                     break
                 case "Biography":
                     personPropertiesToDisplay.biography = personProperty.propertyValue
                     break
                 case "Position":
                     personPropertiesToDisplay.position = personProperty.propertyValue
                     break
                 default:
                     break
             }
                  
         })
 
         setPropertiesToDisplay(personPropertiesToDisplay)
     },[])
 
     // utility to check if string is empty
     const isNotEmpty = (valueToCheck:string) => {
         return valueToCheck.trim() !== ""
     }
 
     // utility to check if a property is not undefined AND is string AND is not empty
     const checkIsStringToDisplay = (propertyToCheck:string | string[] | undefined) => {
         if (propertyToCheck && typeof propertyToCheck === 'string'  && propertyToCheck !== ""){
             return propertyToCheck
         }
     }
 
     // utility to check if a property is not undefined AND is array AND has at least one item
     const checkIsArrayToDisplay = (propertyToCheck: string | string[] | undefined) => {
         if(propertyToCheck && Array.isArray(propertyToCheck) && propertyToCheck.length !== 0) {
             return propertyToCheck
         }
     }
 
     return (
         <div>
             <ModalBoxContainer>
                 
                 {   propertiesToDisplay
                     ?   <ModalBoxContent left={isLeft}>
                         <NonScrollableContent>
                             <ModalTitleWrapper>
                                 <FullName>
                                     {checkIsArrayToDisplay(propertiesToDisplay.fullName)?.map((itemToDisplay: string, index: number) => (
                                         <div key={index}><b>{checkIsStringToDisplay(itemToDisplay)}</b></div>
                                     ))}
                                     <b>{checkIsStringToDisplay(propertiesToDisplay.fullName)}</b>
                                 </FullName>

                                 <DateOfBirth>
                                     {checkIsArrayToDisplay(propertiesToDisplay.dateOfBirth)?.map((itemToDisplay: string, index: number) => (
                                         <div key={index}>{checkIsStringToDisplay(itemToDisplay)}</div>
                                     ))}
                                     {checkIsStringToDisplay(propertiesToDisplay.dateOfBirth)}
                                 </DateOfBirth>

                                 <Country>
                                     {checkIsArrayToDisplay(propertiesToDisplay.countryOfNationality)?.map((itemToDisplay: string, index: number) => (
                                         <div key={index}>{checkIsStringToDisplay(itemToDisplay)}</div>
                                     ))}
                                     {checkIsStringToDisplay(propertiesToDisplay.countryOfNationality)}
                                 </Country>
                             </ModalTitleWrapper>
                             <ModalBoxControls>
                                 <ModalBoxCloseIcon onClick={() => setModalUCI("")} />
                             </ModalBoxControls>
                         </NonScrollableContent>
                             <ScrollableContent>
                                 <Biography>
                                 {   checkIsArrayToDisplay(propertiesToDisplay.biography)?.map((itemToDisplay:string, index:number) => (
                                         checkIsStringToDisplay(itemToDisplay) && <div key={index}><FieldValueFilter fieldToFilter={ checkIsStringToDisplay(itemToDisplay)} /></div>
                                     ))}
                                 {   checkIsStringToDisplay(propertiesToDisplay.biography) && <FieldValueFilter fieldToFilter={ checkIsStringToDisplay(propertiesToDisplay.biography)} />}
                                 </Biography>
 
                                 {   checkIsArrayToDisplay(propertiesToDisplay.position)?.some(property => isNotEmpty(property))
                                     &&  <Positions>
                                         { checkIsArrayToDisplay(propertiesToDisplay.position)?.length === 1 ? <span>Position:</span> : <span>Positions</span>}
                                             { checkIsArrayToDisplay(propertiesToDisplay.position)?.map( (itemToDisplay:string, index:number) => (
                                                 <SinglePosition key={index}>{checkIsStringToDisplay(itemToDisplay)}</SinglePosition>
                                             ))}
                                         </Positions>
                                 }
                                 { checkIsStringToDisplay(propertiesToDisplay.position)
                                 && <Positions>Position: <SinglePosition>{propertiesToDisplay.position}</SinglePosition></Positions> }
 
                             </ScrollableContent>
                         </ModalBoxContent>
                     :   <ModalBoxContent>Sorry, we do not have enough data to display</ModalBoxContent>
                 }
             </ModalBoxContainer>
         </div>
     )
 }
 