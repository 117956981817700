// Copyright Marcello Di Fronzo Gravallese and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

import {useEffect} from 'react';
import { sdkLoaded } from 'services/ecp';
import styled from "styled-components";
import { DivProps } from "../../types/ecp";


const CustomCardContainer = styled.div`
 `
 
const CustomDiv = styled.div<{id:string}>`
  display: ${({id}) => id ? "block" : "none"} ;
  height: 50vh;
`

const IframeCard = (props: DivProps) => {
    useEffect(() => {
            if(props.streamId) {
                sdkLoaded.then(() => {
                    (window as any).symphony.openStream(props.streamId, `#${props.chatName}`)
                })
                const iframes = document?.querySelectorAll?.('iframe') || [];

                if(iframes?.length) {
                    iframes.forEach(iframe => {
                        const id = iframe.src.split("#")[1];
                        iframe.style.display = id === props.chatName ? "block" : "none"
                    })
                }

            }
    }, [props.chatName, props.streamId])

    return (
        <CustomCardContainer>
            <CustomDiv id={props.chatName} className='check' />
        </CustomCardContainer>
    )
}

export default IframeCard;
