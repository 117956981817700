import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import LandingPageMenu from "../../components/landingPageMenu/LandingPageMenu";


const Home = () => {
    const {track} = useTrackActivity();

    useEffectOnce(() => {
        track(ActivityType.LOAD_PAGE, "Home")
    })
    
    return(
        <div className="Home">
            <LandingPageMenu />
        </div>
        
    )
}
export default Home;