//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the main component for Party Profile
 */

import type { FunctionComponent } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import GroupedDetails from "../GroupedDetails";
import {
  Container,
} from "styles/party/profile/index.styled";
import useEffectOnce from "hooks/useEffectOnce";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { PartyDetails } from "types";
import { ContactInformation } from "types/party";

const PartyProfile: FunctionComponent<{ data: PartyDetails; partyId: string }> = ({
  data,
  partyId,
}) => {
  const {track} = useTrackActivity()

  useEffectOnce(() => {
    track(
      ActivityType.LOAD_PARTY_PROFILE,
      'Party Profile',
      {
        party_name: (data['Contact Information'] as ContactInformation).Name,
        party_id: partyId
      }
      
    )
  })
  
  
  if (!data) return <LoadingSpinner />;
  else if (data && partyId) {
    return (
      <Container>
        <GroupedDetails data={data} partyId={partyId} light />
      </Container>
    );
  } else return <Container />;
};

export default PartyProfile;
