import styled from "styled-components";

const getIcon = (iconName:string):string => {
    let iconToBeUsed = '';
    switch (iconName){
        case 'GUO':
            iconToBeUsed = guoIconUrl;
            break;
        case 'NO_NEO':
            iconToBeUsed = notNeoIcon;
            break;
        case 'NEO':
            iconToBeUsed = neoIcon
            break;
    }
    return iconToBeUsed
    }

export const guoIconUrl = `${process.env.PUBLIC_URL}/assets/guoSuggestionItemIcon.svg`;
export const neoIcon = `${process.env.PUBLIC_URL}/assets/neo4jSuggestionItemIcon.svg`;
export const notNeoIcon = `${process.env.PUBLIC_URL}/assets/notNeo4jSuggestionItemIcon.svg`;


export const SuggestionItem = styled.div`
  text-transform: uppercase;
  padding: 10px 0 10px 15px;
  max-width: 100%;
  background: ${({theme}) => theme.colors.GREYED[800]};
  color: ${({theme}) => theme.colors.DARK[500]};
  border-left: ${({theme}) => theme.colors.GREYED[800]} solid 4px;
  font-weight: 500;

  &:hover{
    opacity: 0.8;
    background: ${({theme}) => theme.colors.PRIMARY[500]};
    border-left: ${({theme}) => theme.colors.PRIMARY[400]} solid 4px;
  }
`
export const SuggestionItemIcon = styled.span<{ typeOfIcon: string }>`
display: inline-block;
vertical-align: middle;
width: ${({typeOfIcon}) => typeOfIcon ==='NO_NEO' ? '12px' : '22px'};
height: ${({typeOfIcon}) => typeOfIcon ==='NO_NEO' ? '12px' : '21px'};
background-image: url(${props => getIcon(props.typeOfIcon) });
background-size: ${({typeOfIcon}) => typeOfIcon ==='NO_NEO' ? '12px 12px' : '22px 21px'};
margin: ${({typeOfIcon}) => typeOfIcon ==='NO_NEO' ? '0 10px 0 5px' : '0 10px 0 0'};;
`

export const SuggestionItemName = styled.span`
display: inline-block;
vertical-align: middle;
`