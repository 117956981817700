//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for displaying (or not) a table in the Party Profile's sections
 */
import { useState } from "react";
import { FilterTable } from "../filter/FilterTable";
import { RespTableRow, TableBodyCell, TableContentContainer } from "styles/tableContent/TableContent.styled";
import ManageFundsTable from "components/party/funds/table/ManageFunds";
import fundsData from "../../data/fundsDummyData.json";


export const DisplayRow = ({
    item,
    hasBorder = false,
  }: {
    item: any;
    hasBorder: boolean;
  }) => {
    let displayRow = false;

    // check if there's at least one column of useful data. If yes, show the row.
    Object.entries(item).forEach(([name, field]: [string, string | any], j) => {
      if (field && field.trim() !== "") displayRow = true;
    });

    return displayRow ? (
      <RespTableRow hasBorder={hasBorder}>
        {Object.entries(item).map(([name, field]: [string, string | any], j) => (
          <TableBodyCell key={j}>{field || "-"}</TableBodyCell>
        ))}
      </RespTableRow>
    ) : null;
  };

const FundData = fundsData;
export const ManagedFundsTableContent = ({
  data,
  name,
  tableType,
}: {
  data: typeof FundData;
  name: string;
  tableType: string;
}) => {

  const [filter, setFilter] = useState("");
  const [hideFilter, setHideFilter] = useState(false);

  let dataToDisplay: typeof FundData = data;

  const searchFilter = (letter: string) => {
    setFilter(letter);
  };

  let displayTable = false;

  // check if there's at least one table cell of useful data. If yes, show the table
  data.forEach((item: Object) => {
    Object.entries(item).forEach(([name, field]: [string, string], j) => {
      if (field && field.trim() !== "") displayTable = true;
    });
  });

  if (!displayTable) {
    return null;
  }

  return (
    <TableContentContainer noShadow noMarginTop>
      {!hideFilter && <FilterTable setData={searchFilter} />}
      <ManageFundsTable data={dataToDisplay} filter={filter} setHideFilter={setHideFilter} />
    </TableContentContainer>
  )
}

