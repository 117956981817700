import { FunctionComponent, useEffect, useState } from "react";
import {
  Container,
  Title,
  SubHeader,
  SubHeaderNav,
  SubContent,
} from "styles/party/funds/ManageFunds.styled";
import fundsData from "data/fundsDummyData.json";
import Funds from "..";
import { useParams } from "react-router-dom";
import useOktaAccessToken from "hooks/useOktaHooks";
import { getFundsById, listFundsDocuments } from "services/funds";
import { FundDocumentsType } from "types/funds";
import { search } from "utils/search";
import { Close } from "@material-ui/icons";
import { useAppData } from "hooks/useAppData";
import LoadingSpinner from "components/LoadingSpinner";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";
import { ActivityType, useTrackActivity } from "hooks/useTracker";
import { FilterableTable } from "components/filterableTable";


const FundData = fundsData;
const ManageFundsTable: FunctionComponent<{
  data: typeof FundData;
  filter: string;
  setHideFilter?: (arg: boolean) => void;
}> = ({ data, filter, setHideFilter }) => {
  const [dataToDisplay, setDataToDisplay] = useState<typeof FundData>([]);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isSub, setSub] = useState(false);
  const [fundData, setFundData] = useState(null);
  const [limit] = useState(40);
  const { id } = useParams<{ id: string }>();
  const { colors } = useAppData();
  const accessToken = useOktaAccessToken();
  const [fundDocuments, setFundDocuments] = useState<FundDocumentsType | null>(
    null
  );
  const { track } = useTrackActivity();

  const onClickRow = (index: string) => {
    if (selectedIndex === index) {
      close();
    } else {
      setSelectedIndex(index);
      setSub(true);
      setHideFilter?.(true);
      const clickedItem = dataToDisplay.find((item) => item['Fund ID'] === index);
      track(ActivityType.CLICK, 'Party Profile Funds', { activity_info: 'Funds Table Row Clicked', funds_clicked: clickedItem!['Fund Name'] })
    }
  };

  const viewedPagesLength = currentPage * limit;
  //use slice to cut the array of dataToDisplay using the starting and last index of items in the array
  //depending on the limit
  const displayableData = dataToDisplay.slice(
    viewedPagesLength - limit,
    viewedPagesLength
  );
  const close = () => {
    setSelectedIndex("");
    setSub(false);
    setHideFilter?.(false);
  };


  useEffect(() => {
    if (accessToken && id && selectedIndex) {
      listFundsDocuments(accessToken, selectedIndex).then((data) => {
        setFundDocuments(data);
      });
      getFundsById(accessToken, id, selectedIndex).then((data) => {
        setFundData(data);
      });
    }
  }, [accessToken, id, selectedIndex]);

  useEffect(() => {
    setDataToDisplay(data);
  }, []);

  useEffect(() => {
    let newData = [...data];
    newData = search(newData, filter);
    setDataToDisplay(newData);
  }, [filter]);

  return (
    <Container>
      {!isSub ? (
        displayableData.length ? <FilterableTable data={displayableData} onClickRow={onClickRow} idKey="Fund ID" tableType="FP" filter={filter} /> : null

      ) : (
        <>
          {fundData ? (
            <SubHeader>
              <SubHeaderNav>
                <Title>{fundData?.["Fund Name"]}</Title>
                <Close
                  onClick={close}
                  style={{ cursor: "pointer" }}
                  htmlColor={colors.PRIMARY[400]}
                />
              </SubHeaderNav>
              <SubContent>
                <Funds data={fundData} fundDocuments={fundDocuments!} />
              </SubContent>
            </SubHeader>
          ) : (
            <LoadingSpinnerDiv><LoadingSpinner /></LoadingSpinnerDiv>
          )}   </>
      )}
    </Container>
  );
};

export default ManageFundsTable;
