import {
    Container,
    Wrapper,
    ToastIcon,
    ToastIcon2,
    Message,
    InfoTitle,
    Info,
    IconWrapper
} from 'styles/Toast.styled'
import { StatusProps } from "constants/index";
import { useToast } from 'hooks/useToast';
import { useCallback, useEffect, useState } from 'react';

const closeIcon = `${process.env.PUBLIC_URL}/assets/cancel.svg`;
const closeIcon2 = `${process.env.PUBLIC_URL}/assets/cancel2.svg`;

function Toast() {
  const {toastInfo, setToastInfo} = useToast();
  const [duration] = useState(3000);

  const onClose = useCallback(() => {
    setToastInfo({isOpen: false, message: '', status: StatusProps.ERROR})
  }, [setToastInfo])

  useEffect(() => {
    const timer = setTimeout(() => {
        onClose();
    }, duration)

    return () => {
        clearTimeout(timer)
    }
}, [duration, onClose])

  return (
    toastInfo.isOpen ? (
        <Container status={toastInfo.status}>
        <Wrapper>
            <IconWrapper status={toastInfo.status}><ToastIcon2 src={closeIcon2} /></IconWrapper>
            <Info>
                <InfoTitle>
                    {toastInfo.status === StatusProps.ERROR ? 'Error' : 'Success'}
                </InfoTitle>
                <Message>{toastInfo.message}</Message>
            </Info>
        </Wrapper>
        <ToastIcon src={closeIcon} onClick={onClose} />
    </Container>
    ) : null
  )
}

export default Toast