import styled from "styled-components";
import { Link } from "react-router-dom";
import MenuIcon from '@material-ui/icons//Menu';


export const t360LogoPurple = `${process.env.PUBLIC_URL}/assets/t360-header-logo-purple.svg`;
export const signoutIcon = `${process.env.PUBLIC_URL}/assets/signout-icon.svg`;

export const StyledMenuIcon = styled(MenuIcon)`
 margin-right: 10px ;
 @media(min-width:768px) {
    display: none !important;
 }
`

export const Logo = styled.div<{ iconURL: string }>`
  width: 28px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  background-image: ${(props) => `url(${props.iconURL})`};
  margin: 0 14px 0 0;
`;

export const Header = styled.div`
  margin-bottom:50px;
`;

export const HeaderTitle = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: ${({theme}) => theme.colors.DARK[500]}
`;

export const Container = styled.div`
  padding: 32px;
  border-right: 1px solid #d0d5dd;

  @media(max-width:768px) {
    display: none ;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MenuContainer = styled.div`
  margin-bottom: 242px;
`;

export const MenuLink = styled(Link)`
  text-decoration: none;
`;

export const MenuItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  color: ${({theme, isActive}) => (isActive ? theme.colors.PRIMARY[400] : theme.colors.GRAY[650])};
  background: ${({theme, isActive}) => (isActive ? theme.colors.PRIMARY[25] : theme.colors.WHITE)};
  height: 25px;
  padding: 8px 12px;
  margin: 10px 0;
  border-radius: 6px;
  cursor: pointer;
  width: 248px;
`;

export const MenuItemIcon = styled.div<{
  iconURL: string;
  isActive?: boolean;
  path?: string;
}>`
  width: 22px;
  height: 22px;
  mask: ${(props) => `url(${props.iconURL})`} no-repeat center;
  background-size: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: ${({path, theme, isActive}) =>
    path === "signout"
      ? theme.colors.ACCENT[700]
      : isActive
      ? theme.colors.PRIMARY[400]
      : "#344054"};
`;

export const MenuItemText = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding-left: 8px;
  font-weight: 500;
`;
