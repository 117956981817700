import styled from 'styled-components';
import { SEARCH_CLASS_TYPE } from 'constants/filter';

export const FilterInputWrapper = styled.div<{classType: SEARCH_CLASS_TYPE}>`
    display: flex;
    align-items: center;
    background-color: ${({classType}) => classType === SEARCH_CLASS_TYPE.PRIMARY && "rgba(54, 64, 115, 0.1)"};
    border: ${({classType}) => classType === SEARCH_CLASS_TYPE.DEFAULT && "1px solid #D0D5DD"};
    padding: 13px 10px;
    border-radius: 6px;
    flex-basis: 392px;
    column-gap: 10px;
`;
export const FilterIcon = styled.div<{ icon: string }>`
    width: 20px;
    height: 20px;
    background-image: url(${process.env.PUBLIC_URL}/assets/${props => props.icon}.svg);
    background-size: contain;
`;

export const FilterInput = styled.input<{classType: SEARCH_CLASS_TYPE}>`
  color: ${({classType, theme}) => classType === SEARCH_CLASS_TYPE.DEFAULT && theme.colors.DARK[500]};
  font-weight: 400;
  font-size: ${({classType}) => classType === SEARCH_CLASS_TYPE.DEFAULT ? '16px' : '12px'};
  line-height: 15px;
  outline: none;
  border: none;
  background: transparent;
  flex: 1;
  &::placeholder {
    color: ${({classType, theme}) => classType === SEARCH_CLASS_TYPE.DEFAULT ? theme.colors.GRAY[500] : theme.colors.PRIMARY[400]};
    font-weight: 400;
    font-size: ${({classType}) => classType === SEARCH_CLASS_TYPE.DEFAULT ? '16px' : '12px'};
    line-height: 15px;
  }
  &:focus {
    outline: none;
  }
`;