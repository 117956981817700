//Copyright Marco Rapaccini, Marcello Di Fronzo Gravallese and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for managing the Hierarchy graph and table components.
 */

import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { initialUltimateOwnerState, RelatedEntity } from "../../../types";
import {
  getRelatedEntities4Hierarchy,
  ultimateOwnerCall,
} from "../../../services/neo4jCalls";
import { CyNode } from "../../../types/cytoscape";
import { TableHierarchyContent } from "./TableHierarchyContent";
import { processData } from "../../../utils/cytoscopeProcessData";
import { HierarchyData } from "../../../types/d3";
import {
  HierarchyVariableContent,
  HierarchyRow,
  HierarchyName,
  ShowGraph,
  SwitchContainer,
  SwitchButton,
} from "styles/party/cytoscape/Cytoscape.styled";
import { GRAPH_TYPE } from "constants/index";
import { PATH } from "constants/index";
import CytoScapeModal from "./CytoScapeModal";

export function CytoscapeExample({
  idForHierarchy,
}: {
  idForHierarchy: string;
}) {
  const { authState, oktaAuth } = useOktaAuth();

  let initialState: HierarchyData = {
    nodes: [],
    edges: [],
    d3Nodes: [],
  };

  let [dataReadyForSHOTable, setDataReadyForSHOTable] = useState(initialState);
  let [dataReadyForSUBTable, setDataReadyForSUBTable] = useState(initialState);

  let [guo, setGuo] = useState<RelatedEntity>(initialUltimateOwnerState);
  let [duo, setDuo] = useState<RelatedEntity>(initialUltimateOwnerState);

  let [fetchSHOHasTerminated, setFetchSHOHasTerminated] = useState(false);
  let [fetchSUBHasTerminated, setFetchSUBHasTerminated] = useState(false);

  let [showModal, setShowModal] = useState<string>("");

  let [currentView, setCurrentView] = useState(GRAPH_TYPE.SHO);

  // this is the DirectFO parameter for Neo4j call(s)
  const percentageParameter = "5";

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      const accessToken = oktaAuth.getAccessToken();

      if (accessToken) {
        ultimateOwnerCall(GRAPH_TYPE.GUO, idForHierarchy).then(
          (neo4jResult: RelatedEntity) => setGuo(neo4jResult)
        );

        ultimateOwnerCall(GRAPH_TYPE.DUO, idForHierarchy).then(
          (neo4jResult: RelatedEntity) => setDuo(neo4jResult)
        );

        getRelatedEntities4Hierarchy(
          GRAPH_TYPE.SHO,
          idForHierarchy,
          percentageParameter,
          "5",
          "10",
          accessToken
        )
          .then((neo4jResult) => processData(neo4jResult, GRAPH_TYPE.SHO)) // Process Data Function processes the data from neo4jResult into data that can be used by cytoscape
          .then((dataForSHOTable: HierarchyData) =>
            setDataReadyForSHOTable(dataForSHOTable)
          )
          .then(() => {
            setFetchSHOHasTerminated(true);
          });

        getRelatedEntities4Hierarchy(
          GRAPH_TYPE.SUB,
          idForHierarchy,
          percentageParameter,
          "5",
          "10",
          accessToken
        )
          .then((neo4jResult) => processData(neo4jResult, GRAPH_TYPE.SUB)) // Process Data Function processes the data from neo4jResult into data that can be used by cytoscape
          .then((dataForSUBTable: HierarchyData) =>
            setDataReadyForSUBTable(dataForSUBTable)
          )
          .then(() => {
            setFetchSUBHasTerminated(true);
          });
      }
    }
  }, []);

  return (
    <HierarchyVariableContent>
      {/* Show  the GUO and DUO in the 'Hierarchy Stats' */}
      {guo.name.trim() !== "" && guo.id.trim() !== "" && (
        <div>
          GUO:{" "}
          <a href={`${PATH.PARTY}/${guo.id}`} target="_blank" rel="noreferrer">
            {guo.name}
          </a>
        </div>
      )}
      {duo.name.trim() !== "" && duo.id.trim() !== "" && (
        <div>
          DUO:{" "}
          <a href={`${PATH.PARTY}/${duo.id}`} target="_blank" rel="noreferrer">
            {duo.name}
          </a>
        </div>
      )}
      {fetchSHOHasTerminated && fetchSUBHasTerminated && (
        <div>
          {/* Show the number of Shareholders and the number of Subsidiaries in the 'Hierarchy Stats', followed by the graph icons */}
          <HierarchyRow>
            <HierarchyName>SHO: </HierarchyName>
            <ShowGraph
              onClick={() =>
                dataReadyForSHOTable.edges.length > 0
                  ? setShowModal("SHO")
                  : null
              }
              inactive={dataReadyForSHOTable.edges.length}
            >
              {
                Array.from(
                  new Map(
                    dataReadyForSHOTable.nodes.map((item: CyNode) => [
                      item.data["id"],
                      item,
                    ])
                  ).values()
                ).filter((node: CyNode) => node.data.id !== idForHierarchy)
                  .length
              }{" "}
              - SHO graph
            </ShowGraph>
          </HierarchyRow>
          <HierarchyRow>
            <HierarchyName>SUB: </HierarchyName>
            <ShowGraph
              onClick={() =>
                dataReadyForSUBTable.edges.length > 0
                  ? setShowModal("SUB")
                  : null
              }
              inactive={dataReadyForSUBTable.edges.length}
            >
              {
                Array.from(
                  new Map(
                    dataReadyForSUBTable.nodes.map((item: CyNode) => [
                      item.data["id"],
                      item,
                    ])
                  ).values()
                ).filter((node: CyNode) => node.data.id !== idForHierarchy)
                  .length
              }{" "}
              - SUB graph
            </ShowGraph>
          </HierarchyRow>
          <SwitchContainer>
            <SwitchButton
              isActive={currentView === GRAPH_TYPE.SHO}
              onClick={() => setCurrentView(GRAPH_TYPE.SHO)}
            >
              SHO`s Table
            </SwitchButton>
            <SwitchButton
              isActive={currentView === GRAPH_TYPE.SUB}
              onClick={() => setCurrentView(GRAPH_TYPE.SUB)}
            >
              SUB`s Table
            </SwitchButton>
          </SwitchContainer>

          {currentView === GRAPH_TYPE.SHO && (
            <div>
              <div className="SHO">
                {dataReadyForSHOTable.edges.length === 0 && (
                  <div>
                    No shareholder information for this Party, within the
                    current parameter ({`>`}
                    {percentageParameter}%)
                  </div>
                )}
                {dataReadyForSHOTable.edges.length !== 0 && (
                  <TableHierarchyContent
                    data={dataReadyForSHOTable}
                    name={"Hierachy"}
                  />
                )}
              </div>
            </div>
          )}

          {currentView === GRAPH_TYPE.SUB && (
            <div>
              <div className="SUB">
                {dataReadyForSUBTable.edges.length === 0 && (
                  <div>
                    No subsidiaries information for this Party, within the
                    current parameter ({`>`}
                    {percentageParameter}%)
                  </div>
                )}
                {dataReadyForSUBTable.edges.length !== 0 && (
                  <TableHierarchyContent
                    data={dataReadyForSUBTable}
                    name={"Hierachy"}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {/* {showModal === GRAPH_TYPE.SHO && <HierarchyGraphModal direction="bottom" dataForCytoscape={dataReadyForSHOTable} graphType={GRAPH_TYPE.SHO} setShowModal={setShowModal} />}
             {showModal === GRAPH_TYPE.SUB && <HierarchyGraphModal dataForCytoscape={dataReadyForSUBTable} graphType={GRAPH_TYPE.SUB} setShowModal={setShowModal} />} */}
      {/* <DisplayCytoscape
            dataForCytoscape={dataForCytoscape}
            graphType={GRAPH_TYPE.CY_CUB}
          /> */}
      {showModal === GRAPH_TYPE.SHO && (
        <CytoScapeModal
          dataForCytoscape={dataReadyForSHOTable}
          graphType={GRAPH_TYPE.SHO}
          setShowModal={setShowModal}
        />
      )}
      {showModal === GRAPH_TYPE.SUB && (
        <CytoScapeModal
          dataForCytoscape={dataReadyForSUBTable}
          graphType={GRAPH_TYPE.SUB}
          setShowModal={setShowModal}
        />
      )}
    </HierarchyVariableContent>
  );
}
