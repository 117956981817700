// --- [START] Styled Components ---
import styled from "styled-components";
import {useRef, useState} from "react";
import Title from "../Title";
import DocumentFromOCR from "./DocumentFromOCR";
import {UserClaims} from "@okta/okta-auth-js";
import {useOktaAuth} from "@okta/okta-react";
import AlertWaitingIcon from "../AlertWaitingIcon";
import {CHECKMATE_CONTROLLER_GCF} from "../../../config/googleCloudFunctionsConfig";
import AlertFeedback from "../AlertFeedback";
import {useDetectOutsideClick} from "../../../hooks/useDetectOutsideClick";
import {generateRandomString} from "../../../utils/randomStringGenerator";
import {AlertFeedbackType, Metadata, OcrDocResponseItem} from "../../../types";
import {callGCFunction} from "../../../services/callGCFunction";
import {createControllerRealPayload} from "../../../services/alert";
import useModal from "hooks/useModal";
import {StatusProps} from "constants/index";
import {
    Modal,
    ModalContent,
    ScrollableArea,
    SubmitButton,
    ButtonRow
 } from "styles/alert/docsUploadAndSavings/ConfirmDocumentsData.styled";
 import { useToast } from "hooks/useToast";


const ConfirmDocumentsData = ({ ocrResponse}: {ocrResponse:OcrDocResponseItem[]}) => {
    const {toast} = useToast();
    const [confirmedDocuments, setConfirmedDocuments] = useState<OcrDocResponseItem[]>(ocrResponse);
    const initialDocSavedState:boolean[] = ocrResponse.map(()=> { return false})
    const [docSavedChecks, setDocSavedChecks] = useState<boolean[]>(initialDocSavedState)
    const [showWaitingIcon, setShowWaitingIcon] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isActive,] = useDetectOutsideClick(dropdownRef, true)

    const controllerResponse = useModal<AlertFeedbackType>()

    const {authState, oktaAuth} = useOktaAuth();
    let accessToken:string = ""
    if(authState && authState.isAuthenticated){
        const token = oktaAuth.getAccessToken();
        if (token) accessToken = token
    }

    // by default let's assume we have no user email info from OKTA
    let userEmail:string = "okta-user-no-email@t60degrees.com"
    oktaAuth.getUser().then((response:UserClaims) => {
        if(response.email)
            userEmail = response.email
    })

    const handleSubmitForController = () => {

        const clientHardcodedPID = "DEFEB13216";
        const randomString:string = generateRandomString(6)

        if (accessToken !== ""){
            const meta:Metadata = {
                pid: clientHardcodedPID,  // here we hardcoded Deutsche Bank's PID
                user_id: userEmail,
                event_id: `${clientHardcodedPID}_ev_${randomString}`,
                transaction_id: `${clientHardcodedPID}_tr_${randomString}`,
                date_time: new Date().toISOString(),
            }
            const dataForGCF:Object = createControllerRealPayload(accessToken, meta, confirmedDocuments);

            setShowWaitingIcon(true)

            callGCFunction(CHECKMATE_CONTROLLER_GCF,
                dataForGCF, "CheckMate GCF controller", "checkMate")
                .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
                .then((data:AlertFeedbackType) => {
                    controllerResponse.open(data)
                })
                .catch((err: Error) => toast(err.toString(), StatusProps.ERROR))
                .finally(() => setShowWaitingIcon(false))
        }
    }

    return (
        <>
            {!controllerResponse && isActive &&
                <Modal >
                    <ScrollableArea>
                        <Title text={"Please check the text fields for each document before submitting"} />
                        <ModalContent>
                            {ocrResponse.map((item: OcrDocResponseItem, index:number) => {
                                return (<DocumentFromOCR
                                    key={index.toString()}
                                    ocrDocResponseItem={item}
                                    setConfirmedDocuments={setConfirmedDocuments}
                                    index={index}
                                    setDocSavedChecks={setDocSavedChecks}
                                />)
                            })}
                            <ButtonRow>
                                <SubmitButton
                                    onClick={handleSubmitForController}
                                    disabled={!docSavedChecks.every(value => value)}
                                >Submit and Continue</SubmitButton>
                            </ButtonRow>
                        </ModalContent>
                    </ScrollableArea>
                </Modal>
            }
            {showWaitingIcon && <AlertWaitingIcon/>}
            <AlertFeedback controller={controllerResponse}/>
        </>
    );
}
export default ConfirmDocumentsData;