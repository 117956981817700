//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for the Leaflet Map that we use in HeadOffice.
 * For the geocoding feature, we are using HERE API: (https://www.here.com/)
 */

import {MapContainer, TileLayer, Marker, Popup} from "react-leaflet";
import {useEffect, useState} from "react";
import {Coordinates} from "../../../types";
import {StatusProps} from "constants/index";
import {getCoordinatesFromHereApi} from "../../../services/hereApiGeoCoder";
import ErrorBoundary from "../../ErrorBoundary";
import 'leaflet/dist/leaflet.css';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'
import { LeafLetMapAttribtion, LeafLetMapUrl } from "config/leafLetMap";
import { useToast } from "hooks/useToast";

export function LeafletMap({query}: { query: string }) {
    const {toast} = useToast();
    const initialState: Coordinates = {
        latitude: 91,
        longitude: 181,
    };

    let [coordinates, setCoordinates] = useState(initialState);

    useEffect(() => {
        // call HERE geocoding API only if we have an address to pass
        if (query !== "No_Address")
            getCoordinatesFromHereApi(query).then((coordinatesFromApi: Coordinates) =>
                setCoordinates(coordinatesFromApi)
            ).catch((err: Error) => toast(err.toString(), StatusProps.ERROR))
    }, []);

    return (
        // display the map only if you have valid coordinates (in a range between -90/90 for lat. and -180/180 for long.)
        coordinates.latitude < 91 && coordinates.longitude < 181 ? (
            // here we use the React Leaflet components
            <ErrorBoundary>
                <MapContainer
                    center={[coordinates.latitude, coordinates.longitude]}
                    zoom={13}
                    scrollWheelZoom={false}
                    style={{height: "400px", maxWidth: "800px", zIndex: 8}}
                >
                    <TileLayer
                        attribution={`&copy; <a href=${LeafLetMapAttribtion}>OpenStreetMap</a> contributors`}
                        url={LeafLetMapUrl}
                    />
                    <Marker position={[coordinates.latitude, coordinates.longitude]}
                            icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
                        <Popup>This is a popup that we can customize</Popup>
                    </Marker>
                </MapContainer>
            </ErrorBoundary>
        ) : null
    );
}
