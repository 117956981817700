import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333333;
  cursor: pointer;
`;

export const ArrowIcon = styled.span<{ rotate?: number }>`
  width: 26.67px;
  height: 26.67px;
  background-image: url(${process.env.PUBLIC_URL}/assets/Accordion${(props) => (props.rotate ? "Minu" : "Plus")}.svg);
  cursor: pointer;
  background-size: contain;
  filter: ${({theme}) => theme.colors.WHITE!=='#FFFFFF'?'saturate(0.8) brightness(0.86) hue-rotate(180deg) invert(1)':'none'};
`;

export const Text = styled.div<{light?:boolean; small?:boolean}>`
  font-weight: ${({light}) => light ? 400 : 700};
  font-size: ${({small}) => small ? '18px' : '24px'};
  line-height: 48px;
  color: ${({theme}) => theme.colors.WHITE!=='#FFFFFF'?'rgb(186, 192, 222)':theme.colors.PRIMARY[400]};
  font-family: 'Roboto Condensed';
  &.noAccordion {
    cursor: inherit;
  }
`;