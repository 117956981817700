import type { FunctionComponent } from "react";
import { Container } from "styles/party/funds/index.styled";
import { FundDocumentsType } from "types/funds";
import GroupedDetails from "./GroupedDetails";

const Funds:FunctionComponent<{data:any, partyId?: string, fundDocuments:FundDocumentsType; hideSubFund?:boolean}> = ({data, partyId, fundDocuments, hideSubFund}) => {
  return (
    <Container>
      <GroupedDetails data={data} partyId={partyId!} fundDocuments={fundDocuments} hideSubFund={hideSubFund} />
    </Container>
  );
};

export default Funds;
