import { 
    Container,
    Title,
    Info,
    Icon,
    Num
 } from 'styles/client/InfoCard.styled'
import { InfoCardProps } from 'types'

const InfoCard = ({icon, num, name}: InfoCardProps) => {
    return (
        <Container>
            <Title>{name}</Title>
            <Info>
                <Icon src={icon} />
                <Num>{num}</Num>
            </Info>
        </Container>
    )
}

export default InfoCard