import {
    Text,
    MainIcon,
    ButtonsContainer,
    Button,
    mainIcon
} from "styles/crownAlert/FirstStep.styled"
import { useLocation } from "react-router-dom";
import { PATH } from "constants/index";

const FirstStep = ({setStep}:{setStep: React.Dispatch<React.SetStateAction<string>>}) => {
    const {pathname} = useLocation();

    return(
        <>
            <Text><b>Send us an Enquiry</b></Text>
            <MainIcon iconURL={mainIcon}/>
            <ButtonsContainer>
                <Button onClick={() => setStep('DataEnquiry')}>
                    {pathname.includes(PATH.PARTY) ? 'Data Enquiry' : 'General Enquiry'}
                </Button>
                <Button onClick={() => setStep('Feedback')}>
                    Feedback
                </Button>
            </ButtonsContainer>
        </>
    )
}
export default FirstStep