import SideBar from "../../components/admin/SideBar";
import { Outlet} from "react-router-dom";
import { Container, Content, Nav, OutletCover } from "../../styles/admin/index.styled";
import AdminNavbar from "../../components/admin/Navbar";

const AdminRoom = () => {

  return (
    <Container>
      <SideBar />
      <Content>
        {/* nav */}
        <Nav>
          <AdminNavbar />
        </Nav>

        {/* outlet  */}
        <OutletCover>
          <Outlet />
        </OutletCover>
      </Content>
    </Container>
  );
};

export default AdminRoom;
