import { FunctionComponent, useState } from "react";
import { Container, StyledCloseIcon, Content, IconsSeparator, IconsContainer, Text, searchIconBlue, askAliceIconBlue, alertIconBlue, notificationIconBlue } from "styles/header/SideMenu.styled";
import CrownAlert from "components/crownAlert/CrownAlert";
import { Link, useLocation } from "react-router-dom";
import { HEADER_STYLE } from "types";
import NotificationTab from "components/notificationTab/NotificationTab";
import { PATH } from "constants/index";

const SideMenu: FunctionComponent<{
    show: boolean;
    close: () => void;
    headerStyle: HEADER_STYLE;
}> = ({ show, close }) => {
    const { pathname } = useLocation();


    //for crowd alert
    const [alertIsActive, setAlertIsActive] = useState(false);
    const menuToggle = () => setAlertIsActive(!alertIsActive);

    //for notification tab
    const [tabIsActive, setTabIsActive] = useState(false);
    const tabToggle = () => setTabIsActive(!tabIsActive);

    const showAlice = () => {
        close();

        if (!tabIsActive && !alertIsActive) {
            menuToggle()
        }
    }

    const showNotification = () => {
        close();

        if(!alertIsActive) {
            tabToggle()
        }
    }



    return (
        <Container show={show}>
            <StyledCloseIcon onClick={close} />
            <Content>
                <Link to={!alertIsActive ? PATH.FUND_SEARCH : pathname} onClick={close}>
                    <IconsSeparator>
                        <IconsContainer
                            iconUrl={searchIconBlue}
                            style={
                                pathname === PATH.FUND_SEARCH || pathname.includes(PATH.FUND_SEARCH_RESULT)
                                    ? { opacity: "0.2" }
                                    : { opacity: "1" }
                            }
                        />
                        <Text>Search</Text>
                    </IconsSeparator>
                </Link>
                <IconsSeparator onClick={showAlice}>
                    <IconsContainer
                        iconUrl={alertIconBlue}
                    />
                    <Text>Alice</Text>
                    <CrownAlert alertIsActive={alertIsActive} menuToggle={menuToggle} />
                </IconsSeparator>
                <Link to={!alertIsActive ? PATH.FUND_ALERT : pathname} onClick={close}>
                    <IconsSeparator>
                        <IconsContainer
                            iconUrl={askAliceIconBlue}
                            style={
                                pathname === PATH.FUND_ALERT ? { opacity: "0.2" } : { opacity: "1" }
                            }
                        />
                        <Text>Alert</Text>
                    </IconsSeparator>
                </Link>
                <IconsSeparator onClick={showNotification}>
                    <IconsContainer
                        iconUrl={notificationIconBlue}

                    />
                    <Text>Notification</Text>
                    <NotificationTab tabIsActive={tabIsActive} tabToggle={tabToggle} />
                </IconsSeparator>
            </Content>
        </Container>
    );
};

export default SideMenu;
