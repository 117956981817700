//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated function that filters the data in order to choose if we should later display banks-only or NON-banks-only data
 */

import { ArrayType } from "../../types";

export function dataFilter({ data }: { data: any }): any[] {
  let arraysField: any[] = [];
  let filteredData: any[] = [];

  // if we have enough data
  if (Object.entries(data).length !== 0) {
    // scan all the data
    Object.entries(data).forEach(([name, field]: [string, string | any], j) => {
      // and take all the arrays and push them into a dedicated array
      if (Array.isArray(field)) {
        arraysField.push([name, field]);
      } else {
        filteredData.push([name, field]);
      }
    });

    /*
         * [ASSUMPTION]
         * If we have more than one array, we need to choose the right data to display.
         * So we follow this logic:
         * If we have more than one array, we should create some "couples" of ArrayType.
         * A "couple" means that the data refers to the same kind of name (ArrayType), but they are coming from different source files.
         * e.g. ID and ID_BO are of the same ArrayType, but the first is NON-banks-only (no_bo) data and the second is banks-only (bo) data.
         * Then, we have to check each single couple:
         *      If we have both bo and no_bo data: always choose banks only (bo) to display, because usually has more updated data
         *      Else choose the only one available (bo OR no_bo)
         * 

        */

    if (arraysField.length >= 2) {
      let groupOfSameArrayType: ArrayType[] = [];

      // we need to scan every array that we need to process before display it in the section
      arraysField.forEach((item) => {
        let arrayName = item[0];
        let arrayData = item[1];

        // if we already have the ArrayTipe element with same name or with same name ending in _BO
        if (
          groupOfSameArrayType.some(
            (item) =>
              item.name === arrayName ||
              item.name === arrayName.substring(0, arrayName.length - 3)
          )
        ) {
          // find the element already present in the array of ArrayTypes
          groupOfSameArrayType.forEach((item: ArrayType, j: number) => {
            if (
              item.name === arrayName ||
              item.name === arrayName.substring(0, arrayName.length - 3)
            ) {
              // and just enter the bo or no_bo value
              // (this is hardcoded and has to be changed in the future!)
              if (arrayName.slice(-3) === "_BO")
                groupOfSameArrayType[j].bo = arrayData;
              else groupOfSameArrayType[j].no_bo = arrayData;
            }
          });
        } else {
          // else, it means that the ArrayType element is completely new, so we need to push it inside the array of ArrayTypes

          let noBanksOnly: any[] = [];
          let banksOnly: any[] = [];
          let newItemToBeInserted: ArrayType;

          // and just enter the bo or no_bo value
          // (this is hardcoded and has to be changed in the future!)
          if (arrayName.slice(-3) === "_BO") banksOnly = arrayData;
          else noBanksOnly = arrayData;

          newItemToBeInserted = {
            name: arrayName,
            no_bo: noBanksOnly,
            bo: banksOnly,
          };
          groupOfSameArrayType.push(newItemToBeInserted);
        }
      });


      // scan all the couples we have in the array of ArrayTypes
      groupOfSameArrayType.forEach((item: ArrayType, j: number) => {
        if (item.no_bo.length === 0) {
          // if the couple doesn't have the no_bo data, just push the bo data
          filteredData.push([item.name + "_fromBanksOnly", item.bo]);
        } else if (item.bo.length === 0) {
          // if the couple doesn't have the bo data, just push the no_bo data
          filteredData.push([item.name, item.no_bo]);
        } else {
          // else, it means that we have both bo and no_bo data, so we use just bo data
          filteredData.push([item.name + "_fromBanksOnly", item.bo]);
        }
      });

      // if we have just one array, just push the one we have
    } else if (arraysField.length === 1) {
      filteredData.push(arraysField[0]);
    }

    return filteredData;
  } else {
    return [];
  }
}
