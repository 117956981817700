const EXAMPLE_DATA = {
    "comparator": {
        "different_doc_addresses_uploaded_together": {
            "comment": "Documents uploaded have different address",
            "data": [
                {
                    "document_address": "1 keybrook",
                    "document_dob": null,
                    "document_name": "smith keeley",
                    "document_number": "456 598 785",
                    "document_type": "UtilityBill",
                    "uid": "FBDB9520EECE49D17C01A52B5A5FAE96C1CC05EB"
                },
                {
                    "document_address": "570 manor road, doncaster, dn37 3rw",
                    "document_dob": "Wed, 21 Sep 1977 00:00:00 GMT",
                    "document_name": "claire fellows",
                    "document_number": "woodw3955e938275",
                    "document_type": "DrivingLicence",
                    "uid": "DEA91DE9B53187AA0CBB525F6A0945845D6142CE"
                }
            ],
            "rag": "RED"
        },
        "doc_expired": {},
        "old_doc_used_after_new_used": {},
        "same_details_different_doc_number": {},
        "same_doc_number_different_date_of_birth": {
            "DEA91DE9B53187AA0CBB525F6A0945845D6142CE": {
                "comment": "The document number: woodw3955e938275 in DrivingLicence of fellows claire has been used with other date of birth 2 times",
                "data": [
                    {
                        "document_address": "570 manor road, doncaster, dn37 3rw",
                        "document_dob": "Wed, 21 Sep 1977 00:00:00 GMT",
                        "document_name": "claire fellows",
                        "document_number": "woodw3955e938275",
                        "document_type": "DrivingLicence"
                    },
                    {
                        "document_address": "570 manor road dn37 3rw doncaster",
                        "document_dob": "Thu, 13 May 1971 00:00:00 GMT",
                        "document_name": "claire chiatti",
                        "document_number": "woodw3955e938275",
                        "document_type": "DrivingLicence"
                    },
                    {
                        "document_address": "570 manor road dn37 3rw doncaster",
                        "document_dob": "Thu, 13 May 1971 00:00:00 GMT",
                        "document_name": "fellows claire",
                        "document_number": "woodw3955e938275",
                        "document_type": "DrivingLicence"
                    }
                ],
                "rag": "RED"
            }
        },
        "same_doc_number_different_name": {
            "DEA91DE9B53187AA0CBB525F6A0945845D6142CE": {
                "comment": "The document number: woodw3955e938275 in DrivingLicence of claire fellows has been used with other names 2 times",
                "data": [
                    {
                        "document_address": "570 manor road, doncaster, dn37 3rw",
                        "document_dob": "Wed, 21 Sep 1977 00:00:00 GMT",
                        "document_name": "claire fellows",
                        "document_number": "woodw3955e938275",
                        "document_type": "DrivingLicence"
                    },
                    {
                        "document_address": "570 manor road dn37 3rw doncaster",
                        "document_dob": "Thu, 13 May 1971 00:00:00 GMT",
                        "document_name": "chiatti claire",
                        "document_number": "woodw3955e938275",
                        "document_type": "DrivingLicence"
                    },
                    {
                        "document_address": "570 manor road dn37 3rw doncaster",
                        "document_dob": "Thu, 13 May 1971 00:00:00 GMT",
                        "document_name": "claire fellows",
                        "document_number": "woodw3955e938275",
                        "document_type": "DrivingLicence"
                    }
                ],
                "rag": "RED"
            }
        },
        "same_document_number_different_address": {
            "DEA91DE9B53187AA0CBB525F6A0945845D6142CE": {
                "comment": "The document number: woodw3955e938275 in DrivingLicence of fellows claire has been used with other addresses 2 times",
                "data": [
                    {
                        "document_address": "570 manor road, doncaster, dn37 3rw",
                        "document_dob": "Wed, 21 Sep 1977 00:00:00 GMT",
                        "document_name": "claire fellows",
                        "document_number": "woodw3955e938275",
                        "document_type": "DrivingLicence"
                    },
                    {
                        "document_address": "570 manor road dn37 3rw doncaster",
                        "document_dob": "Thu, 13 May 1971 00:00:00 GMT",
                        "document_name": "claire chiatti",
                        "document_number": "woodw3955e938275",
                        "document_type": "DrivingLicence"
                    },
                    {
                        "document_address": "570 manor road dn37 3rw doncaster",
                        "document_dob": "Thu, 13 May 1971 00:00:00 GMT",
                        "document_name": "fellows claire",
                        "document_number": "woodw3955e938275",
                        "document_type": "DrivingLicence"
                    }
                ],
                "rag": "AMBER"
            }
        },
        "utility_bill_address_different_names": {}
    },
    "counter": {
        "DEA91DE9B53187AA0CBB525F6A0945845D6142CE": {
            "Bank": {
                "comment": "The DrivingLicence number: woodw3955e938275 of Claire Fellows born the 21 Sep 1977 has been used 7 times in the last week",
                "data": [
                    {
                        "client_address": "26 Lairfield Cottage Dundee DD26 7TI Scotland",
                        "client_name": "HSBC Scotland",
                        "client_pid": "GB49022145",
                        "document_address": null,
                        "document_dob": "Wed, 21 Sep 1977 00:00:00 GMT",
                        "document_name": "claire",
                        "document_number": "woodw3955e938275",
                        "document_surname": "fellows",
                        "document_type": "DrivingLicence"
                    },
                    {
                        "client_address": "26 Lairfield Cottage Dundee DD26 7TI Scotland",
                        "client_name": "HSBC Scotland",
                        "client_pid": "GB49022145",
                        "document_address": null,
                        "document_dob": "Wed, 21 Sep 1977 00:00:00 GMT",
                        "document_name": "claire",
                        "document_number": "woodw3955e938275",
                        "document_surname": "fellows",
                        "document_type": "DrivingLicence"
                    },
                    {
                        "client_address": "26 Lairfield Cottage Dundee DD26 7TI Scotland",
                        "client_name": "HSBC Scotland",
                        "client_pid": "GB49022145",
                        "document_address": null,
                        "document_dob": "Wed, 21 Sep 1977 00:00:00 GMT",
                        "document_name": "claire",
                        "document_number": "woodw3955e938275",
                        "document_surname": "fellows",
                        "document_type": "DrivingLicence"
                    },
                    {
                        "client_address": "26 Lairfield Cottage Dundee DD26 7TI Scotland",
                        "client_name": "HSBC Scotland",
                        "client_pid": "GB49022145",
                        "document_address": null,
                        "document_dob": "Wed, 21 Sep 1977 00:00:00 GMT",
                        "document_name": "claire",
                        "document_number": "woodw3955e938275",
                        "document_surname": "fellows",
                        "document_type": "DrivingLicence"
                    },
                    {
                        "client_address": "26 Lairfield Cottage Dundee DD26 7TI Scotland",
                        "client_name": "HSBC Scotland",
                        "client_pid": "GB49022145",
                        "document_address": null,
                        "document_dob": "Wed, 21 Sep 1977 00:00:00 GMT",
                        "document_name": "claire",
                        "document_number": "woodw3955e938275",
                        "document_surname": "fellows",
                        "document_type": "DrivingLicence"
                    },
                    {
                        "client_address": "26 Lairfield Cottage Dundee DD26 7TI Scotland",
                        "client_name": "HSBC Scotland",
                        "client_pid": "GB49022145",
                        "document_address": null,
                        "document_dob": "Wed, 21 Sep 1977 00:00:00 GMT",
                        "document_name": "claire",
                        "document_number": "woodw3955e938275",
                        "document_surname": "fellows",
                        "document_type": "DrivingLicence"
                    },
                    {
                        "client_address": "26 Lairfield Cottage Dundee DD26 7TI Scotland",
                        "client_name": "HSBC Scotland",
                        "client_pid": "GB49022145",
                        "document_address": null,
                        "document_dob": "Wed, 21 Sep 1977 00:00:00 GMT",
                        "document_name": "claire",
                        "document_number": "woodw3955e938275",
                        "document_surname": "fellows",
                        "document_type": "DrivingLicence"
                    }
                ],
                "rag": "RED"
            },
            "CarRental": {},
            "Feedback": {},
            "PhoneShop": {}
        },
        "FBDB9520EECE49D17C01A52B5A5FAE96C1CC05EB": {
            "Bank": {},
            "CarRental": {},
            "Feedback": {},
            "PhoneShop": {}
        }
    },
    "rag": "RED"
}
export default EXAMPLE_DATA