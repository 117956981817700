export const PARTY_MAP = {
  barclays_party: "GB00048839",
  deutsche_bank_party: "DEFEB13216",
  hsbc_party: "GB00617987",
  t360_party: "DEFEB13216",
};

export enum PARTY {
  HUBWISE = "hubwise_party",
}

export const PARTY_ID_BIC_MAP: { [key: string]: string } = {
  GB00617987: "HSBCGB22XXX",
  GB00014259: "MIDLGB22XXX",
  GB01026167: "BARCGB22XXX",
  GB09740322: "BUKBGB22XXX",
  DEFEB13216: "DEUTDEFFXXX",
  US134941247: "BKTRUS33XXX",
};

export const DASHBOARD_BAR_TITLE = {
  BANKS: "Banks",
  GOV_RELATED_INST: "Govt-Related Inst",
  FINANCIAL_INST: "Financial Institution",
};

export const ROLE = {
  ADMIN: "admin",
  CLIENT: "client/",
  DIDO: "dido",
};

export enum COLOR_MODE {
  LIGHT = "light",
  DARK = "dark",
}

export enum CrownAlertSteps {
  FEEDBACK = "Feedback",
  DATAENQUIRY = "DataEnquiry",
  CLOSE = "Close",
}

export enum CHART_TYPE {
  PIE = "",
  GEO = "GeoChart",
}

export enum GRAPH_TYPE {
  SHO = "SHO",
  SUB = "SUB",
  GUO = "GUO",
  DUO = "DUO",
  CY_SHO = "cySHO",
  CY_CUB = "cySUB",
}

export enum PATH {
  SEARCH = "/search",
  SEARCH_RESULT = "/search-results",
  FUND_SEARCH = "/funds/search",
  FUND_SEARCH_RESULT = "/funds/search-results",
  FUND_ALERT = "/funds/alert",
  ALERT = "/alert",
  ADMIN_ALICE_REQUEST = "/admin/alice-requests",
  CLIENT_ALICE_REQUEST = "/client/alice-requests",
  ADMIN = "/admin",
  FUNDS = "/funds",
  INDEX = "/",
  SIGNOUT = "signout",
  CLIENT = "/client",
  DIDO_DEMO = "/dido-demo",
  DIDO_DEMO_INTERACTIVE = "/dido-demo/interactive",
  PARTY = "/party",
}

export enum META_DATA {
  P_ID = "pid",
  USER_ID = "user_id",
  TRANSACTION_ID = "transaction_id",
  DATE_TIME = "date_time",
}

export enum TRANSACTION_STATUS {
  APPROVED = "approved",
  IN_PROGRESS = "in-progress",
  DENIED = "denied",
}

export const PARTY_ID = "GB00617987";

export enum DIRECTION {
  TOP = "top",
  BOTTOM = "bottom",
  RIGHT = "right",
  LEFT = "left",
}

export enum StatusProps {
  ERROR = "error",
  SUCCESS = "success",
}
