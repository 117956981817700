//Copyright Marco Rapaccini and TRANSACTION 360 DEGREES LTD. Unauthorised copying of this file via any medium is strictly prohibited. See LICENSE.md for more details.

/**
 * This is the dedicated component for displaying (or not) a table in the Party Profile's sections
 */
import { useState } from "react";
import { FilterableTable } from "../filterableTable";
import { FilterTable } from "../filter/FilterTable";
import { RespTable,RespTableBody, RespTableRow, TableBodyCell, TableContentContainer, TableHeaderCell } from "styles/tableContent/TableContent.styled";

export const DisplayRow = ({
  item,
  hasBorder = false,
}: {
  item: any;
  hasBorder: boolean;
}) => {
  let displayRow = false;

  // check if there's at least one column of useful data. If yes, show the row.
  Object.entries(item).forEach(([name, field]: [string, string | any], j) => {
    if (field && field.trim() !== "") displayRow = true;
  });


  return displayRow ? (
    <RespTableRow hasBorder={hasBorder}>
      {Object.entries(item).map(([name, field]: [string, string | any], j) => (
        <TableBodyCell key={j}>{field || "-"}</TableBodyCell>
      ))}
    </RespTableRow>
  ) : null;
};

export const TableContent = ({
  data,
  name,
  tableType,
}: {
  data: any;
  name: string;
  tableType: string;
}) => {

  const [filter, setFilter] = useState("");


  const searchFilter = (letter: string) => {
    setFilter(letter);
  };

  let displayTable = false;

  // check if there's at least one table cell of useful data. If yes, show the table
  data.forEach((item: Object) => {
    Object.entries(item).forEach(([name, field]: [string, string | any], j) => {
      if (field && field.trim() !== "") displayTable = true;
    });
  });

  if (!displayTable) {
    return null;
  }
  return (
    <TableContentContainer noMarginTop>
      <FilterTable setData={searchFilter} />
      <FilterableTable data={data} tableType="FP" filter={filter} />
    </TableContentContainer>
  );
};
